import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private api: ApiService, private sanitizer: DomSanitizer) { }

  transform(id): Observable<SafeUrl> {
    console.log('internal secure');
    const noCache = new Date().getTime();
    const endpoint = `banca/logoFileDaBancaId/${id}?noCache=` + noCache;
    return this.api
      .getBlobRequest(endpoint);
  }

}
