import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { IChatController, IChatParticipant, Localization } from 'ng-chat';
import { ChatService } from '../services/chat.service';
import { EventInteractionService } from '../services/event-interaction.service';
import { UserService } from 'app/main/admin/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from 'app/main/services/notification.service';
import { FormBuilder } from '@angular/forms';
import { ChatAdapterOperatorImplementation } from '../chat-adapter-operator-implementation';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-chat-operator',
  templateUrl: './chat-operator.component.html',
  styleUrls: ['./chat-operator.component.scss']
})
export class ChatOperatorComponent extends AdminUtilComponent implements OnInit {

  adapter: ChatAdapterOperatorImplementation;
  chatDisabled: boolean = false;
  searchEnabled: boolean = true;
  historyEnabled: boolean = false;
  hideFriendsList: boolean = false;
  messagePlaceholder: string = "Scrivi un messaggio"
  messageDatePipeFormat: string = "dd-MM-yyyy HH:mm:SS"
  localization: Localization

  @ViewChild('ngOperatorChatInstance')
  protected ngOperatorChatInstance: IChatController;
  eis: any;
  fileUrl: any;
  userInfo: any;
  myUserUuid: any;

  constructor(
    private dialogMat: MatDialog,
    private snackBarMat: MatSnackBar,
    private eventInteractionService: EventInteractionService,
    private chatService: ChatService,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private _formBuilder: FormBuilder,
    private accountService: AccountService
  , injector: Injector) {
    super(dialogMat, snackBarMat, injector);
    this.chatService.setThereIsAdapter(true);
    this.adapter = new ChatAdapterOperatorImplementation(this.chatService, this.userService);
    this.localization = {
      title: "Specialisti",
      messagePlaceholder: "Scrivi un messaggio",
      searchPlaceholder: "Ricerca",
      browserNotificationTitle: "Nuovo messaggio",
      loadMessageHistoryPlaceholder: "Caricamento",
      statusDescription : {
        online: 'ONLINE',
        busy: 'OCCUPATO',
        away: 'AWAY',
        offline: 'OFFLINE'
    }
  }
  }

  ngOnInit() {
    this.eis = this.eventInteractionService.getObservable().subscribe((data) => {
      //console.log('Data received', data);

      if (data != undefined && data != null
        && data.event != undefined && data.event != null) {
        switch (data.event) {
          case 'LOGIN':
            // console.log('chat enabled!!!')
            this.chatDisabled = false;
            this.accountService.getAccount().then((account) => {
              this.myUserUuid = account.userId;
              this.adapter.setMyUserId(this.myUserUuid);
          })
            break;
          case 'LOGOUT':
            console.log('chat disabled!!!')
            this.chatDisabled = true;
            this.adapter.setMyUserId(null);
            break;
          // case 'OPENCHAT':
          //   console.log('openchat', data)
          //   this.openChat(data);
          //   break;
          case 'NEWCHATMESSAGE':
            console.log('NEWCHATMESSAGE', data)
            this.newMessage(data);
            break;
          case 'updateusers':
            this.mergeOperators(data.data);
            break;
          default:
            break;
        }
      }
    });
    this.accountService.getAccount().then((account) => {
        this.myUserUuid = account.userId;
        this.adapter.setMyUserId(this.myUserUuid);
    })

    setTimeout(() => {
      console.log('adding public fetch');
      this.ngOperatorChatInstance['publicFetch'] = this.publicFetch;
    }, 200);
  }

  publicFetch() {
    console.log(this);
    this['fetchFriendsList'](false);
  }

  mergeOperators(data) {
    this.adapter.mergeOperators(data).then(()=>{
      if(this.ngOperatorChatInstance['publicFetch'] == undefined || this.ngOperatorChatInstance['publicFetch'] == null) {
        this.ngOperatorChatInstance['publicFetch'] = this.publicFetch;
      } 
      this.ngOperatorChatInstance['publicFetch']();
    })
   
    
  }

  openChat(data) {
    console.log('opening user chat');
    let contactId = 15;
    let emergencyId;
    let user = {
      participant: {
        participantType: 0,
        id: contactId,
        displayName: "Test test",
        avatar: "https://thumbnail.myheritageimages.com/502/323/78502323/000/000114_884889c3n33qfe004v5024_C_64x64C.jpg",
        status: 0
      }
    }
    user.participant.avatar = '/assets/images/admin-image.jpg';
    if(data != undefined && data != null) {
      user.participant.id = data.contactId;
      contactId = data.contactId;
      emergencyId = data.emergencyId;
      this.userService.getUserInfoByUserId(contactId)
      .subscribe((data: any) => {
        this.userInfo = data.payload;
        user.participant.displayName = this.userInfo.name + " " + this.userInfo.surname;
        // user.participant.avatar = this.userService.retrieveProfileImageUidEndpoint(contactId);
        // this._openChat(user.participant);
        this.userService.renderUnsecureProfileImageUid(contactId, false)
          .subscribe((data) => {
              //  console.log('res: ', data);
              this.fileUrl = data;
              user.participant.avatar = this.fileUrl;
              this._openChat(user.participant);
          },
          (error) => {
              this.snackBarMat.open('Errore caricamento immagine profilo', '', {
                  duration: 3000,
                  panelClass: 'warn',
                });
                this._openChat(user.participant);
          });
    },
    (error) => {
        this.snackBarMat.open('Errore caricamento profilo', '', {
            duration: 3000,
            panelClass: 'warn',
          });
    });
      
    } else {
      this._openChat(user.participant);
    }
    //let user = this.adapter.getUser(1);

    
  }

  _getSafeUrl() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
  }

  _openChat(user) {
    if (!this.chatDisabled){
      this.adapter.addToList(user);
      this.ngOperatorChatInstance.triggerOpenChatWindow(user);
    }
  }

  ngOnDestroy(): void {
    if (this.eis != undefined && this.eis != null)
      this.eis.unsubscribe();
  }

  newMessage(data) {
    if(data.message != undefined && data.message != null)
      this.adapter.newMessage(data.message);
  }

  onChatClosed(participant) {
    console.log('chat closed!!!', participant);
  }



}

