import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AccountService } from 'app/main/shared/services/account.service';
import { AuthService } from 'app/main/shared/services/auth.service';
import { UserService } from 'app/main/admin/services/user.service';
import { ProfileService } from 'app/main/shared/services/profile.service';

@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    data: any;
    bankData: any;
    //logo: any = 'assets/images/logos/covidSafe_logo_w.png';
    logo: any = 'assets/images/logos/logo_SUP_w.png';
    //logomini: any = 'assets/images/logos/icon-covidsafe.png';
    logomini: any = 'assets/images/logos/SU_Simbolo.png';
    myProfile: any;
    fileUrl;
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    currentRole: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _account: AccountService,
        private _auth: AuthService,
        private userService: UserService,
        private profileService: ProfileService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective)
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector('navbar .nav-link.active');

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop;
                        if (activeNavItem.offsetTop) {
                            const activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
                                scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3) - 168;

                            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                        }
                    }
                });
            }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit() {

        this.profileService.data.subscribe((data) => {
            if (data && data.profileImageId) {
                this.data.profileImageId = data.profileImageId;
                console.log('data from profileService:', data);
                this.myProfile = this.userService.renderPatientProfileImage(data.id, data.profileImageId)
                    .subscribe((data) => {
                        console.log('res: ', data);
                        this.fileUrl = data;
                    });
            }
        })

        await this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            }
            );

        // Subscribe to the config changes
        await this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        await this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });
        if (this._auth.loggedIn) {
            await this._account.getAccount().then((res: any) => {
                this.data = res;
                this.currentRole = "";
                switch (this.data.ruolo) {
                    case 'TENANT_ADMINISTRATOR':
                        this.currentRole = "Admin";
                        break;
                    case 'OPERATORE':
                        this.currentRole = "Specialista";
                        break;
                    case 'AMMINISTRATORE':
                        this.currentRole = "Superadmin";
                        break;
                    case 'PAZIENTE':
                    case 'UTENTE':
                        this.currentRole = "Utente";
                        break;
                    default:
                        break;
                }

                if (res.profileImageId) {
                    this.myProfile = this.userService.renderPatientProfileImage(res.id, res.profileImageId)
                    .subscribe((data) => {
                        console.log('res: ', data);
                        this.fileUrl = data;
                    });
                   
                }
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}
