import {   
    HttpEvent, 
    HttpInterceptor, 
    HttpHandler, 
    HttpRequest 
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { TokenService } from './main/shared/services/token.service';
import { Injector } from '@angular/core';
import { environment } from 'environments/environment';

export class HttpTokenInterceptor implements HttpInterceptor {
    debugEnabled: boolean;

    constructor(
        private tokenService: TokenService
        ) {
            this.debugEnabled = environment.debugEnabled;
         }

    intercept(request: HttpRequest<any>, next: HttpHandler)
              :Observable<HttpEvent<any>> {  
                
               // this.consoleLog("------INTERCEPTOR------", new Date());

               if (!request.headers.has('authtoken') 
               || request.headers.get('authtoken') == undefined
               || request.headers.get('authtoken') == null) {
                this.consoleLog("------INTERCEPTOR1------", new Date());

                const token: string = this.tokenService.getToken();
                if(token) {
                    this.consoleLog("------INTERCEPTOR2------", new Date());
                    request = request.clone({
                        setHeaders: {
                            'authtoken': token
                        }
                    });
                }
              }
               return next.handle(request);   
           }
    consoleLog(message?: any, ...optionalParams: any[]): void {
        if(this.debugEnabled)
            console.log(message, optionalParams);
    }
}
