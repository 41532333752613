import { Component, Inject, OnInit, OnChanges, Input } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { UserService } from 'app/main/admin/services/user.service';
import { AccountService } from '../services/account.service';
import { ProfileService } from '../services/profile.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';


// To modify
@Component({
    selector: 'image-profile',
    templateUrl: './image-profile.component.html',
    styleUrls: ['./image-profile.component.scss']
})
export class ImageProfileComponent implements OnInit, OnChanges {

    // @Input() private src: string;
    // Per gestire il change
    @Input() profile: any;


    dataUrl$: any;
    fileUrl;
    myProfile: any;

    constructor(private userService: UserService, private _account: AccountService,
        private profileService: ProfileService, 
        private dialog: MatDialog,
        private snackBarMat: MatSnackBar) {
    }

    async ngOnChanges(changes: any) {

        if (this.profile && this.profile.profileImageId) {
            // console.log('data from profileService:', data);
            this.myProfile = this.userService.renderPatientProfileImage(this.profile.id, this.profile.profileImageId)
                .subscribe((data) => {
                    //  console.log('res: ', data);
                    this.fileUrl = data;
                },
                (error) => {
                    this.snackBarMat.open('Errore caricamento immagine profilo', '', {
                        duration: 3000,
                        panelClass: 'warn',
                      });
                });
        }

    }


    async ngOnInit() {

        this.fileUrl = '/assets/images/admin-image.jpg';

    }


    onClick() {
          this.onPreview();

      }
    
      public onPreview = () => {
         
        // this.location.back();
        const dialog = this.dialog.open(ImagePreviewComponent, {
          // width: '400px',
          panelClass: 'image-preview-class',
          data: {
            imageUrl: this.fileUrl,
            title: 'Anteprima',
            body: '',
            buttons: [
              {
                text: 'OK',
                close: true,
                value: true,
                callback: () => {
                  
                }
              }
            ]
          }
        });
    
        dialog.afterClosed().subscribe((datas: any) => {
          if (datas) {
            // callback(datas);
          }
        });
        
      }
} 
