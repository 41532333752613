import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'dettagli',
  templateUrl: './dettagli.component.html',
  styleUrls: ['./dettagli.component.scss']
})
export class DettagliComponent {

  constructor(
    public dialogRef: MatDialogRef<DettagliComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onClose(){
    this.dialogRef.close();
  }

}
