import { Pipe, PipeTransform } from '@angular/core';

declare let moment: any;

@Pipe({
  name: 'customDateFormatterPipe'
})
export class CustomDateFormatterPipe implements PipeTransform {

  constructor() { }

  transform(value, ...args: any[]): any {
    const date = new Date(value);
    // const formatted_date = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' - ' + date.getHours() + ':' + date.getMinutes();
    // return formatted_date;
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

}
