import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountService } from 'app/main/shared/services/account.service';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private _account: AccountService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.iconRegistry.addSvgIcon(
            'bank',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/images/bank-building.svg')
        );
        this.iconRegistry.addSvgIcon(
            'fields-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/images/note.svg')
        );
        this.iconRegistry.addSvgIcon(
            'customtimer',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/images/stop-watch.svg')
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    check(item) {
        return this._account.check(item);
        /*if (item.checkAdmin) {
            return this._account.isAdmin();
        } else if (item.checkOperator) {
            return this._account.isOperator();
        } else {
            return true;
        }*/
    }
}
