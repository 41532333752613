import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {
  autoClose: boolean;
  timeout: number;
  fileUrl: any;
  constructor(
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    // this.usefulTips = "<p><b>pluto</b></p><p>ddddfffffff</p><p><strong>pippo</strong></p><ol><li>asssdddee</li><li>bssss</li><li>v</li></ol><p><br></p>";
    /// this.loadData(this.usefulTips);

    if(this.data != undefined && this.data != null) {
        
      this.fileUrl = this.data.imageUrl;
        // this.value = this.data.qrcode;
        if(this.data.buttons != undefined && this.data.buttons != null && this.data.buttons.length > 0) {
          this.autoClose = true;
          for (let index = 0; index < this.data.buttons.length; index++) {
            const element = this.data.buttons[index];
            if(element.close != undefined && element.close != null && element.close == true) {
              this.autoClose = false;
              break;
            }
          }
        } else {
          this.autoClose = true;
          this.timeout = 3000;
          if(this.data.timeout != undefined && this.data.timeout != null)
            this.timeout = this.data.timeout;
        }
      }
  
      if(this.autoClose == true) {
        setTimeout(()=> {
          this.dialogRef.close();
        }, this.timeout);
      }
}

}
