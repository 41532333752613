import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Pipe({
  name: 'machineImagePipe'
})
export class MachineImagePipe implements PipeTransform {

  constructor(private api: ApiService) { }

  transform(machineImageId, machineId): any {
    const noCache = new Date().getTime();
    const endpoint = `bo/files/machine/renderMachineImage?machineImageId=${machineImageId}&machineId=${machineId}&noCache=` + noCache;
    const ret = this.api
      .getBlobRequest(endpoint);
    return ret;
  }

}