import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private dataSource = new BehaviorSubject(null);
  data = this.dataSource.asObservable();

  updatedProfile(data: any){
    this.dataSource.next(data);
  }

}
