import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'app/main/shared/services/api.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { FileElement } from '../file-explorer/model/element';
import { UpdateModel } from '../file-explorer/model/update-model';


export interface IFileService {
  add(fileElement: FileElement);
  delete(id: string);
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
}

@Injectable()
export class FileService implements IFileService {
  private map = new Map<string, FileElement>();

  constructor(private api: ApiService) { }

  findAllRepos(): Observable<FileElement[]> {
    // Request as transfer object <ServerData>
    return this.getRepositories().pipe(
      map(res => {
        return res['payload'].map(item => {
          const fe = new FileElement();
          fe.id = item.key;
          fe.isFolder = true;
          fe.type = 'folder';
          fe.name = item.value ? item.value : item.fileName;
          fe.parent = 'root';
          fe.Uuid = null;
          fe.repoName = item.key;
          // fe.repositoryId = item.key ? item.key : item.repoName;
          return fe;
        })
      })
    );
  }

  getFolderByName(repoName, parentUuid, folderName): Observable<any> {

    const body = {
      parentUuid: parentUuid,
      repoName: repoName
    };

    return this.getRepository(body).pipe(
      map(res => {
        return res['payload']
          .map(item => {
            console.log('item: ', item);
            return item;
          })
          .filter(v => (v.fileName === folderName && v.fileType === 'FOLDER'))
      })

    );
  }


  getFolderFiles(repoName, Uuid): Observable<any[]> {
    // Request as transfer object <ServerData>
    const body = {
      parentUuid: Uuid,
      repoName: repoName
    };

    return this.getRepository(body).pipe(
      map(res => {
        return res['payload'];
      })
    );
  }


  findAllFiles(element: any): Observable<FileElement[]> {
    // Request as transfer object <ServerData>
    const body = {
      parentUuid: element.Uuid,
      repoName: element.repoName
    };

    return this.getRepository(body).pipe(
      map(res => {
        return res['payload'].map(item => {

          return this.InitFileElement(item, { id: element.id, repoName: element.repoName });

        })
      })
    );
  }


  InitFileElement(item, element): FileElement {
    const fe = new FileElement();

    if (item.fileType === 'FOLDER') {
      fe.type = "folder";
      fe.isFolder = true;
    }
    else {
      // check type with extension to show the right icon
      let ext = this.getFileExtendionByName(item.fileName);
      if (ext) {
        ext = ("" + ext).toLowerCase();
      }
      switch (ext) {
        case 'mp3':
        case 'wav':
        //case 'wma':
        case 'ogg':
          fe.type = 'audio';
          break;
        case 'jpg':
        case 'png':
        case 'bmp':
        case 'jpeg':
        case 'gif':
        case 'tga':
          fe.type = 'image';
          break;
        case 'mp4':
        case 'mpeg':
        case 'mpg':
        case 'avi':
        //case 'wmv':
        case 'mov':
        case 'flv':
          fe.type = 'video';
          break;

        default:
          fe.type = 'document';
          break;
      }

      fe.isFolder = false;
    }

    fe.id = item.repositoryId;
    fe.name = item.fileName;
    fe.Uuid = fe.id;
    fe.deleted = item.deleted;
    fe.canView = item.canView;
    fe.canModify = item.canModify;
    fe.tags = item.tags;

    if (element) {
      fe.parent = element.id;
      fe.repoName = element.repoName;
    }

    return fe;
  }

  private getFileExtendionByName(item) {
    let elem = item.split('.');
    return elem[1];
  }


  add(fileElement: FileElement) {
    let element = this.get(fileElement.id);

    // Add if not present
    if (element === undefined || element == null) {
      this.map.set(fileElement.id, this.clone(fileElement));
    }
    else {
      this.update(fileElement.id, fileElement);
    }

    return fileElement;
  }


  delete(id: string) {
    this.map.delete(id);
  }

  update(id: string, update: Partial<FileElement>): Observable<FileElement> {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
    const body: UpdateModel = this.convertFileElementToUpdateModel(element);
    return this.updateInfo(body);
  }

  convertFileElementToUpdateModel(element: FileElement): UpdateModel {
    const body: UpdateModel = {
      repositoryId: element.id,
      canModify: element.canModify,
      canView: element.canView,
      tags: element.tags,
      fileName: element.name,
      repositoryName: element.repoName,
      parentId: element.parent == element.repoName ? null : element.parent
    }
    return body;
  }

  private querySubject: BehaviorSubject<FileElement[]>;
  queryInFolder(folderId: string) {
    const result: FileElement[] = [];
    this.map.forEach(element => {
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  get(id: string) {
    return this.map.get(id);
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }

  getRepositories() {
    return this.api.getRequest(`bo/repository/getRepositories`);
  }

  shareFile(fileId, userId) {
    return this.api.postUrlParamsRequest(`bo/repository/shareFile`, `${fileId}/${userId}`).pipe(
      map(res => {
        let item = res['payload'];

        return this.InitFileElement(item, null);

      })
    );
  }

  getPreviewRepositoryFile(fileId) {
    return this.api.postUrlParamsRequest(`bo/repository/getPreviewRepositoryFile`, `${fileId}`).pipe(
      map(res => {
        let item = res['payload'];
        if (!item)
          return null;
        return this.InitFileElement(item, null);
      })
    );
  }

  getRepository(body) {
    return this.api.postRequest('bo/repository/getRepository', body);
  }

  newFolder(body) {
    return this.api.postRequest('bo/repository/newFolder', body);
  }

  updateInfo(body): Observable<FileElement> {
    return this.api.postRequest(`bo/repository/updateInfo`, body).pipe(
      map(res => {
        let item = res['payload'];
        return this.InitFileElement(item, null);
      })
    );
  }

  getRepositoryByTag(tagId) {
    return this.api.getRequest(`bo/repository/getRepositoryByTag/${tagId}`);
  }


  deleteFolder(id): Observable<FileElement> {
    return this.api.deleteRequest(`bo/repository/updateInfo/${id}`).pipe(
      map(res => {
        let item = res['payload'];

        return this.InitFileElement(item, null);

      })
    );
  }

  addNewFolder(body: any): Observable<any> {

    return this.newFolder(body).pipe(
      map(res => {
        return res['payload'];
      })
    );
  }

  addFolder(body: any, currentFileElement: FileElement): Observable<FileElement> {

    return this.newFolder(body).pipe(
      map(res => {
        let item = res['payload'];
        let parent = body.parentId === null ? currentFileElement.id : body.parentId;

        return this.InitFileElement(item, { id: parent, repoName: body.repoName });

      })
    );
  }

  fileExtAccept() {
    return '.jpg, .png, .bmp, .flv, .avi, .mp4, .mpg, .mpeg, .mpg, .mov, .mp3, .wav,' +
      '.JPG, .PNG, .BMP, .FLV, .AVI, .MP4, .MPG, .MPEG, .MPG, .MOV, .MP3, .WAV';
  }

  uploadFile(repoName: string, repoFolderId: string, file) {
    console.log("service - uploadFile FILE 1", file);

    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('repoName', repoName);
    formData.append('repoFolderId', repoFolderId);

    file.inProgress = true;
    this.api.postFileRepositoryRequest('bo/repository/', formData).pipe(
      map(event => {
        //
        console.log("pipe event 3 - ", event)
        //
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            console.log('file.progress: ', file.progress);
            break;
          case HttpEventType.Response:
            console.log('file.progress over', file.progress);
            return event;
        }
      })
    ).subscribe((event: any) => {
      // console.log('event from uploadFile:' , event);
      if (typeof (event) === 'object') {
        // console.log(event.body);  
      }
    });

  }


  uploadImage(repoName: string, repoFolderId: string, file) {

    console.log("service - uploadImage FILE 2", file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('repoName', repoName);
    formData.append('repoFolderId', repoFolderId);

    file.inProgress = true;
    this.api.postFileRepositoryRequest('bo/repository/uploadFile', formData).pipe(
      map(event => {
        //
        console.log("pipe event 4 - ", event)
        //
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            console.log('file.progress: ', file.progress);
            break;
          case HttpEventType.Response:
            console.log('file.progress over', file.progress);
            return event;
        }
      })
    ).subscribe((event: any) => {
      // console.log('event from uploadFile:' , event);
      if (typeof (event) === 'object') {
        // console.log(event.body);  
      }
    });

  }

  uploadImageObservable(repoName: string, repoFolderId: string, file) {

    console.log("uploadImageObservable - uploadImage FILE 3", file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('repoName', repoName);
    formData.append('repoFolderId', repoFolderId);

    file.inProgress = true;
    return this.api.postFileRepositoryRequest('bo/repository/uploadFile', formData).pipe(
      map(event => {
        //
        console.log("pipe event 5 - ", event)
        //
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            console.log('file.progress: ', file.progress);
            break;
          case HttpEventType.Response:
            console.log('file.progress over', file.progress);
            return event;
        }
      })
    )
  }


  getFile(id, contentType?) {
    return this.api.getBlobRequestUnMapped(`bo/repository/getFile/${id}`, contentType);
  }

  uploadTmpImage(blob: Blob) {
    const formData = new FormData();
    formData.append('file', blob);
    return this.api.postFileRequest(`bo/files/single/temp/upload`, formData);
  }
}


