import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';

import { CommonModule } from '@angular/common';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatButtonModule, MatCardModule, MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { SharedModule } from 'app/main/shared/shared.module';
import { ApplicationPipesModule } from 'app/main/application-pipes/application-pipes.module';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        SharedModule,
        VerticalLayout1Module,
        MatCardModule,
        ApplicationPipesModule,
        MatProgressBarModule,
        MatProgressSpinnerModule
    ],
    exports: [
        VerticalLayout1Module,
    ],
    declarations: []
})
export class LayoutModule {
}
