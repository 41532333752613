import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-component',
  templateUrl: './alert-component.component.html',
  styleUrls: ['./alert-component.component.scss']
})
export class AlertComponentComponent implements OnInit {
  timeout: number;
  autoClose: boolean;

  constructor(
    public dialogRef: MatDialogRef<AlertComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    if(this.data != undefined && this.data != null) {
      if(this.data.buttons != undefined && this.data.buttons != null && this.data.buttons.length > 0) {
        this.autoClose = true;
        for (let index = 0; index < this.data.buttons.length; index++) {
          const element = this.data.buttons[index];
          if(element.close != undefined && element.close != null && element.close == true) {
            this.autoClose = false;
            break;
          }
        }
      } else {
        this.autoClose = true;
        this.timeout = 3000;
        if(this.data.timeout != undefined && this.data.timeout != null)
          this.timeout = this.data.timeout;
      }
    }

    if(this.autoClose == true) {
      setTimeout(()=> {
        this.dialogRef.close();
      }, this.timeout);
    }
  }

  onButtonClick(item) {
    if(item == undefined || item == null)
      return;
    let value = null;
    if(item.value != undefined && item.value != null)
      value = item.value;
    if(item.callback != undefined && item.callback != null)
      item.callback(value);
    if(item.close != undefined && item.close != null && item.close == true)
      this.dialogRef.close(value);
  }

}
