import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'currencyLicense'})
export class CurrencyLicensePipe implements PipeTransform {
  transform(value: any) {
      if(value === 'EUR')
            return '€';

      return value;      
  }
}

@Pipe({name: 'periodLicense'})
export class PeriodLicensePipe implements PipeTransform {
  transform(value: any) {
      if(value === 'MONTH')
            return 'Mese';
      if(value === 'WEEK')
            return 'Settimana';
      if(value === 'DAY')
            return 'Giorno';      
      return value;      
  }
}