import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/types';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  toCheckUno: boolean;
  auths: any = {};

  constructor(private storage: StorageService,
    private _fuseNavigationService: FuseNavigationService) { }

  setAccount(body: any) {
    this.storage.set('ACCOUNT', JSON.stringify(body));
    if (body.ruolo)
      this.setRole(body.ruolo);
    if (body.currentTenant)
      this.setCurrentTenant(body.currentTenant);
  }

  deleteAccount() {
    this.storage.remove('ACCOUNT');
    this.storage.remove('ROLE');
    this.storage.remove('CURRENT_TENANT');
    this.setAuths([]);
  }

  setRole(body: string) {
    this.storage.set('ROLE', body);
  }

  setCurrentTenant(body) {
    this.storage.set('CURRENT_TENANT', JSON.stringify(body));
  }

  getRole() {
    return this.storage.get('ROLE');
  }

  isAdmin() {
    return this.getRole() === 'AMMINISTRATORE';
  }

  isOperator() {
    return this.getRole() === 'OPERATORE';
  }

  /*isBankAdmin() {
    return (this.getRole() === 'BANK_ADMINISTRATOR') || (this.getRole() === 'AMMINISTRATORE');
  }*/

  isTenantAdministrator() {
    return this.getRole() === 'TENANT_ADMINISTRATOR';
  }





  async getAccount() {
    let account = '{}';
    if (this.storage.get('ACCOUNT') !== null) {
      account = await this.storage.get('ACCOUNT');
    }
    return await JSON.parse(account);
  }

  getAccountSync() {
    let account = '{}';
    if (this.storage.get('ACCOUNT') !== null) {
      account = this.storage.get('ACCOUNT');
    }
    return JSON.parse(account);
  }

  setAuths(body: any) {
    this.auths = body;
    this.storage.set('AUTHS', JSON.stringify(body));
    //this.updateNavigation();
  }

  async getAuths() {
    let auths = '{}';
    if (this.storage.get('AUTHS') !== null) {
      auths = await this.storage.get('AUTHS');
    }
    return await JSON.parse(auths);
  }

  check(item: FuseNavigationItem) {

    //console.log(item);
    let auths = this.auths;
    if (item.bemethod != undefined && item.bemethod != null &&
      auths['methodsAuths'] != undefined &&
      auths['methodsAuths'] != null) {
      if (auths['methodsAuths'].includes(item.bemethod))
        return true;
      return false;
    } else if (item.canViewFunction) {
      return item.canViewFunction(this.getAccountSync(), this.auths, null);
    } else if (item.children != undefined && item.children != null) {
      for (let index = 0; index < item.children.length; index++) {
        const element = item.children[index];
        if (this.check(element))
          return true;
      }
      return false;
    } /*else {
        return true;
      }*/
    // TODO: decommentare l'else e rimuovere il blocco sottostante quando saranno impostati correttamente tutti i permessi
    if (item.checkAdmin) {
      return this.isAdmin();
    } else if (item.checkOperator) {
      return this.isOperator();
    } else {
      return true;
    }
    return false;
  }

  /**
   * 
   * @param beMethod 
   * @returns 
   */
  checkAuth(beMethod: string): boolean {
    if (beMethod != undefined && beMethod != null &&
      this.auths['methodsAuths'] != undefined &&
      this.auths['methodsAuths'] != null && this.auths['methodsAuths'].includes(beMethod)) {
      return true;
    }
    return false;
  }

  /*updateNavigation() {
    const navigation = this._fuseNavigationService.getCurrentNavigation();
    console.log('updateNavigation',navigation);
  }*/

}
