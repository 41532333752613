import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideocallService {
 
  private videoCalls : Array<any>;

  constructor() {
    this.videoCalls = new Array<any>();
  }

  
  addVideo(currentContact: { contactId: string; socketSessionId: any; }, isEmergency: boolean, emergencyId: string, isRosCam: boolean) {
    console.log('VideoCallService addVideo')
    const idx = this.videoCalls.findIndex(x => x.contactId == currentContact.contactId);
    const newVid = {
      contactId: currentContact.contactId,
      socketSessionId: currentContact.socketSessionId,
      isEmergency: isEmergency,
      emergencyId: emergencyId
    };
    if(idx < 0)
      this.videoCalls.push(newVid)
    else
      this.videoCalls.splice(idx,1,newVid);
    console.log('VideoCallService addVideo', this.videoCalls)

  }

  removeVideo(currentContact: { contactId: string; socketSessionId: any; }) {
    console.log('VideoCallService removeVideo')
    if(currentContact == undefined || currentContact == null)
      return;
    const idx = this.videoCalls.findIndex(x => x.socketSessionId == currentContact.socketSessionId);
    if(idx > 0)
      this.videoCalls.splice(idx, 1);
    else {
      console.log('VideoCallService removeVideo not found', idx, currentContact)
    }
    console.log('VideoCallService removeVideo', this.videoCalls)

  }

  removeAllVideo() {
    this.videoCalls = new Array<any>();
    console.log('VideoCallService removeAllVideo')
  }

  isInCall(): boolean {
    return this.videoCalls.length > 0;
  }

  getCurrentContacts() {
    return this.videoCalls;
  }

}
