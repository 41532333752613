import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Pipe({
  name: 'patientProfileImage'
})
export class PatientProfileImagePipe implements PipeTransform {

  constructor(private api: ApiService) { }

  transform(profileImageId, userInfoId): any {
    const noCache = new Date().getTime();
    const endpoint = `bo/files/user/renderProfileImage?profileImageId=${profileImageId}&userInfoId=${userInfoId}&noCache=` + noCache;
    const ret = this.api
      .getBlobRequest(endpoint);
    return ret;
  }

}