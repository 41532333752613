import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatToolbarModule, MatCheckbox, MatCheckboxModule, MatProgressSpinnerModule } from '@angular/material';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdminEditComponent } from '../admin/admin-edit/admin-edit.component';
// import { ApiService } from './services/api.service';
import { DettagliComponent } from './dettagli/dettagli.component';
import { AdminGuard } from './guards/admin.guard';
import { AppGuard } from './guards/app.guard';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { SecurePipe } from './pipes/secure.pipe';
import { ProfileComponent } from './profile/profile.component';
import { ErrorHandlerService } from './services/error-handler.service';
import { StorageService } from './services/storage.service';
import { TokenService } from './services/token.service';
import { EliminazioneSegnalazioneComponent } from '../apps/videocall/elimina-segnalazione/elimina-segnalazione.component';
import { AlertComponentComponent } from './alert/alert-component/alert-component.component';
import { QrCodeComponent } from './alert/qr-code/qr-code.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { AdminMessageComponent } from '../admin/admin-message/admin-message.component';
import { ProfileService } from './services/profile.service';
import { ImageProfileComponent } from './image-profile/image-profile.component';
import { UsefulTipsComponent } from './useful-tips/useful-tips.component';
import { MatCardModule } from '@angular/material';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http'
import { PreviewUsefulTipsComponent } from './preview-useful-tips/preview-useful-tips.component';
import { VideocallComponent } from './videocall/videocall.component';
import { ImageGenericComponent } from './image-generic/image-generic.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ChatComponent } from './chat/chat.component';
import { NgChatModule } from 'ng-chat';
import { ChatService } from './services/chat.service';
import { ChatOperatorComponent } from './chat-operator/chat-operator.component';
import { PricePlanComponent } from './price-plan/price-plan.component';
import { ApplicationPipesModule } from '../application-pipes/application-pipes.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { EliminazioneComponent } from './eliminazione/eliminazione.component';
import { FileService } from '../admin/services/file.service';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { ImageCropMachineComponent } from './image-crop-machine/image-crop-machine.component';


@NgModule({
  declarations: [
    DettagliComponent,
    ProfileComponent,
    EliminazioneComponent,
    EliminazioneSegnalazioneComponent,
    SecurePipe,
    AdminEditComponent,
    AdminMessageComponent,
    ImageCropComponent,
    ImageCropMachineComponent,
    AlertComponentComponent,
    QrCodeComponent,
    ImageProfileComponent,
    UsefulTipsComponent,
    PreviewUsefulTipsComponent,
    VideocallComponent,
    ImageGenericComponent,
    ImagePreviewComponent,
    MediaPreviewComponent,
    ChatComponent,
    ChatOperatorComponent,
    PricePlanComponent,
    DynamicFormComponent,
    DynamicTableComponent
  ],
  entryComponents: [
    DettagliComponent,
    ProfileComponent,
    EliminazioneComponent,
    EliminazioneSegnalazioneComponent,
    AdminEditComponent,
    DynamicFormComponent,
    ImageCropComponent,
    ImageCropMachineComponent,
    AlertComponentComponent,
    QrCodeComponent,
    AdminMessageComponent,
    ImageProfileComponent,
    ImageGenericComponent,
    UsefulTipsComponent,
    PreviewUsefulTipsComponent,
    VideocallComponent,
    ImagePreviewComponent,
    MediaPreviewComponent,
    ChatComponent,
    ChatOperatorComponent,
    PricePlanComponent
  ],
  imports: [
    DragDropModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    NgxQRCodeModule,
    MatCardModule,
    AngularEditorModule,
    HttpClientModule,
    //NG-CHAT
    NgChatModule,
    ApplicationPipesModule,
    MatProgressSpinnerModule
  ],
  exports: [
    MatDialogModule,
    DettagliComponent,
    SecurePipe,
    ImageProfileComponent,
    ImageGenericComponent,
    UsefulTipsComponent,
    VideocallComponent,
    ChatComponent,
    ChatOperatorComponent,
    PricePlanComponent
  ],
  providers: [
    TokenService,
    StorageService,
    ErrorHandlerService,
    AppGuard,
    AdminGuard,
    ProfileService,
    ChatService,
    FileService
  ],
})
export class SharedModule { }
