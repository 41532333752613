import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FuseUtils } from '@fuse/utils';
import { NotificationService } from 'app/main/services/notification.service';
import { UserService } from 'app/main/admin/services/user.service';
import { resolve } from 'dns';



@Injectable()
export class ChatPanelService
{
    contacts: any[];
    chats: any[];
    user: any;
    operators: any[] = [];
    myUserUuid: never;
    fileUrl: any;
    objContacts: {};
    objUsers: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private notificationService: NotificationService,
        private userService: UserService
    )
    {
    }

    /**
     * Loader
     *
     * @returns {Promise<any> | any}
     */
    loadContacts(): Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContacts(),
                this.getUser()
            ]).then(
                ([contacts, user]) => {
                    this.contacts = JSON.parse(contacts);
                    this.user = JSON.parse(user);
                    console.log(this.contacts);
                    console.log(this.user);         
                    resolve();
                },
                reject
            );
        });
    }

  


    mergeOperators(payload: []) {
        let toRemove = [];
        for (let index = 0; index < this.operators.length; index++) {
            const element = this.operators[index];
            const found = payload.find((v)=>{
                return v['userId'] == element.userId;
            })
            if(found == undefined || found == null) {
                toRemove.push(index);
            } else {
                this.operators[index].status = found['status'];
                this.operators[index].sessionId = found['sessionId'];
            }
        }
        toRemove = toRemove.sort((a, b) => {
            if(a == b)
                return 0;
            if(a < b)
                return -1;
            if(a > b)
                return 1;
        })

        for(let index = toRemove.length-1; index >=0; index--) {
            this.operators.splice(toRemove[index], 1);
        }

        for(let index = 0; index < payload.length; index++) {
            if(payload[index]['userId'] == this.myUserUuid)
                continue;
            const found = this.operators.find((v)=>{
                return v['userId'] == payload[index]['userId'];
            })
            if(found == undefined || found == null) {
                this.operators.push(payload[index]);
            }
        }
    }
    
      
    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */

    getContacts(): Promise<any>
    {

        return new Promise((resolve, reject) => {

            this.notificationService.getOperators().subscribe((res: any) => {
                // console.log(res)
                if (res.payload) {
                    this.mergeOperators(res.payload)
                }
    
                var listOperator = [];
                for (let i = 0 ; i <= this.operators.length; i++){
                    if (this.operators[i] != undefined && this.operators[i] != null)
                    {
                        if (this.operators[i] && this.operators[i].profileImageId) {
                            // console.log('data from profileService:', data);
                             this.userService.renderPatientProfileImage(this.operators[i].id, this.operators[i].profileImageId)
                                .subscribe((data) => {
                                    //  console.log('res: ', data);
                                    this.fileUrl = data;
                                });
                        }
                        var obj: any = {};
                        obj.id =  this.operators[i].id;
                        obj.name = this.operators[i].name + ' ' + this.operators[i].surname;
                        obj.avatar = '/assets/images/admin-image.jpg';
                        obj.status = this.operators[i].status.toLowerCase();
                        obj.mood = '';
                        obj.unread = 0;
                        listOperator.push(obj);
                        //console.log(listOperator);
                    }
                }

                // this.objContacts = JSON.stringify(listOperator);
                const contacts = JSON.stringify(listOperator);
                resolve(contacts);
            });
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        
        return new Promise((resolve, reject) => {

            this.notificationService.getOperators().subscribe((res: any) => {
                // console.log(res)
                if (res.payload) {
                    this.mergeOperators(res.payload)
                }
                
                var listUsers = [];
                for (let i = 0 ; i <= this.operators.length; i++){
                    if (this.operators[i] != undefined && this.operators[i] != null)
                    {
                        if (this.operators[i] && this.operators[i].profileImageId) {
                            // console.log('data from profileService:', data);
                             this.userService.renderPatientProfileImage(this.operators[i].id, this.operators[i].profileImageId)
                                .subscribe((data) => {
                                    //  console.log('res: ', data);
                                    this.fileUrl = data;
                                });
                        }
                        var obj: any = {};
                        obj.id =  this.operators[i].id;
                        obj.name = this.operators[i].name + ' ' + this.operators[i].surname;
                        obj.avatar = '/assets/images/admin-image.jpg';
                        obj.status = this.operators[i].status.toLowerCase();
                        obj.mood = '';
                        obj.chatList = [];
                        listUsers.push(obj);
                    }
                }
                // console.log(this.objUsers);
                const user = JSON.stringify(listUsers);
                resolve(user);
            });

        

        });
    }


    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any>
    {
        const chats = [
            {
                'id'    : '1725a680b3249760ea21de52',
                'dialog': [
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                        'time'   : '2017-03-22T08:54:28.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'I’m having breakfast right now, can’t you wait for 10 minutes?',
                        'time'   : '2017-03-22T08:55:28.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'I’m having breakfast right now, can’t you wait for 10 minutes?',
                        'time'   : '2017-03-22T08:55:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'We are losing money! Quick!',
                        'time'   : '2017-03-22T09:00:28.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                        'time'   : '2017-03-22T09:02:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'You are the worst!',
                        'time'   : '2017-03-22T09:05:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'We are losing money! Quick!',
                        'time'   : '2017-03-22T09:15:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'You are the worst!',
                        'time'   : '2017-03-22T09:05:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'We are losing money! Quick!',
                        'time'   : '2017-03-22T09:15:28.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                        'time'   : '2017-03-22T09:20:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'You are the worst!',
                        'time'   : '2017-03-22T09:22:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'We are losing money! Quick!',
                        'time'   : '2017-03-22T09:25:28.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                        'time'   : '2017-03-22T09:27:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'You are the worst!',
                        'time'   : '2017-03-22T09:33:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'You are the worst!',
                        'time'   : '2017-03-22T09:33:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'We are losing money! Quick!',
                        'time'   : '2017-03-22T09:35:28.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                        'time'   : '2017-03-22T09:45:28.299Z'
                    },
                    {
                        'who'    : '5725a680b3249760ea21de52',
                        'message': 'You are the worst!',
                        'time'   : '2017-03-22T10:00:28.299Z'
                    }
                ]
            },
            {
                'id'    : '2725a680b8d240c011dd2243',
                'dialog': [
                    {
                        'who'    : '5725a680606588342058356d',
                        'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                        'time'   : '2017-04-22T01:00:00.299Z'
                    },
                    {
                        'who'    : '5725a6802d10e277a0f35724',
                        'message': 'I’m having breakfast right now, can’t you wait for 10 minutes?',
                        'time'   : '2017-04-22T01:05:00.299Z'
                    },
                    {
                        'who'    : '5725a680606588342058356d',
                        'message': 'We are losing money! Quick!',
                        'time'   : '2017-04-22T01:10:00.299Z'
                    }
                ]
            },
            {
                'id'    : '3725a6809413bf8a0a5272b4',
                'dialog': [
                    {
                        'who'    : '5725a68009e20d0a9e9acf2a',
                        'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                        'time'   : '2017-04-22T02:10:00.299Z'
                    }
                ]
            }
        ];
        this.user.chatList = chats;
        console.log('DOmenico', this.user.chatList);

        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId;
        });

        // Get the chat
        return new Promise((resolve, reject) => {

            // If there is a chat with this user, return that.
            if ( chatItem )
            {
                this._httpClient.get('api/chat-panel-chats/' + chatItem.chatId)
                    .subscribe((chat) => {

                        // Resolve the promise
                        resolve(chat);

                    }, reject);


                


                resolve(chats[0]);
            }
            // If there is no chat with this user, create one...
            else
            {
                this.createNewChat(contactId).then(() => {

                    // and then recall the getChat method
                    this.getChat(contactId).then((chat) => {
                        resolve(chat);
                    });
                });
            }
        });
    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any>
    {
        return new Promise((resolve, reject) => {

            // Generate a new id
            const chatId = FuseUtils.generateGUID();

            // Prepare the chat object
            const chat = {
                id    : chatId,
                dialog: []
            };

            // Prepare the chat list entry
            const chatListItem = {
                chatId         : chatId,
                contactId      : contactId,
                lastMessageTime: '2017-02-18T10:30:18.931Z'
            };

            // Add new chat list item to the user's chat list
            this.user.chatList.push(chatListItem);

            // Post the created chat to the server
            this._httpClient.post('api/chat-panel-chats', {...chat})
                .subscribe(() => {

                    // Post the updated user data to the server
                    this._httpClient.post('api/chat-panel-user/' + this.user.id, this.user)
                        .subscribe(() => {

                            // Resolve the promise
                            resolve();
                        });
                }, reject);
        });
    }

    /**
     * Update the chat
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateChat(chatId, dialog): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const newData = {
                id    : chatId,
                dialog: dialog
            };

            this._httpClient.post('api/chat-panel-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                }, reject);
                
        });
    }

}
