export const environment = {
  production: false,
  precollaudo: false,
  collaudo: false,
  local: false,
  hmr: false,
  demo:true,
  isProxyDev: false,
  isProxyCol: false,
  isProxyVpn: false,
  isProxyProd: false,
  debugEnabled: true,  
  appUrl: "/videosop/api",
  webSocketUrl: 'wss://videosop.mare.net/videosop/videosop',
  streamerUrl: 'https://stream.remoteoperationsupport.com/webrtcstreamer.html?video=',
  ICE_SERVERS: [
      { urls: ['turn:turn.videosop.it:3478'], credential: "RosTurn2020", username: "turnros" },
      { urls: ['turn:turn.remoteoperationsupport.com:3478'], credential: "RosTurn202", username: "turnros" },
      { urls: 'stun:stun.l.google.com:19302' }
    ]
};
