import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserService } from 'app/main/admin/services/user.service';
import { AccountService } from 'app/main/shared/services/account.service';
import { AuthService } from 'app/main/shared/services/auth.service';
import { ErrorHandlerService } from 'app/main/shared/services/error-handler.service';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebsocketService } from 'app/main/shared/services/websocket.service';


@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        private _accountService: AccountService,
        private _errorHandler: ErrorHandlerService,
        private _auth: AuthService,
        private _websocketService: WebsocketService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        if (this._auth.loggedIn()) {
            this._userService.getUserInfo().subscribe((res: any) => {
                this._accountService.setAccount(res.payload);
                this._accountService.setRole(res.payload.ruolo);
                this._websocketService.checkService();
            });
            this._auth.getAuthMap().subscribe((res: any) => {
                this._accountService.setAuths(res.payload);
            });
        }
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
}
