import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { EventInteractionService } from './event-interaction.service';
import { tokenKey } from '@angular/core/src/view';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private storage: StorageService,
    private eventInteractionService: EventInteractionService
    ) { }

  getToken() {
    const token =  this.storage.get('TOKEN');
    if(token == undefined || token == null){
      this.eventInteractionService.publishData({
        event: 'LOGOUT'
      })
    }
    return token;
  }

  setToken(token: string) {
    if(token == undefined || token == null){
      this.eventInteractionService.publishData({
        event: 'LOGOUT'
      })
    } else {
        this.eventInteractionService.publishData({
          event: 'LOGIN'
        })
    }
    return this.storage.set('TOKEN', token);
  }

  deleteToken() {
      this.eventInteractionService.publishData({
        event: 'LOGOUT'
      })
    return this.storage.remove('TOKEN');
  }

}
