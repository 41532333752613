import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';

@Component({
  selector: 'image-generic',
  templateUrl: './image-generic.component.html',
  styleUrls: ['./image-generic.component.scss']
})
export class ImageGenericComponent implements OnInit {

  // @Input() private src: string;
  // Per gestire il change
  @Input() functionToLoad: any;
  @Input() elementId: any;
  @Input() classes: any;
  @Input() openPreview: boolean;

  dataUrl$: any;
  fileUrl;
  myProfile: any;

  constructor(
    private snackBarMat: MatSnackBar,
    private dialog: MatDialog 
    ) {
  }

  async ngOnChanges(changes: any) {

  }


  async ngOnInit() {
    if(this.openPreview == undefined || this.openPreview == null)
      this.openPreview = true;
    if(this.classes == undefined || this.classes == null)
      this.classes = 'avatar';
    setTimeout(() => {
      this.loadImage();
    }, 100);
  }

  onClick() {
    if(this.openPreview) {
      this.onPreview();
    }
  }

  public onPreview = () => {
    let data = null;
     
    // this.location.back();
    const dialog = this.dialog.open(ImagePreviewComponent, {
      // width: '400px',
      panelClass: 'image-preview-class',
      data: {
        imageUrl: this.fileUrl,
        title: 'Anteprima',
        body: '',
        buttons: [
          {
            text: 'OK',
            close: true,
            value: true,
            callback: () => {
              
            }
          }
        ]
      }
    });

    dialog.afterClosed().subscribe((datas: any) => {
      if (datas) {
        // callback(datas);
      }
    });
    
  }

  loadImage() {
    if (this.functionToLoad && this.elementId) {
      let ret = this.functionToLoad(this.elementId);
      ret.subscribe((data) => {
        console.log('res: ', data);
        this.fileUrl = data;
      },
        (error) => {
          this.snackBarMat.open('Errore caricamento immagine ', '', {
            duration: 3000,
            panelClass: 'warn',
          });
        })
    }

  }
}
