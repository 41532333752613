import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUpperTrim'
})
export class ToUpperTrimPipe implements PipeTransform {

  transform(value: any): any {
    return value.replace('_', ' ').toUpperCase();
  }

}
