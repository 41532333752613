import { Injectable } from '@angular/core';
import { ApiService } from 'app/main/shared/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    constructor(private api: ApiService) { }

    sendNotificationForVideocall(contactId) {
        return this.api.getRequest('bo/push/sendForCall/' + contactId);
    }

    sendGenericNotification(data) {
        return this.api.postRequest('bo/push/sendGeneric', data);
    }
}