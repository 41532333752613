import { Injectable } from '@angular/core';
import { Emergency } from '../models/emergency';
import { NotificationService } from 'app/main/services/notification.service';
import { VideocallEventInteractionService } from 'app/main/apps/videocall/eventWebSocket.service';
import { EventInteractionService } from './event-interaction.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmergenciesService {
  


  private emergencies: Array<Emergency>;

  constructor(
    private notificationService: NotificationService,
    private videocallEventInteractionService: VideocallEventInteractionService,
    private eventInteractionService: EventInteractionService
  ) {
    this.emergencies = new Array<Emergency>();
    console.log('---------------------EmergenciesService--------------');
  }

  private emergencySubject = new Subject<{event: string, emergency: Emergency}>();

    publishData(data: {event: string, emergency: Emergency}) {
        this.emergencySubject.next(data);
    }

    getObservable(): Subject<{event: string, emergency: Emergency}> {
        return this.emergencySubject;
    }

  addEmergency(em: Emergency) {
    const emp = this.getEmergencyById(em.emergencyId);
    if (emp == undefined)
      this.emergencies.push(em);
    else
      this.mergeEmergency(emp, em);
    console.log('addEmergency', this.emergencies);
    this.publishData({
      event: 'addEmergency',
      emergency: em
    })
  }

  updateEmergency(em: Emergency) {
    const emp = this.getEmergencyById(em.emergencyId);
    if (emp == undefined)
      console.log('update undefined emergency!!!');
    else
      this.mergeEmergency(emp, em);
    console.log('updateEmergency', this.emergencies);
    this.publishData({
      event: 'updateEmergency',
      emergency: em
    })
  }

  getEmergencyById(emergencyId: string): Emergency | undefined {
    if (emergencyId == undefined || emergencyId == null)
      return undefined;
    return this.emergencies.find(x => {
      return x.emergencyId == emergencyId
    });
  }

  getEmergencyByUserId(userId: string): Emergency | undefined {
    if (userId == undefined || userId == null)
      return undefined;
    return this.emergencies.find(x => {
      return x.userId == userId
    });
  }

  removeEmergencyById(emergencyId: string, successCallback: (res: any) => void, errorCallback: (error: any) => void): boolean {
    if (emergencyId == undefined || emergencyId == null)
      return false;
    const idx = this.emergencies.findIndex(x => {
      return x.emergencyId == emergencyId
    });
    if (idx < 0)
      return false;
    this._removeEmergency(idx, successCallback, errorCallback);
      console.log('remove emergency', this.emergencies);

  }

  removeEmergencyByUserId(userId: any, successCallback: (res: any) => void, errorCallback: (error: any) => void) {
    if (userId == undefined || userId == null)
      return false;
    const idx = this.emergencies.findIndex(x => {
      return x.userId == userId
    });
    if (idx < 0)
      return false;
    this._removeEmergency(idx, successCallback, errorCallback);
    console.log('remove emergency', this.emergencies);
  }

  private _removeEmergency(idx: number, successCallback: (res: any) => void, errorCallback: (error: any) => void) {
    const em = this.emergencies[idx];
    this.notificationService.closeEmergencyByPatientId(em.userId).subscribe((res: any) => {
      this.notificationService.getEmergencyNotfications('OPEN, PROCESSING').subscribe((resp: any) => {
        this.notificationService.updateEmergencyNotificationsValue(true);
      });
        // this.askForNote(this._formBuilder, this.notificationService, emergencyId);
        this.eventInteractionService.publishData({ 
          event: 'askForNotes', 
          contactId: em.userId, 
          emergencyId: em.emergencyId });

      if(successCallback != undefined && successCallback != null) {
        successCallback(res);
      }
    },
    (error)=>{
      if(errorCallback != undefined && errorCallback != null) {
        errorCallback(error);
      }
    });
  this.emergencies.splice(idx, 1);
  this.publishData({
    event: 'removeEmergency',
    emergency: em
  })
  }

  removeClosedEmergencyById(emergencyId: string, successCallback: (res: any) => void, errorCallback: (error: any) => void): boolean {
    if (emergencyId == undefined || emergencyId == null)
      return false;
    const idx = this.emergencies.findIndex(x => {
      return x.emergencyId == emergencyId
    });
    if (idx < 0)
      return false;
    this._removeClosedEmergency(idx, successCallback, errorCallback);
      console.log('remove emergency', this.emergencies);
  }

  private _removeClosedEmergency(idx: number, successCallback: (res: any) => void, errorCallback: (error: any) => void) {
    const em = this.emergencies[idx];
      this.notificationService.getEmergencyNotfications('OPEN, PROCESSING').subscribe((resp: any) => {
        this.notificationService.updateEmergencyNotificationsValue(true);
      });
        // this.askForNote(this._formBuilder, this.notificationService, emergencyId);
        this.eventInteractionService.publishData({ 
          event: 'askForNotes', 
          contactId: em.userId, 
          emergencyId: em.emergencyId });

      if(successCallback != undefined && successCallback != null) {
        successCallback(em);
      }

  this.emergencies.splice(idx, 1);
  this.publishData({
    event: 'removeClosedEmergency',
    emergency: em
  })
  }

  mergeEmergency(emp: Emergency, em: Emergency): void {
    emp.chat = em.chat;
    emp.videocallId = em.videocallId;
    emp.userId = em.userId;
  }

  processEmergency(contactId: any, emergencyId: any, type: string, success?, errorCallback?) {
    let emergency = new Emergency(emergencyId, contactId);
    this.addEmergency(emergency);
    this.notificationService.processingEmergency(emergencyId, { type: type }).subscribe((res) => {
      emergency.status = 'PROCESSING';
      if (type == 'VIDEO') {
        emergency.videoRequested = true;
      } else {
        emergency.chatRequested = true;
      }
      this.updateEmergency(emergency);
      if (success != undefined && success != null) {
        success(res);
        return;
      }
      if (type == 'VIDEO') {
        // this.videocallEventInteractionService.publishData({event:'toolbarVideocallStart', contactId: contactId, emergencyId: emergencyId})
        this.eventInteractionService.publishData({ event: 'toolbarVideocallStart', contactId: contactId, emergencyId: emergencyId })
      } else {
        this.eventInteractionService.publishData({ event: 'OPENCHAT', contactId: contactId, emergencyId: emergencyId })
      }
      this.publishData({
        event: 'processEmergency',
        emergency: emergency
      })
    }, (error) => {
      if (errorCallback != undefined && errorCallback != null)
        errorCallback(error);
    });
  }

  tryToUpdateEmergency(emergencyId: string, action: string, type: string, callback: (res: any) => void) {
    if (emergencyId == undefined || emergencyId == null) {
      console.log('tryToUpdateEmergency but emergencyId is null!!!');
      return;
    }
    let em = this.getEmergencyById(emergencyId);
    if (em == undefined) {
      console.log('try to update undefined emergency!');
      return;
    }

    switch (action) {
      case 'request':
        if (type == 'VIDEO')
          em.videoRequested = true;
        else
          em.chatRequested = true;
        break;
      case 'add':
        if (type == 'VIDEO')
          em.videoEnabled = true;
        else
          em.chatEnabled = true;
        break;
      case 'unrequest':
        if (type == 'VIDEO')
          em.videoRequested = false;
        else
          em.chatRequested = false;
        break;
      case 'remove':
        if (type == 'VIDEO')
          em.videoEnabled = false;
        else
          em.chatEnabled = false;
        break;
      default:
        break;
    }
    this.publishData({
      event: 'tryToUpdateEmergency',
      emergency: em
    })
    console.log('tryToUpdateEmergency', this.emergencies);
    if(callback != undefined && callback != null) {
      callback(em);
    }
  }
}
