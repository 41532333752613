import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    loaderEl: any;
    loaderTextEl: HTMLElement;
    loaderSpinnerEl: HTMLElement;
    player: AnimationPlayer;

    /**
     * Constructor
     *
     * @param {AnimationBuilder} _animationBuilder
     * @param _document
     * @param {Router} _router
     */
    constructor(
        private _animationBuilder: AnimationBuilder,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router
    ) {
        // Initialize
        this._init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void {
        // Get the splash screen element
        this.loaderEl = this._document.body.querySelector('#app-loader-screen');
        this.loaderTextEl= this._document.body.querySelector('#app-loader-screen-text');
        this.loaderSpinnerEl= this._document.body.querySelector('#app-loader-screen-spinner');
        setTimeout(() => {
            this.hide();
        }); 
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(fromText?): void {
        if(!fromText || fromText == false ) {
            if(this.loaderSpinnerEl) {
                this.loaderSpinnerEl.style.display = 'block';
            }
        }
        
      console.log('loader service show!')
        this.player =
            this._animationBuilder
                .build([
                    style({
                        opacity: '0',
                        zIndex: '99999'
                    }),
                    animate('400ms ease', style({ opacity: '1' }))
                ]).create(this.loaderEl);

        setTimeout(() => {
            this.player.play();
        }, 0);
    }

    /**
     * Hide the splash screen
     */
    hide(): void {
        
        
      console.log('loader service hide!')
        this.player =
            this._animationBuilder
                .build([
                    style({ opacity: '1' }),
                    animate('400ms ease', style({
                        opacity: '0',
                        zIndex: '-10'
                    }))
                ]).create(this.loaderEl);

        setTimeout(() => {
            this.player.play();
        }, 0);
        setTimeout(() => {
            if(this.loaderTextEl) {
                this.loaderTextEl.innerHTML = '';
                this.loaderTextEl.style.display = 'none';
            }
            if(this.loaderEl) {
                this.loaderEl.style.backgroundColor = "transparent";
            }
            if(this.loaderSpinnerEl) {
                this.loaderSpinnerEl.style.display = 'block';
            }
        }, 400);
    }


    showText(text: string) {
        if(this.loaderTextEl) {
            this.loaderTextEl.innerHTML = text;
            this.loaderTextEl.style.display = 'block';
        }

        if(this.loaderSpinnerEl) {
            this.loaderSpinnerEl.style.display = 'none';
        }

        if(this.loaderEl) {
            //this.loaderEl.style.backgroundColor = "rgba(45, 60, 61, 0.4)";
        }
        this.show(true);
    }
}
