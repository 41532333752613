import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { DataSourceService } from "./data-source-service";

export class PagedDataSource<T> implements DataSource<T>{

    constructor(
        public loadPagedData: (specificParameters: any, filterKey: string[], filterValue: string, sortParam: string, sortDirection: "ASC" | "DESC", pageIndex: number, pageSize: number) => Observable<ArrayBuffer>,
        public setLocalCounter: (v) => void
        ) {
            console.log('PagedDataSource costructor')
        }

    dataSubject = new BehaviorSubject<Array<T>>([]);
    loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();

    filter:any;
    sortingDataAccessor: any;
    data: any;

    connect(collectionViewer: CollectionViewer): Observable<Array<T>> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
    loadData(
        specificParameters: any,
        filterKey: string[],
        filterValue: string,
        sortParam: string,
        sortDirection:  'ASC' | 'DESC', 
        pageIndex: number, 
        pageSize: number) {
            console.log('loadData...')
            this.loadingSubject.next(true);

            this._loadDataImplementation(specificParameters,
                filterKey,
                filterValue,
                sortParam,
                sortDirection, 
                pageIndex, 
                pageSize).pipe(
                    catchError(() => of([])),
                    finalize(() => {
                        console.log('loadData finalize!')
                        this.loadingSubject.next(false)
                    })
                ).subscribe((resp: any) => {
                    this.setLocalCounter(resp.totalCount);
                    this.dataSubject.next(resp.payload);
                  })
        }

    protected _loadDataImplementation(specificParameters: any,
        filterKey: string[],
        filterValue: string,
        sortParam: string,
        sortDirection:  'ASC' | 'DESC', 
        pageIndex: number, 
        pageSize: number): Observable<any> {
            throw new Error("Method not implemented.");
        }
}
