import { Injectable } from '@angular/core';
import { ApiService } from 'app/main/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private api: ApiService) { }

  getAppConfig() {
    return this.api.getRequest(`bo/configuration/appConfig`);
  }

  saveAppConfig(appConf) {
    return this.api.putRequest(`bo/configuration/appConfig`, appConf);
  }

  getAppPlatformConfig() {
    return this.api.getRequest(`bo/configuration/appPlatformConfig`);
  }

  saveAppPlafformConfig(appConf) {
    return this.api.putRequest(`bo/configuration/appPlatformConfig`, appConf);
  }
}


