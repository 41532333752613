import { Window } from "ng-chat";

export class Emergency {
    public videocallId: any;
    public chat: Window;
    public processed: boolean = false;
    public status: string = 'CREATING';
    public videoRequested: boolean = false;
    public chatRequested: boolean = false;
    public videoEnabled: boolean = false;
    public chatEnabled: boolean = false;

    constructor(
        public emergencyId: string,
        public userId: string
    ) {

    }
}
