import { Injectable } from '@angular/core';
import { Router, CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate{

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.loggedIn()) {
      console.log(state);
      this.router.navigate(['/login'], { 
        queryParams: { 
          returnUrl: state.url,
          timestamp: new Date().getTime()
         }, })
      .then((v)=>{
        console.log('navigation end...', v);
      }).catch((e)=>{
        console.log("navigation error", e);
      })
      return false;    
    }
    return this.authService.loggedIn();
  }
  
}
