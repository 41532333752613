import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        translate: '',
        type: 'group',
        children: [
            // {
            //     id: '16',
            //     title: 'Dashboard',
            //     type: 'item',
            //     icon: 'insert_chart ',
            //     url: '/admin/dashboard',
            //     bemethod: 'BOGETPOSIZIONI'
            //     //checkOperator: true,
            // },
            // {
            //     id: '30',
            //     title: 'Questionari',
            //     type: 'collapsable',
            //     icon: 'dvr',
            //     checkAdmin: true,
            //     children: [
            //         {
            //             id: '31',
            //             title: 'Modifica questionari',
            //             type: 'item',
            //             icon: 'chrome_reader_mode',
            //             url: '/admin/editor-questionari',
            //             checkAdmin: true,
            //             bemethod: 'BOEDITQUESTIONNAIREPROTOTYPE'
            //         },
            //         {
            //             id: '32',
            //             title: 'Nuovo Questionario',
            //             type: 'item',
            //             icon: 'add_to_photos',
            //             url: '/admin/editor-questionario',
            //             checkAdmin: true,
            //             bemethod: 'BOEDITQUESTIONNAIREPROTOTYPE'
            //         },
            //         {
            //             id: '33',
            //             title: 'Questionari compilati',
            //             type: 'item',
            //             icon: 'chrome_reader_mode',
            //             url: '/admin/questionari',
            //             //checkOperator: true,
            //             bemethod: 'BOGETQUESTIONNAIRECOMPILATEDDYNAMICDATATABLE'
            //         },
            //     ]
            // },
            //
            {
                id: '11',
                title: 'Aziende',
                type: 'item',
                icon: 'how_to_reg',
                url: '/admin/aziende',
                /*canViewFunction: (account: any) => {
                    return true
                },*/
                bemethod: 'TenantService_findAllTenants',
            },
            {
                id: 'devices',
                title: 'Postazioni macchina',
                type: 'item',
                icon: 'nfc',
                url: '/moviemasher/devices',
                checkAdmin: true,
                bemethod: 'MachineService_getMachineList',
            },
            // {
            //     id: '40',
            //     title: 'Camere IP',
            //     type: 'item',
            //     icon: 'videocam',
            //     // checkOperator: true,
            //     url: '/admin/camere',
            //     bemethod: 'FINDALLCAMERASBYTENANT',
            //     // checkOperator: true,
            // },
            // {
            //     id: '13',
            //     title: 'Help Desk',
            //     type: 'collapsable',
            //     icon: 'notifications',
            //     children: [
            //         {
            //             id: '14',
            //             title: 'Utenti in coda',
            //             type: 'item',
            //             icon: 'warning',
            //             url: '/admin/emergencyNotifications',
            //             bemethod: 'BOGETEMERGENCY'
            //         },
            //         {
            //             id: '15',
            //             title: 'Storico interventi',
            //             type: 'item',
            //             icon: 'featured_play_list',
            //             url: '/admin/historyEmergencyNotifications',
            //             bemethod: 'BOGETEMERGENCYHISTORY'
            //         }
            //     ]
            // },
            {
                id: 'file-manager',
                title: 'File Server',
                // translate: 'NAV.FILE_MANAGER',
                type: 'item',
                icon: 'folder',
                url: '/admin/file-manager',
                // bemethod: 'GETREPOSITORY',
                canViewFunction: (account: any) => {
                    return true
                },
            },
            // {
            //     id: '2',
            //     title: 'Mappa Posizioni',
            //     type: 'item',
            //     icon: 'location_on',
            //     url: '/admin/map',
            //     checkOperator: true,
            //     bemethod: 'BOGETPOSIZIONI'
            // },
            // {
            //     id: '3',
            //     title: 'HeatMap',
            //     type: 'item',
            //     icon: 'gradient',
            //     url: '/admin/heatmap',
            //     //checkOperator: true,
            //     bemethod: 'BOGETPOSIZIONI'
            // },
            // {
            //     id: 'qrcode',
            //     title: 'QRCode App',
            //     type: 'item',
            //     icon: 'dashboard',
            //     bemethod: 'BOGETQRCODE',
            // },
            // {
            //     id: '50',
            //     title: 'Licenze',
            //     type: 'item',
            //     icon: 'credit_card',
            //     url: '/admin/netlicence/home/UUID-TNNT-1',
            //     // checkAdmin: true,
            //     bemethod: 'BOMANAGELICENSE'
            // },
            // {
            //     id: '57',
            //     title: 'Acquisto Licenze',
            //     type: 'item',
            //     icon: 'credit_card',
            //     url: '/admin/buy-licenses',
            //     bemethod: 'BOBUYLICENSE'
            // },
            // {
            //     id: '56',
            //     title: 'Lista Licenze',
            //     type: 'item',
            //     icon: 'featured_play_list',
            //     url: '/admin/history-licenses',
            //     bemethod: 'BOBUYLICENSE'
            // },
            // {
            //     id: '51',
            //     title: 'WebRtcStream Admin',
            //     type: 'item',
            //     icon: 'videocam',
            //     url: '/admin/netlicence/webrtc/UUID-TNNT-1',
            //     checkAdmin: true,
            // },

            // {
            //     id       : 'ardatabase',
            //     title    : 'AR Database (Beta)',
            //     // translate: 'NAV.FILE_MANAGER',
            //     type     : 'item',
            //     icon     : 'developer_board',
            //     url      : '/admin/ardatabase-list',
            //     bemethod: 'CREATENEWDATABASE'

            // },
            // {
            //     id: '19',
            //     title: 'Configurazioni',
            //     type: 'item',
            //     icon: 'settings_applications',
            //     // checkAdmin: true,
            //     url: '/admin/appConfig',
            //     bemethod: 'CREATEORUPDATEAPPCONFIGURATIONENTITY',
            //     // children: [
            //     //     {
            //     //         id: '20',
            //     //         title: 'App',
            //     //         type: 'item',
            //     //         icon: 'settings_cell',
            //     //         url: '/admin/appConfig',
            //     //         bemethod: 'CREATEORUPDATEAPPCONFIGURATIONENTITY',
            //     //     }
            //     // ]
            // },
            // {
            //     id: 'help',
            //     title: 'Help',
            //     type: 'collapsable',
            //     icon: 'help_outline',
            //     children: [
            //         {
            //             id: 'firstAccess',
            //             title: 'Setup',
            //             type: 'item',
            //             icon: 'help_outline',
            //             url: '/admin/firstAccess',
            //             canViewFunction: (account: any) => {
            //                 // if(account)
            //                 //     return true;
            //                 // return false;
            //                 return true
            //             },
            //         }
            //     ]
            // },
            // {
            //     id: 'moviemasherliste',
            //     title: 'VideoSop',
            //     type: 'item',
            //     icon: 'video_library',
            //     url: '/moviemasher/videosop',
            //     canViewFunction: (account: any) => {
            //         return true
            //     },
            // },
            /*{
                id: 'moviemasherclient',
                title: 'Videosop client',
                type: 'item',
                icon: 'ondemand_video',
                // url: 'https://videosopclient.eclettica.net',
                canViewFunction: (account: any) => {
                    return true
                },
            },*/
            // {
            //     id: 'moviemasher',
            //     title: 'Progetti Videosop',
            //     type: 'collapsable',
            //     icon: 'videocam',
            //     children: [
            //         // {
            //         //     id: 'moviemashereditor',
            //         //     title: 'Videosop Editor',
            //         //     type: 'item',
            //         //     icon: 'featured_video',
            //         //     url: '/moviemasher/editor',
            //         //     canViewFunction: (account: any) => {
            //         //         return true
            //         //     },
            //         // },
            //         // {
            //         //     id: 'moviemasherliste',
            //         //     title: 'VideoSop',
            //         //     type: 'item',
            //         //     icon: 'video_library',
            //         //     url: '/moviemasher/videosop',
            //         //     canViewFunction: (account: any) => {
            //         //         return true
            //         //     },
            //         // },
            //         // {
            //         //     id: 'moviemashervideosop',
            //         //     title: 'Video Library',
            //         //     type: 'item',
            //         //     icon: 'featured_video',
            //         //     url: '/moviemasher/videosop-list',
            //         //     canViewFunction: (account: any) => {
            //         //         return true
            //         //     },
            //         // },
            //         // {
            //         //     id: 'moviemashercreate',
            //         //     title: 'Crea videosop',
            //         //     type: 'item',
            //         //     icon: 'help_outline',
            //         //     url: '/moviemasher/create',
            //         //     canViewFunction: (account: any) => {
            //         //         return true
            //         //     },
            //         // },
            //         {
            //             id: 'moviemasherclient',
            //             title: 'Videosop client',
            //             type: 'item',
            //             icon: 'ondemand_video',
            //             // url: 'https://videosopclient.eclettica.net',
            //             canViewFunction: (account: any) => {
            //                 return true
            //             },
            //         }
            //     ]
            // },

            {
                id: 'security',
                title: 'Security',
                type: 'collapsable',
                icon: 'lock',
                children: [
                    {
                        id: 'auths',
                        title: 'Autorizzazioni',
                        type: 'item',
                        icon: 'verified_user',
                        url: '/admin/security/auths',
                        bemethod: 'MethodAuthService_createOrUpdate',
                        // canViewFunction: (account: any) => {
                        //     return true
                        // },
                    },
                    {
                        id: 'roles',
                        title: 'Ruoli',
                        type: 'item',
                        icon: 'security',
                        url: '/admin/security/roles',
                        bemethod: 'UserRoleService_createOrUpdate',
                        // canViewFunction: (account: any) => {
                        //     return true
                        // },
                    },
                    {
                        id: 'sessions',
                        title: 'Sessioni',
                        type: 'item',
                        icon: 'access_time',
                        url: '/admin/security/sessions',
                        bemethod: 'SessionTypeService_createOrUpdate',
                        // canViewFunction: (account: any) => {
                        //     return true
                        // },
                    },
                    //{
                    // id: 'chatconfig',
                    //title: 'Configurazione Chat',
                    //type: 'item',
                    //icon: 'help_outline',
                    //url: '/admin/security/chat-config',
                    //bemethod: 'ChatConfigService_updateChatConfig',
                    //}, 
                    {
                        id: 'allusers',
                        title: 'Utenti',
                        type: 'item',
                        icon: 'person_outline',
                        url: '/admin/security/users',
                        //bemethod: 'UserService_getUsersList',
                        canViewFunction: (account: any, auths, variables) => {
                            if (account && account.ruolo == 'AMMINISTRATORE')
                                return true;
                            return false;
                        },
                    }, {
                        id: 'export-config',
                        title: 'Esporta configurazione',
                        type: 'item',
                        icon: 'person_outline',
                        // url: '/admin/security/users',
                        //bemethod: 'UserService_getUsersList',
                        canViewFunction: (account: any, auths, variables) => {
                            if (account && account.ruolo == 'AMMINISTRATORE')
                                return true;
                            return false;
                        }
                    }, {
                        id: 'import-config',
                        title: 'Importa configurazione',
                        type: 'item',
                        icon: 'person_outline',
                        // url: '/admin/security/users',
                        //bemethod: 'UserService_getUsersList',
                        canViewFunction: (account: any, auths, variables) => {
                            if (true)
                                return false;
                            if (account && account.ruolo == 'AMMINISTRATORE')
                                return true;
                            return false;
                        }
                    }
                ]
            },
            {
                id: 'gestutenti',
                title: 'Gestione Utenti',
                type: 'collapsable',
                icon: 'group',
                children: [
                ]
            },


            /*{
                id: 'chat',
                title: 'Chat',
                type: 'item',
                icon: 'chat',
                url: '/apps/chat',
                checkOperator: true,
            },
            {
                id: '5',
                title: 'Video Consulto',
                type: 'item',
                icon: 'voice_chat',
                url: '/apps/videocall',
                checkOperator: true,
            }*/
        ]
    }
];
