import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { delay } from "rxjs/operators";
import { ChatService } from './services/chat.service';
import { UserService } from '../admin/services/user.service';

export class ChatAdapterOperatorImplementation extends ChatAdapter implements IChatGroupAdapter {
  
  public downloadFile(repositoryId: string, fileName: string): void {
    throw new Error('Method not implemented.');
  }
  public goToRepo(repositoryId: string, isGroup: boolean): void {
    throw new Error('Method not implemented.');
  }

  operators: any;
  myUserUuid: any;


  constructor(
    private chatService: ChatService,
    private userService: UserService
  ) {
    super();
  }

  public static mockedParticipants: IChatParticipant[] = [];

  // public static mockedParticipantsOrig: IChatParticipant[] = [
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 1,
  //     displayName: "Arya Stark",
  //     avatar: "https://66.media.tumblr.com/avatar_9dd9bb497b75_128.pnj",
  //     status: ChatParticipantStatus.Online
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 2,
  //     displayName: "Cersei Lannister",
  //     avatar: null,
  //     status: ChatParticipantStatus.Online
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 3,
  //     displayName: "Daenerys Targaryen",
  //     avatar: "https://68.media.tumblr.com/avatar_d28d7149f567_128.png",
  //     status: ChatParticipantStatus.Busy
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 4,
  //     displayName: "Eddard Stark",
  //     avatar: "https://pbs.twimg.com/profile_images/600707945911844864/MNogF757_400x400.jpg",
  //     status: ChatParticipantStatus.Offline
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 5,
  //     displayName: "Hodor",
  //     avatar: "https://pbs.twimg.com/profile_images/378800000449071678/27f2e27edd119a7133110f8635f2c130.jpeg",
  //     status: ChatParticipantStatus.Offline
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 6,
  //     displayName: "Jaime Lannister",
  //     avatar: "https://pbs.twimg.com/profile_images/378800000243930208/4fa8efadb63777ead29046d822606a57.jpeg",
  //     status: ChatParticipantStatus.Busy
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 7,
  //     displayName: "John Snow",
  //     avatar: "https://pbs.twimg.com/profile_images/3456602315/aad436e6fab77ef4098c7a5b86cac8e3.jpeg",
  //     status: ChatParticipantStatus.Busy
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 8,
  //     displayName: "Lorde Petyr 'Littlefinger' Baelish",
  //     avatar: "http://68.media.tumblr.com/avatar_ba75cbb26da7_128.png",
  //     status: ChatParticipantStatus.Offline
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 9,
  //     displayName: "Sansa Stark",
  //     avatar: "http://pm1.narvii.com/6201/dfe7ad75cd32130a5c844d58315cbca02fe5b804_128.jpg",
  //     status: ChatParticipantStatus.Online
  //   },
  //   {
  //     participantType: ChatParticipantType.User,
  //     id: 10,
  //     displayName: "Theon Greyjoy",
  //     avatar: "https://thumbnail.myheritageimages.com/502/323/78502323/000/000114_884889c3n33qfe004v5024_C_64x64C.jpg",
  //     status: ChatParticipantStatus.Away
  //   }];

  getUser(userId): ParticipantResponse {
    const partecipants = ChatAdapterOperatorImplementation.mockedParticipants.filter((p) => {
      return p.id == userId;
    })
    if (partecipants != undefined && partecipants != null && partecipants.length > 0) {
      let participantResponse = new ParticipantResponse();

      participantResponse.participant = partecipants[0];
      participantResponse.metadata = {
        totalUnreadMessages: Math.floor(Math.random() * 10)
      }
      return participantResponse;
    }
    return null;
  }

  listFriends(): Observable<ParticipantResponse[]> {
    console.log('listFriends!!!');
    return of(ChatAdapterOperatorImplementation.mockedParticipants.map(user => {
      let participantResponse = new ParticipantResponse();

      participantResponse.participant = user;
      participantResponse.metadata = {
        totalUnreadMessages: Math.floor(Math.random() * 10)
      }

      return participantResponse;
    }));
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    let mockedHistory: Array<Message>;

    mockedHistory = [
      {
        fromId: 1,
        toId: 999,
        message: "Hi there, just type any message bellow to test this Angular module.",
        dateSent: new Date()
      }
    ];

    return of(mockedHistory).pipe(delay(2000));
  }

  sendMessage(message: Message): void {
    let msg = {
      message: message.message
    };
    this.chatService.send(
      message.toId,
      msg,
      new Date()
    );
    // setTimeout(() => {
    //   let replyMessage = new Message();

    //   replyMessage.message = "You have typed '" + message.message + "'";
    //   replyMessage.dateSent = new Date();

    //   if (isNaN(message.toId)) {
    //     let group = ChatAdapterImplementation.mockedParticipants.find(x => x.id == message.toId) as Group;

    //     // Message to a group. Pick up any participant for this
    //     let randomParticipantIndex = Math.floor(Math.random() * group.chattingTo.length);
    //     replyMessage.fromId = group.chattingTo[randomParticipantIndex].id;

    //     replyMessage.toId = message.toId;

    //     this.onMessageReceived(group, replyMessage);
    //   }
    //   else {
    //     replyMessage.fromId = message.toId;
    //     replyMessage.toId = message.fromId;

    //     let user = ChatAdapterImplementation.mockedParticipants.find(x => x.id == replyMessage.fromId);

    //     this.onMessageReceived(user, replyMessage);
    //   }
    // }, 1000);
  }

  groupCreated(group: Group): void {
    ChatAdapterOperatorImplementation.mockedParticipants.push(group);

    ChatAdapterOperatorImplementation.mockedParticipants = ChatAdapterOperatorImplementation.mockedParticipants.sort((first, second) =>
      second.displayName > first.displayName ? -1 : 1
    );

    // Trigger update of friends list
    this.listFriends().subscribe(response => {
      this.onFriendsListChanged(response);
    });
  }

  newMessage(message) {
    let user = ChatAdapterOperatorImplementation.mockedParticipants.find(x => x.id == message.fromUser.uuid);
    if(user == undefined || user == null)
      return;
      let replyMessage = new Message();

    replyMessage.message = message.textMessage;
    replyMessage.dateSent = message.clientDate;
    replyMessage.fromId = message.fromUser.uuid;
    replyMessage.toId = message.toUser.uuid;
    
    this.onMessageReceived(user, replyMessage);
  }

  addToList(user) {
    let u = ChatAdapterOperatorImplementation.mockedParticipants.find(x => x.id == user.id);
    if (u == undefined || u == null) {
      ChatAdapterOperatorImplementation.mockedParticipants.push(user);
    }
  }

  setMyUserId(myUserUuid: any) {
    this.myUserUuid = myUserUuid;
  }

  mergeOperators(payload: Array<any>) {
    return new Promise((resolve, reject) => {
      let toRemove = [];
      let promises = [];
      let participants: IChatParticipant[] = [];
      if (this.operators == undefined || this.operators == null)
        this.operators = new Array<any>();
      for (let index = 0; index < this.operators.length; index++) {
        const element = this.operators[index];
        const found: any = payload.find((v) => {
          return v['userId'] == element.userId;
        })
        if (found == undefined || found == null) {
          toRemove.push(index);
        } else {
          this.operators[index].status = found['status'];
          this.operators[index].sessionId = found['sessionId'];
          let usr = {
            participantType: ChatParticipantType.User,
            id: found.id,
            displayName: found.name + " " + found.surname,
            avatar: this.userService.retrieveProfileImageUidEndpoint(found.id, false),
            status: this.getUserStatus(found.status)
          }
          const p = this.loadUserAvatar(usr, (user) => {
            participants.push(user);
          })
          promises.push(p);
        }
      }
      toRemove = toRemove.sort((a, b) => {
        if (a == b)
          return 0;
        if (a < b)
          return -1;
        if (a > b)
          return 1;
      })

      for (let index = toRemove.length - 1; index >= 0; index--) {
        this.operators.splice(toRemove[index], 1);
      }

      for (let index = 0; index < payload.length; index++) {
        if (payload[index]['userId'] == this.myUserUuid)
          continue;
        const found = this.operators.find((v) => {
          return v['userId'] == payload[index]['userId'];
        })
        if (found == undefined || found == null) {
          this.operators.push(payload[index]);
          let usr = {
            participantType: ChatParticipantType.User,
            id: payload[index].id,
            displayName: payload[index].name + " " + payload[index].surname,
            avatar: this.userService.retrieveProfileImageUidEndpoint(payload[index].id, false),
            status: this.getUserStatus(payload[index].status)
          }
          let p = this.loadUserAvatar(usr, (user) => {
            participants.push(user);
          })
          promises.push(p);
        }
      }
      console.log('-----------------------------', this.operators);
      Promise.all(promises).finally(()=>{
        ChatAdapterOperatorImplementation.mockedParticipants = participants;
        resolve(true);
      })
    })

  }

  loadUserAvatar(usr: { participantType: ChatParticipantType; id: any; displayName: string; avatar: string; status: ChatParticipantStatus; }, callback: (user: any) => void) {
    return new Promise((resolve, reject)=>{
      this.userService.renderUnsecureProfileImageUid(usr.id, false)
      .subscribe((data) => {
        usr.avatar = data;
        callback(usr);
        resolve(usr);
      },
        (error) => {
          usr.avatar = '/assets/images/admin-image.jpg';
          callback(usr);
          resolve(usr);
        });
    })
    
  }

  getUserStatus(status) {
    if (status == undefined || status == null) {
      return ChatParticipantStatus.Offline;
    }
    switch (status) {
      case 'ONLINE':
        return ChatParticipantStatus.Online;
        break;
      case 'OFFLINE':
        return ChatParticipantStatus.Offline;
        break;
      case 'BUSY':
        return ChatParticipantStatus.Busy;
        break;
      default:
        return ChatParticipantStatus.Offline;
        break;
    }
  }

}
