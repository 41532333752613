import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/main/shared/services/api.service';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '../shared/services/account.service';
import { AuthService } from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  statusClickOnEmergencyNotification: boolean;
  
  setStatusClick(arg0: boolean) {
    this.statusClickOnEmergencyNotification = arg0;
  }

  getStatusClick(){
    return this.statusClickOnEmergencyNotification;
  }

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor(private api: ApiService,
    private accountService: AccountService,
    private auth: AuthService) {

  }

  private dataSource = new BehaviorSubject<any>({ emergencyNotifications: null });
  onEmergencyNotificationsValueChange = this.dataSource.asObservable();

  private dataSource2 = new BehaviorSubject<any>({ emergencyNotifications: null });
  onEmergencyNotificationsValueChange2 = this.dataSource2.asObservable();

  private dataSource3 = new BehaviorSubject<any>({ emergencyNotifications: null });
  onEmergencyNotificationsValueChange3 = this.dataSource3.asObservable();


  private dataSourceNotification = new BehaviorSubject<any>({ notifications: null });
  onNotificationsValueChange = this.dataSourceNotification.asObservable();

  setStatusCliccked(){
    this.clicked.emit(true);
  }
  
  updateEmergencyNotificationsValue(emergencyNotifications) {
    // if (emergencyNotifications === false) {
    //   this.dataSource.unsubscribe();
    // } else {
    //   this.dataSource.next(emergencyNotifications);
    // }
    this.dataSource.next(emergencyNotifications);
  }

  updateEmergencyNotificationsValue2(emergencyNotifications) {
    // if (emergencyNotifications === false) {
    //   this.dataSource2.unsubscribe();
    // } else {
    //   this.dataSource2.next(emergencyNotifications);
    // }
    this.dataSource2.next(emergencyNotifications);
  }

  updateEmergencyNotificationsValue3(emergencyNotifications) {
    // if (emergencyNotifications === false) {
    //   this.dataSource3.unsubscribe();
    // } else {
    //   this.dataSource3.next(emergencyNotifications);
    // }
    this.dataSource3.next(emergencyNotifications);
  }

  // getEmergencyNotfications(page: any, num_elements: any) {
  //   return this.api.getRequest(`segnalazioni/getNotifiche?page=${page}&num_elements=${num_elements}`);
  // }
  countEmergencyNotfications(statuses?) {
    if (this.auth.loggedIn()) {
      // if (this.accountService.isOperator()) {
      let internalStatuses = '';
      if (statuses) {
        internalStatuses = statuses;
      }
      return this.api.getRequest(`bo/emergency/count?statuses=${internalStatuses}`);
      // }
    }
  }

  getNotfications() {
    if (this.auth.loggedIn()) {
      // if (this.accountService.isOperator()) {
      return this.api.getRequest(`bo/notifications/all`);
      // }
    }
  }

  getNotfication(notificationId) {
    if (this.auth.loggedIn()) {
      // if (this.accountService.isOperator()) {
      return this.api.getRequest(`bo/notifications/${notificationId}/getNotification`);
      // }
    }
  }

  getEmergencyNotfications(statuses?) {
    let internalStatuses = '';
    if (statuses) {
      internalStatuses = statuses;
    }
    return this.api.getRequest(`bo/emergency?statuses=${internalStatuses}`);
  }

  getHistoryEmergencyNotfications(statuses?) {
    let internalStatuses = '';
    if (statuses) {
      internalStatuses = statuses;
    }
    return this.api.getRequest(`bo/emergency/history?statuses=${internalStatuses}`);
  }

  getHistoryTaskNotfications(specificParameters, searchKeys, searchValue, sortParam, sortDirection, pageIndex, pageSize) {

    console.log('getPagedPatients', sortParam, sortDirection, pageIndex, pageSize)

    let p = { params: {
      'pageNumber': pageIndex.toString(),
      'pageSize': pageSize.toString()
    }}
     if(sortParam) {
      p.params['sortParam'] =  sortParam;
      p.params['sortDirection'] = sortDirection;
    }

    if(searchValue && searchKeys) {
      p.params['searchValue'] =  searchValue;
      p.params['searchKeys'] = searchKeys;
    }

    return this.api.getTypedRequest<any>(`bo/emergency/tasks/history`, p);
  }

  closeEmergency(emergencyId) {
    return this.api.putRequest(`bo/emergency/${emergencyId}/close`, null);
  }

  openEmergency(emergencyId) {
    return this.api.putRequest(`bo/emergency/${emergencyId}/open`, null);
  }

  processingEmergency(emergencyId, body) {
    return this.api.putRequest(`bo/emergency/${emergencyId}/processing`, body);
  }


  closeEmergencyByPatientId(patientId) {
    return this.api.putRequest(`bo/emergency/${patientId}/closebypatientid`, null);
  }


  // ////////////////////
  // Emergency Notes API
  // ////////////////////

  addNoteEmergency(emergencyId, note) {
    return this.api.putRequest(`bo/emergency/updateNotes`, {
      "userEmergencyId": emergencyId,
      "notes": note
    });
  }

  getNotesByEmergencyId(emergencyId) {
    return this.api.getRequest(`bo/emergency/${emergencyId}/notes/all`);
  }

  getNoteByEmergencyId(emergencyId, noteId) {
    // return this.api.getRequest(`bo/emergency/${emergencyId}/notes`);
    return this.api.getRequest(`bo/emergency/${emergencyId}/notes/${noteId}/getNote`);
  }

  deleteNoteByEmergencyId(emergencyId, noteId) {
    return this.api.deleteRequest(`bo/emergency/${emergencyId}/notes/${noteId}/getNote`);
  }

  createUserEmergencyNotes(emergency: any) {
    return this.api.postRequest(`bo/emergency/updateUserEmergencyNotes`, {
      "userEmergencyId": emergency.userEmergencyId,
      "notes": emergency.notes
    });
  }

  updateUserEmergencyNotes(emergency: any) {
    return this.api.putRequest(`bo/emergency/updateUserEmergencyNotes`, {
      "userEmergencyId": emergency.userEmergencyId,
      "notes": emergency.notes,
      "id": emergency.id
    });
  }

  getOperators() {
      return this.api.getRequest(`bo/getOperators`);
  }
}
