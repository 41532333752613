import { Injectable } from '@angular/core';
import { ApiService } from 'app/main/shared/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  [x: string]: any;



  constructor(private api: ApiService) { }

  login(data) {
    return this.api.postRequest('bo/login', data);
  }

  getUserRoleList(): Observable<Object> {

    return this.api.getRequest(`security/role`);
  }

  /**
   * 
   * @param specificParameters 
   * @param searchKeys 
   * @param searchValue 
   * @param sortParam 
   * @param sortDirection 
   * @param pageIndex 
   * @param pageSize 
   * @returns 
   */
  getPagedCustomUsers(specificParameters, searchKeys, searchValue, sortParam, sortDirection, pageIndex, pageSize) {
    let tenantId = null;
    let roleId = null;
    let roles = null;
    if (specificParameters) {
      tenantId = specificParameters['tenantId']
      if (specificParameters['roleId'])
        roleId = specificParameters['roleId']
      if (specificParameters['roles'])
        roles = specificParameters['roles']
    }
    console.log('getPagedCustomUsers', tenantId, sortParam, sortDirection, pageIndex, pageSize, roleId)

    let p = {
      params: {
        'pageNumber': pageIndex.toString(),
        'pageSize': pageSize.toString()
      }
    }
    if (sortParam) {
      p.params['sortParam'] = sortParam;
      p.params['sortDirection'] = sortDirection;
    }

    if (searchValue && searchKeys) {
      p.params['searchValue'] = searchValue;
      p.params['searchKeys'] = searchKeys;
    }

    let url = `bo/getUsersList`;

    if (roleId) {
      p.params['roles'] = [roleId];
    }
    if (roles) {
      p.params['roles'] = roles;
    }

    if (tenantId) {
      //url += '/'+tenantId;
      p.params['tenantId'] = tenantId;
    }
    return this.api.getRequest(url, p);
  }



  registerUserTenant(data: any) {
    return this.api.postRequest('bo/registerUserTenant', data);
  }

  getUsers(tenantId?) {
    return this.getPatients(tenantId);
  }

  getPatients(tenantId?) {
    let url = `bo/getPatientsList`;
    if (tenantId)
      url += '/' + tenantId;
    return this.api.getRequest(url);
  }

  getPagedPatients(specificParameters, searchKeys, searchValue, sortParam, sortDirection, pageIndex, pageSize) {
    let tenantId = null;
    if (specificParameters)
      tenantId = specificParameters['tenantId']
    console.log('getPagedPatients', tenantId, sortParam, sortDirection, pageIndex, pageSize)

    let p = {
      params: {
        'pageNumber': pageIndex.toString(),
        'pageSize': pageSize.toString()
      }
    }
    if (sortParam) {
      p.params['sortParam'] = sortParam;
      p.params['sortDirection'] = sortDirection;
    }

    if (searchValue && searchKeys) {
      p.params['searchValue'] = searchValue;
      p.params['searchKeys'] = searchKeys;
    }

    let url = `bo/getPatientsList`;
    if (tenantId)
      url += '/' + tenantId;
    return this.api.getRequest(url, p);
  }

  getOperatorsList() {
    return this.api.getRequest(`bo/getOperatorsList`);
  }

  getOperators() {
    return this.api.getRequest(`bo/getOperators`);
  }


  getOperatorsByTenant(tenantId) {
    return this.api.getRequest(`bo/getOperatorsList/${tenantId}`);
  }

  createUser(body) {
    return this.api.postRequest(`bo/updateUserInfo`, body);
  }

  editPaziente(body: any) {
    return this.api.putRequest(`bo/updateUserInfo`, body);
  }

  deletePaziente(userInfoId: any) {
    return this.api.deleteRequest(`bo/users/${userInfoId}`);
  }

  deleteOperatore(userInfoId: any) {
    return this.api.deleteRequest(`bo/users/${userInfoId}`);
  }

  deleteUtente(userInfoId: any) {
    return this.api.deleteRequest(`bo/users/${userInfoId}`);
  }

  editOperatore(body: any) {
    return this.api.putRequest(`bo/updateUserInfo`, body);
  }

  createOperator(body) {
    return this.api.postRequest(`bo/updateUserInfo`, body);
  }

  changeMyPassword(body: any) {
    return this.api.putRequest(`bo/changePassword`, body);
  }

  changeUserPassword(id: any, body: any) {
    return this.api.putRequest(`bo/users/${id}/changePassword`, body);
  }

  changeUserUsername(id: any, body: any) {
    return this.api.putRequest(`bo/users/${id}/changeUsername`, body);
  }

  getUserInfo() {
    return this.api.getRequest('bo/getUserInfo');
  }

  getUserInfoByUserId(userId) {
    return this.api.getRequest(`bo/users/${userId}/getUserInfo`);
  }

  renderPatientProfileImage(userInfoId, profileImageId) {
    const noCache = new Date().getTime();
    const endpoint = `bo/files/user/renderProfileImage?profileImageId=${profileImageId}&userInfoId=${userInfoId}&noCache=` + noCache;
    const ret = this.api
      .getBlobRequest(endpoint);
    return ret;
  }

  retrieveProfileImageUidEndpoint(userId, isNoCache) {
    const noCache = new Date().getTime();
    let endpoint = `bo/files/user/renderProfileImageUID?userId=${userId}`;
    if (isNoCache == undefined || isNoCache == null || isNoCache == true)
      endpoint += `&noCache=` + noCache;
    return this.api.retrieveCompleteUrl(endpoint);
  }

  renderUnsecureProfileImageUid(userId, isNoCache) {
    const noCache = new Date().getTime();
    let endpoint = `bo/files/user/renderProfileImageUID?userId=${userId}`;
    if (isNoCache == undefined || isNoCache == null || isNoCache == true)
      endpoint += `&noCache=` + noCache;
    const ret = this.api
      .getUnsecureBlobRequest(endpoint, false);
    return ret;
  }

  renderProfileImageUid(userId) {
    const noCache = new Date().getTime();
    const endpoint = `bo/files/user/renderProfileImageUID?userId=${userId}&noCache=` + noCache;
    const ret = this.api
      .getBlobRequest(endpoint);
    return ret;
  }

  uploadImage(blob: Blob) {
    const formData = new FormData();
    formData.append('file', blob);
    return this.api.postFileRequest(`bo/files/single/temp/upload`, formData);
  }

  goToShop() {
    return this.api.getRequest(`bo/tenant/goToShop`);
  }

  checkLicense() {
    return this.api.getRequest(`bo/tenant/checkLicense`);
  }

  getPricingTables(id) {
    return this.api.getRequest(`bo/tenants/licenseTemplates/model/${id}`);
  }

  getPublicPricingTables() {
    return this.api.getRequest(`bo/pricingTable`);
  }

  getLicenseProductDefault() {
    return this.api.getRequest(`bo/licenses/product/default`);
  }

  buyLicense(body: any): Observable<Object> {
    return this.api.postRequest(`bo/tenant/buylicense`, body);
  }

  /*
  activateLicense(body: any): Observable<Object>
  {
    return this.api.postRequest(`bo/tenant/createLicense`, body);
  }
  */

  getTenantLicenses(tenantId?) {
    if (tenantId)
      return this.api.getRequest(`bo/tenant/licenses/${tenantId}/all`);
    return this.api.getRequest(`bo/tenant/licenses/all`);
  }

  getTenantLicenseInfoByLicensId(id: any) {
    return this.api.getRequest(`bo/tenants/tenantLicense/${id}`);
  }

  createCompany(body: any): Observable<Object> {
    return this.api.postRequest(`bo/tenant/updateTenant`, body);
  }
  editCompany(body: any): Observable<Object> {
    return this.api.putRequest(`bo/tenant/updateTenant`, body);
  }
  getCompanies(): Observable<Object> {

    return this.api.getRequest(`bo/tenants/all`);
  }

  deleteCompany(id: any): Observable<Object> {
    return this.api.deleteRequest(`bo/tenants/${id}/getTenant`);
  }

  getCompany(id: any): Observable<Object> {
    return this.api.getRequest(`bo/tenants/${id}/getTenant`);
  }

  regenerateLicense(id: any): Observable<Object> {
    return this.api.getRequest(`bo/tenants/${id}/recreateLicense`);
  }

  getTenantAdmins() {
    return this.api.getRequest(`bo/getTenantAdminList`);
  }

  getTenantAdminsByTenant(tenantId) {
    return this.api.getRequest(`bo/getTenantAdminList/${tenantId}`);
  }

  deleteTenantAdmin(userInfoId: any) {
    return this.api.deleteRequest(`bo/users/${userInfoId}`);
  }

  editTenantAdmin(body: any) {
    return this.api.putRequest(`bo/updateUserInfo`, body);
  }

  createTenantAdmin(body) {
    return this.api.postRequest(`bo/updateUserInfo`, body);
  }

  getRosCam(contactId) {
    return this.api.getRequest(`bo/getRosCam/${contactId}`);
  }

  getLastAcceptance() {
    return this.api.getRequest(`bo/lastAcceptance`);
  }


  _passwordPattern = /[a-zA-Z0-9_\-\+\.]/;
  passwordLength = 12;
  generateRandomPassword(): string {
    return Array.apply(null, { 'length': this.passwordLength })
      .map(() => {
        let result;
        while (true) {
          result = String.fromCharCode(this._getRandomByte());
          if (this._passwordPattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  }

  _getRandomByte() {
    // http://caniuse.com/#feat=getrandomvalues
    if (window.crypto && window.crypto.getRandomValues) {
      var result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    }
    else if (window['msCrypto'] && window['msCrypto'].getRandomValues) {
      var result = new Uint8Array(1);
      window['msCrypto'].getRandomValues(result);
      return result[0];
    }
    else {
      return Math.floor(Math.random() * 256);
    }
  }

  /**
   * 
   * @param roleId 
   * @returns 
   */
  countUsersByRole(roleId: any) {
    return this.api.getRequest(`bo/countUsersByRole/${roleId}`);
  }

}


