import { ChangeDetectorRef } from "@angular/core";
import { FormGroup } from "@angular/forms";

export class DynamicFormDataInput {
    formGroup: FormGroup;
    topHeader: string;
    header?: string;
    headerOptions?: any;
    elements: Array<DynamicFormDataInputElement>;
    button: string;
    buttons?: Array<DynamicFormDataButtonElement>;
    disableClose?: boolean;
}

export class DynamicFormDataInputElement {
    type: 'TEXT' | 'NUMBER' | 'CHECK' | 'SELECT' | 'TEXTAREA' | 'PASSWORD' | 'UPLOAD' | 'MODAL';
    formControlName: string;
    placeholder: string;
    multiple?: boolean;
    options?: Array<{
        value: string | number | boolean;
        name: string;
        setDisabled?: boolean | null
    }>;
    icon?: string;
    label?: string;
    classes?: string;
    visibleIf?: {
        formControlName: string;
        values: Array<string | number | boolean>;
    };
    isVisible?: boolean = true;
    required: boolean;
    accept?: string;
  uploadFunction?: (e: any, formGroup: FormGroup, formControlName: string, element: DynamicFormDataInputElement, ref: ChangeDetectorRef) => Promise<any>;
  urlToUpload?: string;
  uploadMessage?: string;
  modal?: DynamicFormDataModalElement;
  currentVal?: {
    currentName: string,
    currentFile?: File
  }
}

export class DynamicFormDataModalElement {
    controller: any;
    data?: any;
    afterClosedCallback: (datas: any, formGroup: FormGroup, modal: DynamicFormDataModalElement, ref: ChangeDetectorRef, formControlName: string) => void
    currentVal?: {
            currentId: string,
            currentName: string
        }
}

export class DynamicFormDataButtonElement {
    color?: string;
    hasIcon: boolean;
    iconName?: string;
    label: string;
    closeDialog?: boolean;
    action: (par?: any) => void;
}
