import { Component, Inject, OnInit, OnChanges, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { UserService } from 'app/main/admin/services/user.service';
import { AccountService } from '../services/account.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';



// To modify
@Component({
  selector: 'price-plan',
  templateUrl: './price-plan.component.html',
  styleUrls: ['./price-plan.component.scss']
})
export class PricePlanComponent implements OnInit, OnChanges {

  @Input() plan: any;
  // @Output revisedPlan:any;

  public realValue: number;

  public min: number = 0;
  public max: number = 2;
  public priceForSpecialist: number = null;
  public specialistsPrice: Number = 0;
  isPriceForSpecialistVisibile = false;
  constructor(
    private userService: UserService,
    private _account: AccountService,

    private dialog: MatDialog,
    private snackBarMat: MatSnackBar,
    private storageService: StorageService,
    private auth: AuthService,

    private router: Router) {


  }

  get value(): number {
    return this.realValue;
  }

  set value(newValue: number) {
    this.realValue = newValue;
    this.specialistsPrice = this.priceForSpecialist * this.realValue;

    if (this.realValue < this.min) {
      this.realValue = undefined;
      setTimeout(() => {
        this.realValue = this.min;
        this.specialistsPrice = this.priceForSpecialist * this.realValue;
      });
    }
    else if (this.realValue > this.max) {
      this.realValue = undefined;
      setTimeout(() => {
        this.realValue = this.max;
        this.specialistsPrice = this.priceForSpecialist * this.realValue;
      });
    }
  }



  validateWhite(event: number) {

    console.log('event: ', event);

  }

  async ngOnChanges(changes: any) {



  }


  async ngOnInit() {
    this.value = 0;

    // console.log("Questi sono i piani: ",this.plan);
    if (this.plan.customData) {
      let isFlexi = Object.keys(this.plan.customData).find(x => x == this.plan.name);
      if (isFlexi && this.plan.customData.Flexi && this.plan.customData.Flexi.PriceForSpecialist) {

        if (this.plan.customData.Flexi.MaxSpecialists)
          this.max = Number(this.plan.customData.Flexi.MaxSpecialists);

        if (this.plan.customData.Flexi.PriceForSpecialist)
          this.priceForSpecialist = Number(this.plan.customData.Flexi.PriceForSpecialist);
      }


    }
    // console.log('this.priceForSpecialist: ', this.priceForSpecialist);
  }


  onPaypalPurchase(plan: any) {


    if (plan.price == 0)
      this.router.navigate(['/register']);
    else {
      plan.specialists_number = this.realValue;
      plan.specialists_price = this.specialistsPrice;

      // console.log('item: ', plan);

      // let body = null;
      const jItem = JSON.stringify(plan);
      // console.log('jItem: ', jItem);
      this.storageService.remove('LICENSE_KEY');
      this.storageService.set('LICENSE_KEY', jItem);

      if (this.auth.loggedIn())
        // this.router.navigate(['/admin/paypal-purchase'], { queryParams: { item: 'LICENSE_KEY' } });
        this.router.navigate(['/admin/paypal-purchase'], { queryParams: { item: jItem } });
      else
        // this.router.navigate(['/register'], { queryParams: { returnUrl: '/admin/paypal-purchase?item=LICENSE_KEY' } })
        this.router.navigate(['/register'], { queryParams: { returnUrl: `/admin/paypal-purchase?item=${jItem}` } });
      
    }
  }

  togglePriceForSpecialist() {
    if (this.isPriceForSpecialistVisibile)
      this.isPriceForSpecialistVisibile = false;
    else
      this.isPriceForSpecialistVisibile = true;

  }

} 
