import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateToString'
})
export class DateToStringPipe implements PipeTransform {

  transform(value: string, args?: string, index?: number): any {
    const format = 'd/MM/yyyy hh:mm';
    let date;
    if (value.slice(0, 1) === '2') {
      const inputDate = this.getDate(value);
      const inputHour = this.getHour(value);
      const inputMin = this.getMIns(value);
      date = new Date(inputDate);
      date.setHours(inputHour, inputMin);
    } else {
      date = new Date(+value);
      return new DatePipe('en-US').transform(date, format);
    }
    if (args) {
      const options = { 'year': 'numeric', 'month': 'long', 'day': '2-digit', 'hour': '2-digit', 'minute': '2-digit' };
      return date.toLocaleString('it-IT', options).toLocaleUpperCase().split(' ')[index];
    }
    return new DatePipe('en-US').transform(date, format);
  }

  getDate(value): string {
    const date = value.substring(0, 8);
    return [date.slice(0, 4), '-', date.slice(4, 6), '-', date.slice(6)].join('');
  }

  getHour(value): number {
    return parseInt(value.substring(8, 10), 10);
  }
  getMIns(value): number {
    return parseInt(value.substring(10, 12), 10);
  }

}
