import { DatePipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AccountService } from 'app/main/shared/services/account.service';
import { ApiService } from 'app/main/shared/services/api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WebsocketService } from 'app/main/shared/services/websocket.service';
import { EventWebSocketService } from 'app/main/shared/services/event-web-socket.service';
import { EventInteractionService } from './event-interaction.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService implements Resolve<any>
{

    contacts: any[];
    chats: any[];
    user: any;
    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;
    private myAccount: any;
    private datePipe: DatePipe;
    socketInterval: NodeJS.Timeout;
    isActive: boolean;
    messagesQueue: Array<string> = [];
    emergencyId: any;
    ewss: any;
    thereIsChatAdapter: any = false;
    isTryToContact: boolean;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private api: ApiService,
        private account: AccountService,
        private eventInteractionService: EventInteractionService,
        private eventWebSocketService: EventWebSocketService,
        private websocketService: WebsocketService
    ) {
        registerLocaleData(localeIt, "it-IT");

        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        this.datePipe = new DatePipe("it-IT");
        this.initService();
    }

    async initService() {
        this.onChatSelected = new BehaviorSubject(null);
        this.emergencyId = null;
        this.thereIsChatAdapter = false;
        // Gets account 
        await this.account.getAccount().then((res: any) => {

            this.myAccount = res;
            this.createSocket();
            //

            //
        });
    }

    setThereIsAdapter(val: boolean) {
        this.thereIsChatAdapter = val;
    }

    private createSocket() {
        if (this.ewss == undefined || this.ewss == null) {
            this.ewss = this.eventWebSocketService.getObservable().subscribe((data) => {
                console.log('eventWebSocketService ', data);
                this.onSocketMessage(data);
            });
        }

    }

    public closeSocket() {


        if (this.ewss != undefined && this.ewss != null) {
            this.ewss.unsubscribe();
            this.ewss = null;
        }

    }


    setEmergencyId(emergencyId) {
        this.emergencyId = emergencyId;
    }

    getEmergencyId() {
        return this.emergencyId;
    }

    tryToContact(toUserId: any, emergencyId) {
        this.emergencyId = emergencyId;
        try {
            this.emit('tryToContact', {
                'toUserId': toUserId,
                'userUuid': toUserId,
                randomId: Math.floor(Math.random() * 10000000000000),
                timestamp: new Date().getTime()
            }, 'tryToContact');
            this.isTryToContact = true;
        } catch (ex) {
            console.log("errore trytoconnect", ex);
        }
    }

    private onSocketMessage(event) {
        try {
            // console.log(event);
            const msg = event;
            switch (msg.event) {
                case "newmessage":
                    this.newMessage(msg.data);
                    break;
                case "tryToContact":
                    if (!this.isTryToContact)
                        return;
                    this.isTryToContact = false;
                    if (msg.data.result != undefined && msg.data.result != null && msg.data.result.textMessage != undefined && msg.data.result.textMessage != null && msg.data.result.textMessage == "true") {
                        this.getChat(msg.data.result.fromUser.uuid);
                    } else {
                        this.eventInteractionService.publishData({ event: 'redirectToEmergency' });
                    }
                    break;
                case "closeemergency":
                    console.log('closeemergency');
                    this.eventInteractionService.publishData({
                        event: 'closeemergency',
                        inChat: false,
                        data: msg.data
                    });
                    //this.forceCloseEmergency();
                    break;
                case 'updateusers':
                    this.eventInteractionService.publishData({
                        event: 'updateusers',
                        data: msg.data
                    });
                    break;
            }
        } catch (e) {
            console.log("Errore getSignalMessageCallback - ", e);
        }
    }


    private newMessage(data) {
        if (data == undefined || data == null)
            return;
        //TODO:constatare se questo passaggio e' necessario anche con l'arrivo messaggio da app
        if (data.result == undefined || data.result == null) {
            return;
        } else {
            data = data.result;
        }

        if (this.thereIsChatAdapter) {
            this.eventInteractionService.publishData({ event: 'NEWCHATMESSAGE', message: data });
            return;
        }

        //
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === data.fromUser.uuid;
        });

        // Create new chat, if it's not created yet.
        if (!chatItem) {
            this.createNewChat(data.fromUser.uuid, data);
            this.getChat(data.fromUser.uuid);
        } else {
            //const chatListItem = this.createNewChatItem(data.fromUser.uuid, data.fromUser, data);            
            const newMessage =
            {
                who: data.fromUser.uuid,
                message: data.textMessage,
                time: this.datePipe.transform(data.clientDate, 'yyyy-MM-dd HH:mm:ss')
            };
            this.eventInteractionService.publishData({ event: 'newmessage', message: newMessage });
        }

        //TODO:al momento non viene restituito il messaggio di lettura messaggio
        /*this.emit('', {
            from: this.userService.currentId,
            userUuid: data.result.fromUser.uuid,
            uuid: data.result.fromUser.uuid,
            to: data.result.fromUser.uuid,
            toUserId: data.result.fromUser.uuid,
            lastRandom: data.result.randomId
        }, 'readmessage');*/
    }

    send(toUserId, newMessage: {who?: string|null, message: string}, newMessageDate) {
        if (newMessage) {
            this.emit('', {
                from: newMessage.who,
                userUuid: toUserId,
                uuid: toUserId,
                to: toUserId,
                toUserId: toUserId,
                message: newMessage.message,
                randomId: Math.floor(Math.random() * 10000000000000),
                timestamp: newMessageDate.getTime()
            }
                , 'sendmessage');
        }
    }

    forceCloseEmergency(toUserId) {
        this.emit('', {
            from: this.myAccount.userId,
            userUuid: toUserId,
            uuid: toUserId,
            to: toUserId,
            toUserId: toUserId,
            message: 'closeemergency',
            randomId: Math.floor(Math.random() * 10000000000000),
            timestamp: new Date().getTime()
        }
            , 'closeemergency');
    }

    sendCommand(command, data) {
        this.emit('', data, command);
    }

    emit(status, data, event?, callback?) {
        this.websocketService.emit(status, data, event, callback);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContacts(),
                this.getChats(),
                this.getUser()
            ]).then(
                ([contacts, chats, user]) => {
                    this.contacts = contacts;
                    this.chats = chats;
                    this.user = user;
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any> {
        if (this.user == undefined || this.user == null)
            return;
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId;
        });

        // Create new chat, if it's not created yet.
        if (!chatItem) {
            this.createNewChat(contactId, null).then(() => {
                this.getChat(contactId)
            });
            return;
        }

        return new Promise((resolve, reject) => {
            this.api.getRequest('bo/chat/chat-chats/' + chatItem.id)
                .subscribe((response: any) => {
                    const chat = response.payload;

                    const chatContact = this.contacts.find((contact) => {
                        return contact.id === contactId;
                    });

                    const chatData = {
                        chatId: chat.id,
                        dialog: chat.dialog,
                        contact: chatContact
                    };

                    this.onChatSelected.next({ ...chatData });
                    setTimeout(() => {
                        this.send(chatContact.id, { message: 'OPENCHAT' }, new Date());
                    }, 200);
                    resolve(true);
                }, reject);

        });

    }

    private createNewChatItem(contactId, contact, chatData) {
        let chatListItem = null;
        if (!chatData) {
            chatListItem = {
                contactId: contactId,
                id: contactId,
                lastMessageTime: '2020-03-29T10:30:18.931Z',
                name: contact.name,
                unread: null
            };
        } else {
            chatListItem = {
                contactId: chatData.fromUser.uuid,
                id: chatData.fromUser.uuid,
                lastMessageTime: this.datePipe.transform(chatData.clientDate, 'yyyy-MM-dd HH:mm:ss'),
                name: chatData.fromUser.surname + " " + chatData.fromUser.name,
                unread: 1
            };
        }
        return chatListItem;
    }


    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId, chatData): Promise<any> {
        return new Promise(() => {

            const contact = this.contacts.find((item) => {
                return item.id === contactId;
            });


            const chatListItem = this.createNewChatItem(contactId, contact, chatData);

            // Add new chat list item to the user's chat list
            this.user.chatList.push(chatListItem);

            const chatContact = this.contacts.find((contact) => {
                return contact.id === contactId;
            });

            const emptyChatData = {
                chatId: contactId,
                dialog: [],
                contact: chatContact
            };

            this.onChatSelected.next({ ...emptyChatData });

        });
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void {
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void {
        this.api.postRequest('bo/chat/chat-user/' + this.user.id, userData)
            .subscribe(() => {
                this.user = userData;
            }
            );
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog): Promise<any> {
        return new Promise((resolve, reject) => {

            const newData = {
                id: chatId,
                dialog: dialog
            };

            this.api.postRequest('bo/chat/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                }, reject);
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any> {
        return new Promise((resolve) => {
            this.api.getRequest('bo/chat/chat-contacts')
                .subscribe((response: any) => {
                    resolve(response.payload);
                }, (err: any) => {
                    console.log(err);
                }, () => {
                });
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any> {
        return new Promise((resolve) => {
            this.api.getRequest('bo/chat/chat-chats')
                .subscribe((response: any) => {
                    resolve(response.payload);
                }, (err: any) => {
                    console.log(err);
                }, () => {
                });
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any> {
        return new Promise((resolve) => {
            this.api.getRequest('bo/chat/chat-user')
                .subscribe((response: any) => {
                    resolve(response.payload);
                }, (err: any) => {
                    console.log(err);
                }, () => {
                });
        });
    }

    getprofileImageId() {
        return this.myAccount.profileImageId;
    }

    getUserId() {
        return this.myAccount.id;
    }

}
