export class FileElement {
  id?: string;        // repositoryId
  isFolder: boolean;
  type?: string;
  name: string;
  parent: string;
  repoName?: string; // repository key
  owner?: string;
  Uuid?: string;
  deleted?: boolean;
  canModify?: [];
  canView?: [];
  tags?: [];
  isLoaded: boolean = false;
}
