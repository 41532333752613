import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sort" })
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string, order?: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        const sortOrder = order ? order : 'asc'; 
        array.sort((a: any, b: any) => {
            if (sortOrder == 'desc') {
                if (a[field] > b[field]) {
                    return -1;
                } else if (a[field] < b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if (a[field] < b[field]) {
                    return -1;
                } else if (a[field] > b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        return array;
    }
}

