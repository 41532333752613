import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit {
  autoClose: boolean;
  timeout: number;
  fileUrl: any;
  fileType: any;
  constructor(
    public dialogRef: MatDialogRef<MediaPreviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    // this.usefulTips = "<p><b>pluto</b></p><p>ddddfffffff</p><p><strong>pippo</strong></p><ol><li>asssdddee</li><li>bssss</li><li>v</li></ol><p><br></p>";
    /// this.loadData(this.usefulTips);

    if(this.data != undefined && this.data != null) {
       console.log('init content'); 
      this.fileUrl = this.data.mediaUrl;
      this.fileType = this.data.mediaType;
        // this.value = this.data.qrcode;
        if(this.data.buttons != undefined && this.data.buttons != null && this.data.buttons.length > 0) {
          this.autoClose = true;
          for (let index = 0; index < this.data.buttons.length; index++) {
            const element = this.data.buttons[index];
            if(element.close != undefined && element.close != null && element.close == true) {
              this.autoClose = false;
              break;
            }
          }
        } else {
          this.autoClose = true;
          this.timeout = 3000;
          if(this.data.timeout != undefined && this.data.timeout != null)
            this.timeout = this.data.timeout;
        }
      }
  
      if(this.autoClose == true) {
        setTimeout(()=> {
          this.dialogRef.close();
        }, this.timeout);
      }
  }
  onButtonClick(item) {
    if(item == undefined || item == null)
      return;
    let value = null;
    if(item.value != undefined && item.value != null)
      value = item.value;
    if(item.callback != undefined && item.callback != null)
      item.callback(value);
    if(item.close != undefined && item.close != null && item.close == true)
      this.dialogRef.close(value);
  }

}
