import { Component, OnInit, ViewChild, QueryList, ElementRef, Input, Injector, OnDestroy, EventEmitter } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { NotificationService } from 'app/main/services/notification.service';
import { AlertComponentComponent } from 'app/main/shared/alert/alert-component/alert-component.component';
import { AccountService } from 'app/main/shared/services/account.service';
import { TokenService } from 'app/main/shared/services/token.service';
import { Subject, Subscription, timer } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import * as Bowser from "bowser"; // ES6 (and TypeScript with --esModuleInterop enabled)
import { WebsocketService } from 'app/main/shared/services/websocket.service';
import { EventWebSocketService } from 'app/main/shared/services/event-web-socket.service';
import { UserService } from 'app/main/admin/services/user.service';
import { VideocallEventInteractionService } from 'app/main/apps/videocall/eventWebSocket.service';
import { environment } from 'environments/environment';
import * as  objectTracker from '@cloud-annotations/object-tracking';
import { EventInteractionService } from '../services/event-interaction.service';
import { EmergenciesService } from '../services/emergencies.service';
import { VideocallService } from '../services/videocall.service';
import { CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { FileService } from '../../admin/services/file.service';
// import { FileElement } from '../../admin/file-explorer/model/element';
import { ArrayType } from '@angular/compiler';

import { saveAs as importedSaveAs } from 'file-saver';
// import * as  moment from 'angular-formio/node_modules/moment';
import * as moment from 'moment';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { DynamicFormDataInput } from '../dynamic-form/dynamic-form-data-input';
// import moment = require('angular-formio/node_modules/moment');

// declare let moment: any;  



// let ICE_SERVERS: RTCIceServer[] = [
//   { urls: ['turn:turn.remoteoperationsupport.com:3478'], credential: "RosTurn202", username: "turnros" },
//   { urls: ['turn:51.178.80.88:3478'], credential: "Idra2017", username: "idra" },
//   { urls: 'stun:stun.l.google.com:19302' }
// ];

let ICE_SERVERS: RTCIceServer[] = [
  {
    urls: 'turn:turn.videosop.it:3478',
    credential: "RosTurn2020",
    username: "turnros"
  },
  {
    urls: 'turn:turn.remoteoperationsupport.com:3478',
    credential: "RosTurn2020",
    username: "turnros"
  },
  { urls: 'stun:stun.l.google.com:19302' }
];

// let ICE_SERVERS: RTCIceServer[] = [
//   { 
//     urls: 'turn:51.178.80.88:3478',
//     credential: 'Idra2017',
//     username: 'idra'
//   },
//   { urls: 'stun:stun.l.google.com:19302' }
// ];

let PEER_CONNECTION_CONFIG: RTCConfiguration = {
  iceServers: ICE_SERVERS,
};

const optionalsConstraints = [
  { "DtlsSrtpKeyAgreement": true },
  { "googImprovedWifiBwe": false },
  { "googDscp": false },
  { "googCpuOveruseDetection": false }
];

const offerConstraints = {
  offerToReceiveAudio: true,
  offerToReceiveVideo: true,
  optionals: optionalsConstraints,
};

const answerConstraints = {
  optional: optionalsConstraints,
  //iceRestart: true
};

@Component({
  selector: 'app-videocall',
  templateUrl: './videocall.component.html',
  styleUrls: ['./videocall.component.scss']
})
export class VideocallComponent extends AdminUtilComponent implements OnInit, OnDestroy {

  @ViewChild("me") me: any;
  @ViewChild("remote") remote: any;
  @ViewChild("rosCamVideo") rosCamVideoElement: QueryList<ElementRef>;
  @ViewChild('myCanvas') canvas: ElementRef;

  // @ViewChild('myCanvas') canvas: any;
  @ViewChild('canvasToRecord') canvasToRecord: ElementRef;
  // canvasToRecordElement: any;

  @Input("emergencyId") emergencyId: string;
  @Input("contactId") contactId: string;
  @Input() role: string;

  @Input('operatorContactId') operatorContactId: string;
  @Input('operatorSocketSessionId') operatorSocketSessionId: string;
  @Input('operatorIsAnswer') operatorIsAnswer: string

  @Input('videoCallId') videoCallId: string;

  // @ViewChild('recordedVideo') recordedVideoElementRef: ElementRef;
  // recordedVideoElement: HTMLVideoElement;

  // drawing = new EventEmitter();
  drawing = new EventEmitter<any>();
  drawingCoordsEvent = new EventEmitter<any>();

  factor = 2;

  drawingCoordinates = new Array();
  lastCoordinates: any;
  currentCoordinate: any;

  rxjsTimer = timer(1000, 200);
  // timerDestroy = new Subject();
  timerDestroy: any;
  // recordedVideo: any;

  canvasStream: MediaStream;
  mediaRecorder: MediaRecorder;
  recordedBlobs: Blob[]
  isRecording: boolean = false
  // downloadUrl: string
  downloadUrl: SafeUrl;
  videoBufferBlob;

  // loading;
  private pcPeers: RTCPeerConnection[];
  //private socket: WebSocket;
  private localStream: any;
  private inboundStream: MediaStream;
  private socketInterval: any;
  //private contactId: any;
  private myAccount: any;

  callActive: boolean;

  private earlyCandidates = [];
  inCall: boolean = false;
  veis: Subscription;
  remoteTrack: any[];
  isShare: boolean;
  addedTrack: Array<RTCRtpSender>;
  isOffer: boolean;
  shareScreenStream: any;
  //emergencyId: any;
  isMuted: boolean;
  ewss: Subscription;
  rosCamList: Array<any>;
  getRosCamTimer: NodeJS.Timeout;
  rosCamTracks: {};
  rosCamInboundStreams: any;
  routerSubscription: Subscription;
  isCollapsed: boolean = false;
  isOperatorCall: boolean;
  isOperatorAnswer: boolean;
  callingTimeout: NodeJS.Timeout;
  callId: number;
  //fromId: any;
  isDragDropDisable = true;


  // setting Canvas design parameter
  canvasElement: any;
  lastX: number;
  lastY: number;

  rec_width: number;
  rec_height: number;

  pressed: boolean;
  isDrawEnable = false;
  isFingerEnable = false;
  isTrackable = false;
  isFreezed = false;
  fingerPosX = 0;
  fingerPosY = 0;
  videoRemote: any;

  coordinates = new Array();


  // offsetTopC1: number;
  currentColour = 'black';
  brushSize = 3;
  videoDim: any;
  currentX: any;
  currentY: any;
  tracker: any;
  tracketIntervall: NodeJS.Timeout;
  // setting Canvas design parameter

  videoPrimaryContainerClass = 'videoPrimary';
  videoPrimaryClass = 'halfspace video videoPrimaryInner';

  videoSecondaryContainerClass = 'videoSecondary videRosCam';
  videoSecondaryClass = 'video-content video';

  // roscamListClass='videoSecondary   videRosCam ';
  // rosCamContainerClass =  'videoSecondary   videRosCam  video-content video  ';


  videoRosCamListClass = [];

  videoMeContainerClass = this.videoSecondaryContainerClass;
  videoMeClass = this.videoSecondaryClass;

  videoRemoteContainerClass = this.videoPrimaryContainerClass;
  videoRemoteClass = this.videoPrimaryClass;

  // VideoRosCamClass = [];
  videoRosCamListClassPRE = [];
  rosCamContainerClass = []
  videoListArrayClass = []

  lastIndiceCam = -1;
  canvasIshidden = false;
  isDrawActive = true;
  isTrackableActive = true;
  isFingerActive = true;
  lastSnapShoot: any;

  // PER ATTIVARE I LOG  
  islogactive = environment.debugEnabled;
  eis: Subscription;
  currentContact: { contactId: string; socketSessionId: any; };
  sendFinger: boolean;
  fingerInterval: NodeJS.Timeout;
  dragging: boolean;
  rosCamListId = [];
  lastIndiceClickedCam: -1;
  recordClass: String;
  firstDisconnect: boolean = false;
  videocallIsAdded: boolean;


  // currentRoot: FileElement;

  constructor(/*private route: ActivatedRoute,*/
    private account: AccountService,
    public fileService: FileService,
    private tokenService: TokenService,
    private videocallEventInteractionService: VideocallEventInteractionService,
    private eventInteractionService: EventInteractionService,

    private eventWebSocketService: EventWebSocketService,
    private dialogMat: MatDialog,
    private snackBarMat: MatSnackBar,
    private notificationService: NotificationService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private webSocketService: WebsocketService,
    private userService: UserService,
    private emergenciesService: EmergenciesService,
    private videocallService: VideocallService,
    injector: Injector,
    private sanitizer: DomSanitizer) {
    super(dialogMat, snackBarMat, injector);
    this.earlyCandidates = [];
    this.addedTrack = [];
    this.shareScreenStream = null;
    //const browser = Bowser.getParser(window.navigator.userAgent);
    if (this.islogactive)
      console.log('browser--------', Bowser.parse(window.navigator.userAgent));

    window.onresize = (e) => {
      this.initCanvas();
    };

    if (environment.ICE_SERVERS != undefined && environment.ICE_SERVERS != null) {
      ICE_SERVERS = environment.ICE_SERVERS;
      PEER_CONNECTION_CONFIG = {
        iceServers: ICE_SERVERS
      };
    }




  }



  manageCanvas() {
    const ctxCanvasToRecord = this.canvasToRecord.nativeElement.getContext('2d');
    const dim = this.videoDimensions(this.videoRemote);
    const video_width = dim.width;
    const video_height = dim.height;
    this.canvasToRecord.nativeElement.width = video_width / this.factor;
    this.canvasToRecord.nativeElement.height = video_height / this.factor;
    this.canvasToRecord.nativeElement.style.width = (video_width / this.factor) + 'px';
    this.canvasToRecord.nativeElement.style.height = (video_height / this.factor) + 'px';

  }

  /* Change seconds to time (HH:MM:SS) format */
  secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    /* Set as JSON object */
    var obj = {
      'h': this.intToString(hours),
      'm': this.intToString(minutes),
      's': this.intToString(seconds)
    };

    return obj;
  }

  /* Ensure that each time is set in 2 characters according to the time format */
  intToString(time) {
    return (parseInt(time) < 10 ? ('0' + time) : time);
  }


  firstCoord: any;
  async ngOnInit() {


    // Gets account 
    this.isShare = false;
    this.isMuted = false;
    this.callId = null;
    if (this.videoCallId != undefined && this.videoCallId != null && this.videoCallId.trim() != '') {
      try {
        this.callId = parseInt(this.videoCallId);
      } catch (error) {
        console.log(error);
      }
    }
    const videoremote = <HTMLVideoElement>this.remote.nativeElement;

    videoremote.addEventListener('resize', () => { this.initCanvas(); });


    // let self = this;
    // var currentTime = document.getElementById('currentTime');

    videoremote.addEventListener('timeupdate', function () {

      // var currSec = videoremote.currentTime;
      // var c_time = self.secondsToTime(currSec);

      // currentTime.innerHTML = currSec + 'sec (' + c_time.h +
      //   ':' + c_time.m + ':' + c_time.s + ')';
    }, false);


    videoremote.addEventListener('loadeddata', () => {
     
      this.drawing.subscribe((val) => {
        this.currentCoordinate = val.coordinate;
      });

      this.drawingCoordsEvent.subscribe((val) => {
        let temp = Object.assign([], val.coordinates);
        this.firstCoord = temp.shift();
        this.drawingCoordinates = Object.assign([], temp);
        //        console.log('this.drawingCoordinates: ', this.drawingCoordinates);
      });


    });


    videoremote.addEventListener('seeked', function () {
      console.log('seeked');
    });



    await this.account.getAccount().then((res: any) => {
      this.callActive = false;
      this.myAccount = res;

      //this.emergencyId = this.route.snapshot.queryParams.emergencyId;
      if (this.emergencyId) {
        this.emergenciesService.processEmergency(this.contactId, this.emergencyId, 'VIDEO', (resp) => {
          this.goOnFunctions();
        }, (error) => {
          // const fromPage = this.route.snapshot.queryParams.fromPage;
          // if (fromPage === 'mappaPoi') {
          //   this.router.navigateByUrl('/admin/map');
          // } else if (fromPage === 'patientsList') {
          //   this.router.navigateByUrl('/admin/pazienti');
          // } else if (fromPage === 'emergencyList') {
          //   this.router.navigateByUrl('/admin/emergencyNotifications');
          // }
        })
        // this.notificationService.processingEmergency(this.emergencyId, {type: 'VIDEO'}).subscribe((resp) => {
        //   this.goOnFunctions();
        // }, (error) => {
        //   // const fromPage = this.route.snapshot.queryParams.fromPage;
        //   // if (fromPage === 'mappaPoi') {
        //   //   this.router.navigateByUrl('/admin/map');
        //   // } else if (fromPage === 'patientsList') {
        //   //   this.router.navigateByUrl('/admin/pazienti');
        //   // } else if (fromPage === 'emergencyList') {
        //   //   this.router.navigateByUrl('/admin/emergencyNotifications');
        //   // }
        // });
      } else {
        this.goOnFunctions();
      }
    });

    this.routerSubscription = this.router.events.subscribe((val) => {
      // see also 
      if (this.islogactive) console.log(val instanceof NavigationEnd);
      if (this.islogactive) console.log(val);
      if (val instanceof NavigationEnd) {
        if (val.url != '/admin/emergencyNotifications') {
          this.isCollapsed = true;
          this.isDragDropDisable = false;

        } else {
          this.isCollapsed = false;
          this.isDragDropDisable = true;
          this.resetDraggedOriginalPosition();

        }
      }
    });


  }

  resetDraggedOriginalPosition() {
    for (let i = 0; i <= this.elementDragged.length; i++)
      if (this.elementDragged[i] != undefined)
        this.elementDragged[i]._dragRef.reset();
  }

  goOnFunctions() {
    //this.contactId = this.route.snapshot.queryParams.contactId;
    this.isOperatorCall = false;
    if (this.contactId == undefined || this.contactId == null) {
      if (this.operatorContactId != undefined && this.operatorContactId != null)
        this.contactId = this.operatorContactId;
      this.isOperatorCall = true;
      this.isOperatorAnswer = false;
      if (this.operatorIsAnswer != undefined && this.operatorIsAnswer != null && this.operatorIsAnswer == 'true')
        this.isOperatorAnswer = true;
    }
    if (this.isOperatorCall) {
      this.currentContact = {
        contactId: this.contactId,
        socketSessionId: this.operatorSocketSessionId
      }
    }
    this.start(this.contactId);


    //this.route.snapshot.paramMap.get("contactId");
    this.eis = this.eventInteractionService.getObservable().subscribe(this.eventInteractionServiceSubscription.bind(this));
    this.veis = this.videocallEventInteractionService.getObservable().subscribe(this.eventInteractionServiceSubscription.bind(this));

    this.ewss = this.eventWebSocketService.getObservable().subscribe((data) => {
      if (this.islogactive)
        console.log('eventWebSocketService ', data);
      this.onSocketMessage(data);
    });


    if (this.contactId != undefined && this.contactId != null) {
      if (this.islogactive)
        console.log("call createPC - ngOnInit");
      this.getLocalStream(this.contactId);

      //this.createPC(this.contactId, true);
      setTimeout(() => {
        this.tryToContact(this.contactId);
      }, 2000);

    }

    // this.initCanvas();
  }

  eventInteractionServiceSubscription(data) {
    //if (this.islogactive) console.log('Data received', data);
    if (this.callActive)
      return;

    if (data != undefined && data != null
      && data.event != undefined && data.event != null) {
      switch (data.event) {
        case 'contactId':
          console.log('DEPRECATED!!!!')
          this.contactId = data.contactId;
          if (this.islogactive)
            console.log("call createPC - eventWebSocketService");
          //this.createPC(this.contactId, true);
          this.tryToContact(this.contactId);
          break;
        default:
          break;
      }
    }
  }

  ngOnDestroy() {

    this.hideLoader();
    // this.timerDestroy.next();
    // this.timerDestroy.complete();


    this.leaveAll();
    if (this.localStream != undefined && this.localStream != null) {
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.shareScreenStream != undefined && this.shareScreenStream != null) {
      this.shareScreenStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.stopSocketHeartbit();
    // if (this.socket != undefined && this.socket != null) {
    //   this.socket.close();
    //   this.socket = null;
    // }

    this.videocallService.removeAllVideo();

    if (this.eis != undefined && this.eis != null)
      this.eis.unsubscribe();
    if (this.veis != undefined && this.veis != null)
      this.veis.unsubscribe();
    if (this.ewss != undefined && this.ewss != null)
      this.ewss.unsubscribe();

    if (this.routerSubscription != undefined && this.routerSubscription != null)
      this.routerSubscription.unsubscribe();
    this.callId = null;
  }

  start(contactId) {
    //this.setupSignalingServer(contactId);
    this.socketOpen(contactId);
  }

  private setupSignalingServer(contactId) {
    // this.socket = new WebSocket(environment.webSocketUrl + "?authtoken=" + this.myAccount.userId);

    // this.socket = new WebSocket(environment.webSocketUrl + "?authtoken=" + this.tokenService.getToken());
    // this.socket.onmessage = this.getSignalMessageCallback();
    // this.socket.onopen = this.socketOpen(contactId);
    // this.socket.onerror = this.errorHandler.bind(this);
  }

  private tryToContact(toUserId) {
    try {
      this.emit('tryToContact', {
        'toUserId': toUserId,
        'userUuid': toUserId,
        randomId: Math.floor(Math.random() * 10000000000000),
        timestamp: new Date().getTime()
      }, 'tryToContact');
    } catch (ex) {
      if (this.islogactive) console.log("errore trytoconnect", ex);
    }
  }

  private createPC(toUserId, isRosCam) {
    if (this.islogactive)
      console.log('Create Peer connection', toUserId, isRosCam, PEER_CONNECTION_CONFIG);
    var pc = new RTCPeerConnection(PEER_CONNECTION_CONFIG);
    if (this.pcPeers == undefined || this.pcPeers == null) {
      this.pcPeers = [];
    }
    pc['isRosCam'] = isRosCam;
    this.pcPeers[toUserId] = pc;
    // pc.onicecandidate = this.getIceCandidateCallback();
    if (isRosCam) {
      pc['socketSessionId'] = toUserId;
      // pc.onicecandidate = (ev) => {
      //   this.getIceCandidateCallback(pc, ev, toUserId)
      // };
      pc.onicecandidate = (event) => {
        if (this.islogactive) console.log(`got ice candidate:`);
        if (this.islogactive) console.log(event);
        if (event.candidate != null) {
          //this.socket.send(JSON.stringify({ 'ice': event.candidate, 'uuid': this.contactId }));
          this.emit('exchange', { //'to': this.contactId, 
            'toUserId': toUserId,
            'candidate': event.candidate
          }, null);
        }
      }
    } else {
      pc['socketSessionId'] = this.currentContact ? this.currentContact.socketSessionId : '';
      // pc.onicecandidate = (ev) => {
      //   this.getIceCandidateCallback(pc, ev, this.contactId)
      // };

      pc.onicecandidate = (event) => {
        if (this.islogactive)
          console.log(`got ice candidate:`);
        if (this.islogactive)
          console.log(event);

        if (event.candidate != null) {
          //this.socket.send(JSON.stringify({ 'ice': event.candidate, 'uuid': this.contactId }));
          this.emit('exchange', { //'to': this.contactId, 
            'toUserId': this.contactId,
            'candidate': event.candidate
          }, null);
        }
      }
    }

    pc.oniceconnectionstatechange = (event) => {
      if (this.islogactive)
        console.log("oniceconnectionstatechange");
      if (pc.iceConnectionState === 'closed') {
        this.onCiceConnectionStateChangeClosedAction(pc);

      } else if (pc.iceConnectionState === 'disconnected') {
        console.log('DISCONNECTED... TRY TO CONNECT')
        // if(pc['disconnectedTimeout']) {
        //   clearTimeout(pc['disconnectedTimeout'])
        //   pc['disconnectedTimeout'] = null;
        // }
        this.showLoaderText('riconnessione in corso...');
        pc['disconnectedTimeout'] = setTimeout(() => {
          this.onCiceConnectionStateChangeClosedAction(pc);
        }, 60000);
      }
      else if (pc.iceConnectionState === 'connected') {
        console.log('CONNECTED')
        if(pc['disconnectedTimeout']) {
          clearTimeout(pc['disconnectedTimeout'])
          pc['disconnectedTimeout'] = null;
        }
        this.hideLoader();
        this.firstDisconnect = false;
      }
    }

    if (isRosCam) {
      pc.ontrack = (ev) => { this.getOnRosCamTrack(pc, ev, toUserId) };
      console.log("getOnRosCamTrack", toUserId)
    } else {
      pc.ontrack = (ev) => { 
        console.log('ontrack event!')
        this.getOnTrack(pc, ev) 
      }
    }

    pc['addtrack'] = (ev) => { 
      console.log('addtrack event!')
      this.getOnTrack(pc, ev) 
    }

    pc['onaddtrack'] = (ev) => { 
      console.log('onaddtrack event!')
      this.getOnTrack(pc, ev) 
    }

    pc['track'] = (ev) => { 
      console.log('track event!')
      this.getOnTrack(pc, ev) 
    }

    pc['addstream'] = (ev) => {
      console.log('addstream event!')
      this.getOnStream(pc, ev);
    }



    pc.onnegotiationneeded = () => {
      if (this.islogactive) console.log('onnegotiationneeded');
      if (this.isOffer) {
        this.createOffer(pc, toUserId);
      }
    }
    if (this.localStream != undefined && this.localStream != null) {
      if (isRosCam)
        this.replaceTrackToPeer(this.localStream, toUserId);
      else {
        let pcc: any = pc;
        pcc.addStream(this.localStream);
        this.replaceTrackToPeer(this.localStream, this.contactId);
      }
      /*for (const track of this.localStream.getTracks()) {
        pc.addTrack(track);
      }*/
    }
    return pc;
  }

  onCiceConnectionStateChangeClosedAction(pc){
    this.hideLoader();
    if (pc['isRosCam'] == true) {

      // console.log("JSON",json);
      const target = event.target['socketSessionId'];

      for (let i = 0; i < this.rosCamList.length; i++) {
        if (this.rosCamList[i].value == target) {
          console.log(i, this.videoRosCamListClass[i]);
          this.videoRosCamListClass[i] = "hideCam";
        }
        // console.log(i, this.rosCamList[i].value);
        console.log(i, this.videoRosCamListClass[i]);
      }
      //TODO gestire rimozione blocco video della roscam rimossa
      // contactId: "UUID-USR-1faacec6-8cb0-4c06-9e66-c0aae239879f", socketSessionId: "98524526-4bcb-87a1-99e0-17bb726d4e1b"} contactId: "UUID-USR-1faacec6-8cb0-4c06-9e66-c0aae239879f" socketSessionId: "98524526-4bcb-87a1-99e0-17bb726d4e1b"
    } else {
      // if(!this.firstDisconnect) {
      //   this.firstDisconnect = true;
      //   this.isOffer = true;
      //   this.createPC(this.contactId, false);
      //   return;
      // } 

      console.log('avevo già ricevuto un disconnect...')
      this.leave();

    }
  }

  private createOffer(pc, toUserId) {
    pc.createOffer(offerConstraints).then((desc) => {
      if (this.islogactive)
        console.log('createOffer', desc);
      pc.setLocalDescription(desc).then(() => {
        if (this.islogactive) console.log('setLocalDescription', pc.localDescription);
        if (this.callId == undefined || this.callId == null)
          this.callId = Math.floor(Math.random() * 10000000000000);
        this.emit('exchange', {
          'toUserId': toUserId,
          'sdp': pc.localDescription,
          'callId': "" + this.callId
        });
      }).catch(ex1 => { if (this.islogactive) console.log(ex1) });
    }).catch(ex => { if (this.islogactive) console.log(ex) });
  }

  private getOnRosCamTrack(pc, ev, socketIDRosCam) {
    if (this.islogactive) console.log('getOnRosCamTrack', ev);
    if (this.rosCamTracks == undefined || this.rosCamTracks == null)
      this.rosCamTracks = {};
    if (this.rosCamTracks[pc.id] == undefined || this.rosCamTracks[pc.id] == null)
      this.rosCamTracks[pc.id] = new Array<any>();
    this.rosCamTracks[pc.id].push(ev.track);

    // let rosCamVideoElements = this.rosCamVideoElement.toArray().filter(r => {
    //   r.nativeElement.getAttribute('roscamId')==rosCamId;
    // })
    const rosCamId = ev.track.id;
    console.log("CERCHIAMO DI CAPIRCI ", ev.track, "socketIDRosCam", socketIDRosCam, pc.id)

    // this.initRosCamClass(socketIDRosCam);

    this.videocallService.addVideo({ contactId: this.contactId, socketSessionId: pc['socketSessionId'] }, !this.isOperatorCall, this.emergencyId, true);

    setTimeout(() => {
      let rosCamVideoElements = document.getElementsByClassName("rosCam_" + socketIDRosCam);

      //let video = <HTMLVideoElement>this.remote.nativeElement;

      let video = <HTMLVideoElement>rosCamVideoElements[0];


      console.log("rosCam_" + socketIDRosCam, rosCamVideoElements);

      if (ev.streams && ev.streams[0]) {
        video.srcObject = ev.streams[0];
      } else {
        if (!this.rosCamInboundStreams) {
          this.rosCamInboundStreams = {};
        }
        if (!this.rosCamInboundStreams[socketIDRosCam]) {
          this.rosCamInboundStreams[socketIDRosCam] = new MediaStream();
          video.srcObject = this.rosCamInboundStreams[socketIDRosCam];
        }
        this.removeAlreadyTrack(ev.track, this.rosCamInboundStreams[socketIDRosCam]);


        this.rosCamInboundStreams[socketIDRosCam].addTrack(ev.track);
      }
      video.onloadedmetadata = () => {
        video.play();
      };
    }, 100);

  }

  private getOnTrack(pc, ev) {
    //pc.ontrack = (ev) => {
    this.performance('middle', 'initializing', 'ontrack');
    if (this.islogactive) console.log('ontrack', ev, pc);
    this.callActive = true;
    //this.videocallEventInteractionService.publishData({ event: 'close', close: true });
    this.eventInteractionService.publishData({ event: 'close', close: true });
    this.setInCall(true);
    if (!this.isOperatorCall && !this.videocallIsAdded) {
      this.emergenciesService.tryToUpdateEmergency(this.emergencyId, 'add', 'VIDEO', null);
    }
    if(!this.videocallIsAdded)
      this.videocallService.addVideo(this.currentContact, !this.isOperatorCall, this.emergencyId, false);
    this.videocallIsAdded = true;

    if (this.remoteTrack == undefined || this.remoteTrack == null)
      this.remoteTrack = new Array<any>();
    this.remoteTrack.push(ev.track);

    let video = <HTMLVideoElement>this.remote.nativeElement;

    if (ev.streams && ev.streams[0]) {
      video.srcObject = ev.streams[0];
      this.inboundStream = ev.streams[0];
    } else {
      if (!this.inboundStream) {
        this.inboundStream = new MediaStream();
        video.srcObject = this.inboundStream;
      }
    }
    this.removeAlreadyTrack(ev.track, this.inboundStream);


    this.inboundStream.addTrack(ev.track);
    this.performance('middle', 'initializing', 'ontrack1');
    
    video.onloadedmetadata = () => {
      this.performance('end', 'initializing', 'play');
      video.play();
      this.initCanvas();
      this.tryToGetRosCams();
    };
  }

  private getOnStream(pc, ev) {
    //pc.ontrack = (ev) => {
    this.performance('middle', 'initializing', 'onaddstream');
    if (this.islogactive) console.log('onaddstream', ev, pc);
    this.callActive = true;
    //this.videocallEventInteractionService.publishData({ event: 'close', close: true });
    this.eventInteractionService.publishData({ event: 'close', close: true });
    this.setInCall(true);
    if (!this.isOperatorCall && !this.videocallIsAdded) {
      this.emergenciesService.tryToUpdateEmergency(this.emergencyId, 'add', 'VIDEO', null);
    }
    if(!this.videocallIsAdded)
      this.videocallService.addVideo(this.currentContact, !this.isOperatorCall, this.emergencyId, false);
    this.videocallIsAdded = true;

    if (this.remoteTrack == undefined || this.remoteTrack == null)
      this.remoteTrack = new Array<any>();
    

    let video = <HTMLVideoElement>this.remote.nativeElement;

    if (ev.stream) {
      video.srcObject = ev.stream;
      this.inboundStream = ev.stream
      this.remoteTrack = this.inboundStream.getTracks();
    } 
      this.performance('middle', 'initializing', 'onaddstream1');
    video.onloadedmetadata = () => {
      this.performance('end', 'initializing', 'play');
      video.play();
      this.initCanvas();
      this.tryToGetRosCams();
    };
  }

  // private getOnTrack(): (string) => void {
  //   return (ev) => {
  //     //pc.ontrack = (ev) => {
  //     this.performance('middle', 'initializing', 'ontrack');
  //     if (this.islogactive) console.log('ontrack', event);
  //     this.callActive = true;
  //     this.eventInteractionService.publishData({ event: 'close', close: true });
  //     this.setInCall(true);
  //     if (this.remoteTrack == undefined || this.remoteTrack == null)
  //       this.remoteTrack = new Array<any>();
  //     this.remoteTrack.push(ev.track);
  //     let video = <HTMLVideoElement>this.remote.nativeElement;

  //     if (ev.streams && ev.streams[0]) {
  //       video.srcObject = ev.streams[0];
  //     } else {
  //       if (!this.inboundStream) {
  //         this.inboundStream = new MediaStream();
  //         video.srcObject = this.inboundStream;
  //       }
  //       this.removeAlreadyTrack(ev.track, this.inboundStream);


  //       this.inboundStream.addTrack(ev.track);
  //       this.performance('middle', 'initializing', 'ontrack1');
  //     }
  //     video.onloadedmetadata = () => {
  //       this.performance('end', 'initializing', 'play');
  //       video.play();
  //       this.tryToGetRosCams();
  //     };
  //   };
  // }

  private removeAlreadyTrack(track, inboundStream) {
    const trackType = track.kind;
    if (inboundStream.getTracks().length > 0) {
      for (let index = 0; index < inboundStream.getTracks().length; index++) {
        const element = inboundStream.getTracks()[index];
        if (element.kind == trackType)
          inboundStream.removeTrack(element);
      }
    }
  }

  // private getSignalMessageCallback(): (string) => void {
  //   return (event) => {
  //     try {
  //       if (this.islogactive) console.log(event.data);
  //       var msg;
  //       try {
  //         msg = JSON.parse(event.data);
  //       } catch (e1) {
  //         msg = event.data;
  //       }
  //       if (this.islogactive) console.log(msg);
  //       switch (msg.event) {
  //         case "call":
  //           if (msg.data.status == 'exchange')
  //             this.exchange(msg.data);
  //           else if (msg.data.status == 'leave')
  //             this.leave(this.contactId);
  //           break;
  //         case "tryToContact":
  //           if (msg.data.result != undefined && msg.data.result != null && msg.data.result.textMessage != undefined && msg.data.result.textMessage != null && msg.data.result.textMessage == "true") {
  //             if(!this.isOperatorCall) {
  //               this.isOffer = true;
  //               this.createPC(this.contactId, false);
  //             } else {
  //               this.emit('calling', { 'toUserId': this.contactId });
  //             }
  //           } else {
  //             this.alertRedirectToEmergency();
  //           }
  //           break;

  //       }
  //     } catch (e) {
  //       if (this.islogactive) console.log("Errore getSignalMessageCallback - ", e);
  //     }
  //   };
  // }

  private onSocketMessage(event) {
    try {
      if (this.islogactive) console.log(event.data);
      var msg;
      /*try {
        msg = JSON.parse(event.data);
      } catch (e1) {
        msg = event.data;
      }*/
      msg = event;
      if (this.islogactive) console.log('onSocketMessage', msg);
      switch (msg.event) {
        case "call":
          if (msg.data.status == 'exchange')
            this.exchange(msg.data);
          else if (msg.data.status == 'leave')
            this.leave(this.contactId);
          else if (msg.data.status == 'answer') {
            if (this.currentContact == undefined || this.currentContact == null
              || this.currentContact.socketSessionId == undefined || this.currentContact.socketSessionId == null
              || this.currentContact.contactId == undefined || this.currentContact.contactId == null) {
              this.currentContact = {
                contactId: this.contactId,
                socketSessionId: msg.data.result.fromUser.socketSessionFrom
              }
            }

            if (this.callingTimeout != undefined && this.callingTimeout != null) {
              clearTimeout(this.callingTimeout);
              this.callingTimeout = null;
            }
          } else if (msg.data.status == 'reject') {
            this.leave(this.contactId);
          } else if (msg.data.status == 'roscamadded') {
            try {
              let customData = msg.data.customData;
              customData = JSON.parse(customData);
              this.tryToAddRoscam(customData.contactUser, msg.data.from)
              // this.addRoscam(msg.data.from);
            } catch (ex) {
              if (this.islogactive) console.log(ex);
            }

          } else if(msg.data.status == 'changeSocketId') {
            let customData = msg.data.customData;
              customData = JSON.parse(customData);
            this.tryToChangeSocketIds(customData);
          }
          break;
        case "tryToContact":
          if (msg.data.result != undefined
            && msg.data.result != null
            && msg.data.result.textMessage != undefined
            && msg.data.result.textMessage != null
            && msg.data.result.textMessage == "true") {
            if (!this.isOperatorCall || this.isOperatorAnswer) {
              if (msg.data.result.fromUser != undefined &&
                msg.data.result.fromUser != null &&
                msg.data.result.fromUser.socketSessionFrom != undefined &&
                msg.data.result.fromUser.socketSessionFrom != null) {
                this.currentContact = {
                  contactId: this.contactId,
                  socketSessionId: msg.data.result.fromUser.socketSessionFrom
                }
              } else {
                this.currentContact = {
                  contactId: this.contactId,
                  socketSessionId: ''
                }
              }
              this.isOffer = true;
              this.createPC(this.contactId, false);
            } else {
              this.callId = Math.floor(Math.random() * 10000000000000);
              this.emit('calling', {
                'toUserId': this.contactId,
                'callId': "" + this.callId
              });
              this.callingTimeout = setTimeout(() => {
                this.leaveAll();
                //this.videocallEventInteractionService.publishData({ event: 'videocallTimeout'});
                this.eventInteractionService.publishData({ event: 'videocallTimeout' });
              }, 30000);
            }
          } else {
            this.alertRedirectToEmergency();
          }
          break;

      }
    } catch (e) {
      console.log("Errore getSignalMessageCallback - ", e);
    }
  }

  alertRedirectToEmergency(): void {
    const dialog = this.dialog.open(AlertComponentComponent, {
      // data: `${data}`,
      data: {
        title: "Attenzione",
        body: "L'utente al momento non è disponibile. Sarai ridirezionato sulla gestione coda interventi",
        buttons: [
          {
            text: 'OK',
            close: true,
            value: true,
            callback: () => {
              this.router.navigateByUrl('/admin/emergencyNotifications');
            }
          }
        ]
      }

    });
    dialog.afterClosed().toPromise();
  }

  private socketOpen(contactId): (string) => void {
    return (message) => {
      if (this.islogactive) console.log('connect');
      // this.socketInterval = setInterval(() => {
      //   this.emit('heartbit', { timestamp: new Date().getTime() }, 'heartbit');
      // }, 20 * 1000);
      this.getLocalStream(contactId);
      //this.captureUserMedia(null);
    };
  }

  private stopSocketHeartbit() {
    if (this.socketInterval != undefined && this.socketInterval != null) {
      try {
        clearInterval(this.socketInterval);
        this.socketInterval = null;
      } catch (ex) {
        if (this.islogactive) console.log(ex);
        this.socketInterval = null;
      }
    }
  }



  private exchange(data) {
    var fromId = data.from;
    var socketSessionFrom = data.socketSessionFrom;
    //this.fromId = fromId;
    var pc;
    if (this.pcPeers && fromId in this.pcPeers) {
      pc = this.pcPeers[fromId];
    } else {
      if (this.islogactive) console.log("call createPC - exchange");
      this.isOffer = false;
      pc = this.createPC(fromId, false);
    }


    if (data.sdp) {
      if (this.islogactive) console.log('exchange sdp', data);
      this.performance('start', 'initializing', 'exchange sdp');
      pc.setRemoteDescription(new RTCSessionDescription(data.sdp)).then(() => {
        if(pc['inCandidates']) {
          console.log('REMOTE DESCRIPTION SET - ADD ' + pc['inCandidates'].length + ' CANDIDATES')
          for (let index = 0; index < pc['inCandidates'].length; index++) {
            const element = pc['inCandidates'][index];
            console.log('REMOTE DESCRIPTION SET - ADD CANDIDATE')
            this.addCandidate(pc, element)
          }
          pc['inCandidates'] = [];
        }
        if (pc.remoteDescription.type == "offer") {
          this.isOffer = false;
          pc.createAnswer(answerConstraints).then((desc) => {
            if (this.islogactive) console.log('createAnswer', desc);
            pc.setLocalDescription(desc).then(() => {
              if (this.islogactive) console.log('setLocalDescription', pc.localDescription);
              this.emit('exchange', { 'to': fromId, 'sdp': pc.localDescription }, null);
            }, this.errorHandler.bind(this));
          }, this.errorHandler.bind(this));
        }
      }, this.errorHandler.bind(this));

    } else {
      if (this.islogactive) 
        console.log('exchange candidate', data);
      if(!pc || !pc.remoteDescription || !pc.remoteDescription.type) {
        console.log('REMOTE DESCRIPTION NOT FOUND - PUSH INCANDIDATES')
        if(!pc['inCandidates'])
          pc['inCandidates'] = new Array<any>();
        pc['inCandidates'].push(data.candidate);
      } else {
        console.log('REMOTE DESCRIPTION FOUND - ADD CANDIDATE')
        this.addCandidate(pc, data.candidate);
      }
    }
  }

  addCandidate(pc, candidate) {
    
      pc.addIceCandidate(new RTCIceCandidate(candidate))
        .then(() => {
          if (this.islogactive) console.log('ice candidate added...');
        })
        .catch(ex => {
          if (this.islogactive) {
            console.log(candidate);
            console.log(ex);
          } 
        });
  }

  private async getLocalStream(contactId) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let video = false;
    let audio: any = false;
    if (devices != undefined && devices != null) {
      for (let index = 0; index < devices.length; index++) {
        const element = devices[index];
        if (element.kind == 'videoinput')
          video = true;
        else if (element.kind == 'audioinput')
          audio = { echoCancellation: true }
      }
    }
    if (video == false && audio == false) {
      return;
    }
    
    navigator.mediaDevices.getUserMedia({ audio: audio, video: video })
      .then(stream => (this.me.nativeElement.srcObject = stream))
      .then(stream => {

        this.me.nativeElement.muted = true;
        this.me.nativeElement.volume = 0;
        if (this.islogactive) console.log("Avviato localstream");

        // this.initVideoMe();

        this.localStream = stream;
        if (this.localStream != undefined && this.localStream != null) {
          this.replaceTrackToPeer(this.localStream, contactId);

          // this.startMerge();

          // var b = document.querySelector("button");  
          // var clicked = false;
          // let self = this;
          // var b = document.getElementById("mergeBtn");
          // b.addEventListener("click", function (e) {
          //   if (!clicked) {
          //     self.startMerge();
          //     // mediaRecorder.start();
          //     // osc.start(0);
          //     // e.target.innerHTML = "Stop recording";
          //     clicked = true;
          //   } else {
          //     clicked = false;
          //     // mediaRecorder.stop();
          //     // osc.stop(0);
          //     // e.target.disabled = true;
          //   }
          // });

          /* if (this.pcPeers != undefined && this.pcPeers != null && this.pcPeers[contactId] != undefined && this.pcPeers[contactId] != null) {
             let pc = this.pcPeers[contactId]
             for (const track of this.localStream.getLocalStream().getTracks()) {
               pc.addTrack(track);
             }
           }*/
        }
      });
  }

  replaceTrackToPeer(stream, contactId) {
    if (this.pcPeers != undefined && this.pcPeers != null && this.pcPeers[contactId] != undefined && this.pcPeers[contactId] != null) {
      let pc = this.pcPeers[contactId];
      for (const track of stream.getTracks()) {
        let sender = pc.getSenders().find(function (s) {
          return s.track.kind == track.kind;
        });
        if (this.islogactive) console.log('found sender:', sender);
        if (sender)
          sender.replaceTrack(track);
        else
          pc.addTrack(track);
      }
      /*if(this.addedTrack.length > 0) {
        let audio = false;
        let video = false;
        for (const track of stream.getTracks()) {
          if(track.kind == 'audio')
            audio = true;
          if(track.kind == 'video')
            video = true;
        }
        let toremove = [];
        for (let index = 0; index < this.addedTrack.length; index++) {
          const element = this.addedTrack[index];
          if((element.track.kind == 'video' && video) ||
          (element.track.kind == 'audio' && audio)) {
            this.pcPeers[contactId].removeTrack(element);
            toremove.push(this.addedTrack.indexOf(element));
          }
        }
        for (let index = 0; index < toremove.length; index++) {
          const element = toremove[index];
          this.addedTrack.splice(element, 1);
        }
      }

      //this.addedTrack = [];
      //this.isOffer = true;
      for (const track of stream.getTracks()) {
        this.addedTrack.push(this.pcPeers[contactId].addTrack(track));
      }*/
    }
  }

  private onGettingDisplaySteam(stream: MediaStream, callback) {
    if (this.islogactive) console.log('onGettingDisplaySteam', stream);
    this.isShare = true;
    this.isOffer = true;
    stream.getVideoTracks()[0].addEventListener('ended', () => {
      this.shareScreen();
    });
    this.replaceTrackToPeer(stream, this.contactId);

    setTimeout(() => {
      this.enableRemoteScreenShare();

    }, 2000);

    /*video.srcObject = stream;
    videosContainer.insertBefore(video, videosContainer.firstChild);

    addStreamStopListener(stream, function() {
        location.reload();
    });

    config.attachStream = stream;
    callback && callback();
    rotateVideo(video);

    addStreamStopListener(stream, function() {
        location.reload();
    });

    showPrivateLink();

    document.querySelector('.hide-after-join').style.display = 'none';*/
  }

  shareScreen() {

    if (this.isShare) {
      this.isShare = false;
      this.isOffer = true;
      this.shareScreenStream = null;
      this.replaceTrackToPeer(this.localStream, this.contactId);
    } else {
      this.captureUserMedia(() => {
        if (this.islogactive) console.log('callback!');
      });
    }
  }

  private captureUserMedia(callback) {
    /*var video = document.createElement('video');
    video.muted = true;
    video.volume = 0;
    try {
        video.setAttributeNode(document.createAttribute('autoplay'));
        video.setAttributeNode(document.createAttribute('playsinline'));
        video.setAttributeNode(document.createAttribute('controls'));
    } catch (e) {
        video.setAttribute('autoplay', true);
        video.setAttribute('playsinline', true);
        video.setAttribute('controls', true);
    }*/

    if (navigator['getDisplayMedia'] || navigator.mediaDevices['getDisplayMedia']) {

      if (navigator.mediaDevices['getDisplayMedia']) {
        navigator.mediaDevices['getDisplayMedia']({ video: true }).then(stream => {
          this.shareScreenStream = stream;
          this.onGettingDisplaySteam(stream, callback);
        }, this.errorHandler.bind(this)).catch(this.errorHandler.bind(this));
      }
      else if (navigator['getDisplayMedia']) {
        navigator['getDisplayMedia']({ video: true }).then(stream => {
          this.shareScreenStream = stream;
          this.onGettingDisplaySteam(stream, callback);
        }, this.errorHandler.bind(this)).catch(this.errorHandler.bind(this));
      }
    }
    else {
      if (this.islogactive) console.log('non è presente il getDispalyMedia')
      // var isChrome = !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
      // var isFirefox = typeof InstallTrigger !== 'undefined';
      // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
      // var isIE = /*@cc_on!@*/false || !!document.documentMode;
      // var isEdge = !isIE && !!window.StyleMedia;
      let browser = Bowser.parse(window.navigator.userAgent);

      if (browser.browser.name === 'Chrome') {
        if (browser.browser.version.startsWith('71')) {
          this.newSnackbar('Please enable "Experimental WebPlatform" flag via chrome://flags.', 'error');
        } else {
          let v: any = 0;
          try {
            let version = browser.browser.version;
            v = version.split('.')[0];
            v = parseInt(v);
          } catch (e) {
            if (this.islogactive) console.log(e);
          }

          if (v < 71) {
            this.newSnackbar('Please upgrade your Chrome browser.', 'error');
          } else {
            this.newSnackbar('Please make sure that you are not using Chrome on iOS.', 'error');
          }
        }
      }

      if (browser.browser.name === 'Firefox') {
        this.newSnackbar('Please upgrade your Firefox browser.', 'error');
      }

      if (browser.browser.name === 'Edge') {
        this.newSnackbar('Please upgrade your Edge browser.', 'error');
      }

      if (browser.browser.name === 'Safari') {
        this.newSnackbar('Safari does NOT supports getDisplayMedia API yet.', 'error');
      }
    }
  }

  mute() {
    let pc: RTCPeerConnection = this.pcPeers[this.contactId];
    var sender = pc.getSenders().find((s) => {
      return s.track.kind == 'audio';
    });
    if (this.islogactive) console.log('found sender:', sender);
    sender.track.enabled = this.isMuted;
    this.isMuted = !this.isMuted;
  }


  showRemote() {
    try {
      if (this.islogactive) console.log("call createPC - showRemote");
      this.isOffer = true;
      this.createPC(this.contactId, false);
    } catch (error) {
      this.start(null);
      if (this.islogactive) console.log(error);
    }
  }

  // private emit(status, data, event?, callback?) {
  //   if (event == undefined || event == null)
  //     event = 'call';
  //   data['status'] = status;
  //   var msg = {
  //     event: event,
  //     data: data
  //   }
  //   if (this.socket != undefined && this.socket != null && this.socket.readyState == this.socket.OPEN) {
  //     this.socket.send(JSON.stringify(msg));
  //   } else {
  //     if (this.islogactive) console.log("Messaggio non inviato");
  //     if (this.islogactive) console.log("Socket", this.socket);
  //     if (this.islogactive) console.log("Message", msg);

  //   }
  // }

  private emit(status, data, event?, callback?) {
    this.webSocketService.emit(status, data, event, callback);
  }

  leaveAll() {
    this.callActive = false;
    if (this.islogactive) console.log('leaveAll');
    let ic = this.inCall;
    this.setInCall(false);
    if (this.pcPeers != undefined && this.pcPeers != null) {

      for (const key in this.pcPeers) {
        if (this.pcPeers.hasOwnProperty(key)) {
          const pc = this.pcPeers[key];
          if (pc != undefined && pc != null) {
            pc.close();
          }
        }
      }
    }

    let video = <HTMLVideoElement>this.remote.nativeElement;
    video.srcObject = null;
    this.inboundStream = null;
    this.remoteTrack = [];
    //this.videocallEventInteractionService.publishData({ event: 'close', close: false });
    this.eventInteractionService.publishData({ event: 'close', close: false });

    this.emit('leave', { 'callId': "" + this.callId, 'toUserId': this.contactId, });

    if (ic && !this.isOperatorCall)
      this.closeEmergency(this.contactId);

    if (!this.isOperatorCall)
      this.router.navigateByUrl('/admin/emergencyNotifications');

  }

  public leave(toUserId?) {
    this.callActive = false;
    if (toUserId == undefined || toUserId == null)
      toUserId = this.contactId;
    if (toUserId == undefined || toUserId == null)
      return;
    if (this.islogactive) console.log('leave', toUserId);
    let ic = this.inCall;
    this.setInCall(false);
    if (this.pcPeers != undefined && this.pcPeers != null) {
      var pc = this.pcPeers[toUserId];
      if (pc != undefined && pc != null) {
        pc.close();
        delete this.pcPeers[toUserId];
      } else {
        ic = false;
      }
    } else {
      ic = false;
    }
    //var video = document.getElementById("remoteView" + toUserId);
    //  if (video) video.remove();

    let video = <HTMLVideoElement>this.remote.nativeElement;
    video.srcObject = null;
    this.inboundStream = null;
    this.remoteTrack = [];
    //this.videocallEventInteractionService.publishData({ event: 'close', close: false });
    this.eventInteractionService.publishData({ event: 'close', close: false });

    this.emit('leave', { 'callId': "" + this.callId, 'toUserId': this.contactId, });
    if (this.callingTimeout != undefined && this.callingTimeout != null) {
      clearTimeout(this.callingTimeout);
      this.callingTimeout = null;
    }
    this.videocallService.removeVideo(this.currentContact);
    if (ic && !this.isOperatorCall) {
      this.closeEmergency(toUserId);
    }
    // if (!this.isOperatorCall) {
    //   this.router.navigateByUrl('/admin/emergencyNotifications');
    // }


    // this.eventInteractionService.publishData({event:'videocallTimeouts'})

  }

  //
  closeEmergency(toUserId: any) {
    const closed = (result) => {
      if (result && result.data) {
        this.loading = true;
        this.emergenciesService.removeEmergencyByUserId(
          toUserId,
          (res) => {
            this.loading = false;
            // this.isInEmergency = false;
            // delete this.emergencies[toUserId];
          },
          (error) => {
            this.loading = false;
            // console.log(error);
            // delete this.emergencies[toUserId];
          })
        // return this.notificationService.closeEmergencyByPatientId(toUserId).subscribe((res: any) => {
        //   let emergencyId = null;
        //   if (res['payload'] != undefined && res['payload'] != null)
        //     emergencyId = res['payload'].emergencyId;
        //   this.notificationService.getEmergencyNotfications('OPEN, PROCESSING').subscribe((resp: any) => {
        //     this.notificationService.updateEmergencyNotificationsValue(true);
        //   });

        //   // this.newSnackbar(`Emergenza chiusa.`, 'success');
        //   if (emergencyId == undefined || emergencyId == null)
        //     emergencyId = this.emergencyId;
        //   if (emergencyId != undefined && emergencyId != null)
        //     // this.askForNote(this._formBuilder, this.notificationService, emergencyId);
        //     this.askForNotes(this._formBuilder, this.notificationService, emergencyId)
        //       .then(
        //         (dataNote) => {
        //           this.newSnackbar(`Nota chiusura creata.`, 'success');
        //           if (this.islogactive) console.log('dataNote: ', dataNote);
        //         },
        //         (errNote) => {
        //           this.newSnackbar(`Errore creazione nota chiusura.`, 'warn');
        //           if (this.islogactive) console.log('errNote: ', errNote);
        //         }
        //       );
        // }, (error) => {
        //   this.loading = false;
        //   console.log(error);
        // });
      }
    };
    this.closeAllEmergencyByUserId(toUserId, closed);
  }

  setInCall(inCallVal) {
    this.inCall = inCallVal;
    //this.videocallEventInteractionService.publishData({ event: 'inCall', inCall: inCallVal });
    this.eventInteractionService.publishData({ event: 'inCall', inCall: inCallVal });
    if (this.inCall == false) {
      //this.videocallEventInteractionService.publishData({event:'toolbarVideocallStop'})
      this.eventInteractionService.publishData({ event: 'toolbarVideocallStop' })
    }

  }

  public audio_off(id) {
    //     if(id=="me"){
    //  var audio = <HTMLVideoElement>this.me.nativeElement;
    //     }else{
    // var audio = <HTMLVideoElement>this.remote.nativeElement;
    //     }

    if (id == "me") {
      this.me.nativeElement.muted = !this.me.nativeElement.muted;
      this.me.nativeElement.volume == 0 ? this.me.nativeElement.volume = 1 : this.me.nativeElement.volume = 0;
      if (this.islogactive) console.log('me audio', this.me.nativeElement.muted);
      // if(this.me.nativeElement.muted){
      //   this.me.nativeElement.muted = false;
      //   // audio.muted=false;
      //   if (this.islogactive) console.log('audio me');
      // }else{
      //   this.me.nativeElement.muted = false;
      //   if (this.islogactive) console.log('audio me muted');
      // }
    } else {
      this.remote.nativeElement.muted = !this.remote.nativeElement.muted;
      if (this.islogactive) console.log('remote audio', this.remote.nativeElement.muted);
      // if(this.remote.nativeElement.muted){
      //   this.remote.nativeElement.muted = false;
      //   // audio.muted=false;
      //   if (this.islogactive) console.log('audio remote');
      // }else{
      //   this.remote.nativeElement.muted = false;
      //   if (this.islogactive) console.log('audio remote muted');
      // }
    }
  }

  public video_off(id) {
    // if(id=="me"){
    //   var videome = <HTMLVideoElement>this.me.nativeElement;
    //      }else{
    //  var videome = <HTMLVideoElement>this.remote.nativeElement;
    //      }

    if (id == "me") {
      if (this.me.nativeElement.paused) {
        this.me.nativeElement.play();
        // audio.muted=false;
        if (this.islogactive) console.log('video me');
      } else {
        this.me.nativeElement.pause();
        if (this.islogactive) console.log('video me muted');
      }
    } else {
      if (this.remote.nativeElement.paused) {
        this.remote.nativeElement.play();
        // audio.muted=false;
        if (this.islogactive) console.log('video remote');
      } else {
        this.remote.nativeElement.pause();
        if (this.islogactive) console.log('video remote muted');
      }
    }



    // if(videome.paused){
    //   videome.play();
    //   if (this.islogactive) console.log('play');
    // }else{
    //   videome.pause();
    //   if (this.islogactive) console.log('pause');
    // }
    // if (this.islogactive) console.log(videome)
  }

  private getIceCandidateCallback(event, pc, toUserId) {
    if (this.islogactive) console.log(`got ice candidate:`);
    if (this.islogactive) console.log(event);

    if (event.candidate != null) {
      //this.socket.send(JSON.stringify({ 'ice': event.candidate, 'uuid': this.contactId }));
      this.emit('exchange', { //'to': this.contactId, 
        'toUserId': toUserId,
        'candidate': event.candidate
      }, null);
    }
  }

  // private getIceCandidateCallback(): (string) => void {
  //   return (event) => {
  //     if (this.islogactive) console.log(`got ice candidate:`);
  //     if (this.islogactive) console.log(event);

  //     if (event.candidate != null) {
  //       //this.socket.send(JSON.stringify({ 'ice': event.candidate, 'uuid': this.contactId }));
  //       this.emit('exchange', { //'to': this.contactId, 
  //         'toUserId': this.contactId,
  //         'candidate': event.candidate
  //       }, null);
  //     }
  //   };
  // }

  tryToGetRosCams() {
    if (this.islogactive) console.log('tryToGetRosCams');
    if (this.getRosCamTimer != undefined && this.getRosCamTimer != null) {
      clearTimeout(this.getRosCamTimer);
    }
    this.getRosCamTimer = setTimeout(() => {
      this.getRosCamTimer = null;
      this.userService.getRosCam(this.contactId).toPromise().then((res) => {
        console.log("initListaRosCams", res);
        if (res['ok'] == true) {
          this.rosCamList = res['payload'];
          this.initListaRosCams(this.rosCamList);
          setTimeout(() => {
            this.tryToGetRosCamStreams();
          }, 2000);
        }
      })
    }, 1000);
  }

  // INIZIALIZZO LE ROSACAM CHE SONO RIMASTE ATTIVE CONNESSE ALL'UTENTE
  initListaRosCams(camList) {
    console.log("camList", camList)
    for (let i = 0; i <= camList.length; i++) {
      if (camList[i] !== undefined) {
        console.log("camList", camList[i].value)

        // this.VideoRosCamClass[i] = this.videoSecondaryClass;
        this.initRosCamClass(camList[i].value)
        // this.videoRosCamListClass[i] = 'video-content video rosCam_' + camList[i].value;
        // this.rosCamContainerClass[i] = 'videoSecondary   videRosCam  video-content video';
        // this.videoRosCamListClassPRE[i] = 'video-content video rosCam_' + camList[i].value;
        // if (this.islogactive) console.log('lista delle camereos', this.rosCamList[i].value);
      }

    }

  }

  // initVideoMe()
  // {
  //     this.lastIndiceCam = 0
  //     this.videoRosCamListClass[0] = 'video-content video ' 
  //     this.rosCamContainerClass[0] = 'videoSecondary   videRosCam  video-content video';
  //     this.videoRosCamListClassPRE[0] = 'video-content video '; 
  // }


  initRosCamClass(camValue) {

    // INDICE PER COMODIT' E' INIZIALIZZATO A -1 per getstire il caso di thumb non roscam
    this.lastIndiceCam = this.lastIndiceCam + 1;
    // this.VideoRosCamClass[i] = this.videoSecondaryClass;
    this.videoRosCamListClass[this.lastIndiceCam] = 'video-content video rosCam_' + camValue;
    this.rosCamContainerClass[this.lastIndiceCam] = 'videoSecondary   videRosCam  video-content video';
    this.videoRosCamListClassPRE[this.lastIndiceCam] = 'video-content video rosCam_' + camValue;
    // if (this.islogactive) console.log('lista delle camereos', this.rosCamList[i].value);
    this.rosCamListId[this.lastIndiceCam] = camValue;


  }

  tryToGetRosCamStreams() {
    if (this.islogactive) console.log('tryToGetRosCams');
    if (this.rosCamList === undefined || this.rosCamList === null) {
      return;
    }
    for (let index = 0; index < this.rosCamList.length; index++) {
      const element = this.rosCamList[index];
      this.isOffer = true;
      this.createPC(element.value, true);
    }
  }

  tryToAddRoscam(contactId, from) {
    if (this.contactId == contactId) {
      setTimeout(() => {
        this.addRoscam(from);
      }, 1000);

    }
  }

  tryToChangeSocketIds(customData) {
    // customData.oldSocketId;
    // customData.newSocketId;
    console.log('can change socketId', customData);
    let pc = this.pcPeers[customData.oldSocketId];
    if(pc) {
      this.pcPeers[customData.newSocketId] = pc;
    }
    if(this.contactId == customData.oldSocketId)
      this.contactId = customData.newSocketId;
  }

  addRoscam(from) {
    if (this.rosCamList == undefined || this.rosCamList == null)
      this.rosCamList = [];

    this.rosCamList.push({ value: from });
    // this.initRosCamClass(from);

    this.isOffer = true;
    this.createPC(from, true);

    setTimeout(() => {
      this.initRosCamClass(from);
    }, 10);

  }

  private errorHandler(error) {
    if (this.islogactive) 
      console.log("errorHandler", error);
  }

  performance(type, block, func) {
    if (window['performanceMap'] == undefined || window['performanceMap'] == null)
      window['performanceMap'] = {};
    if (this.islogactive) console.log('performance', type, block, func);
    switch (type) {
      case 'start':
        window['performanceMap'][block] = {};
      case 'middle':
        if (window['performanceMap'][block] == undefined)
          return;
        window['performanceMap'][block][new Date().getTime()] = func;
        break;
      case 'end':
        if (window['performanceMap'][block] == undefined)
          return;
        window['performanceMap'][block][new Date().getTime()] = func;
        let start = null;
        if (this.islogactive) console.log('--------performance-----------')
        for (const key in window['performanceMap'][block]) {
          if (window['performanceMap'][block].hasOwnProperty(key)) {
            const element = window['performanceMap'][block][key];
            if (start == null) {
              start = parseInt(key);
              continue;
            }
            let elapsed = parseInt(key) - start;
            if (this.islogactive) console.log('-------performance ' + block + ' ' + element + ' after ' + elapsed);
          }
        }
        if (this.islogactive) console.log('--------performance end-----------')
        break;
      default:
        break;
    }
  }




  enableButtonAR() {
    this.isDrawActive = true;
    this.isTrackableActive = true;
    this.isFingerActive = true;
    this.isFreezed = true;


  }

  disableButtonAR() {
    this.isDrawActive = false;
    this.isTrackableActive = false;
    this.isFingerActive = false;

    this.isDrawEnable = false;
    this.isTrackable = false;
    this.isFingerEnable = false;
    this.isFreezed = false;
  }


  hideCanvas() {
    this.canvasIshidden = true;
  }

  showCanvas() {
    setTimeout(() => {
      this.canvasIshidden = false;
      this.initCanvas();
    }, 1000);

  }
  // FUNZIONI PER DISEGNARE SUL CANVAS


  initCanvas() {

    const video = document.getElementById('device');

    if (this.canvas == undefined || (this.canvas.nativeElement == undefined || this.canvas.nativeElement == null || video == undefined || video == null)) {
      console.log("errore nella generazione del canvas o del video");
      return;
    }

    this.canvasElement = this.canvas.nativeElement;

    this.videoRemote = video;
    const dim = this.videoDimensions(video);

    this.videoDim = dim;

    const video_width = dim.width;
    const video_height = dim.height;

    this.canvasElement.width = video_width;
    this.canvasElement.height = video_height;

    // if (this.islogactive) console.log('init', video_width, video_height);
    this.canvasElement.style.width = video_width + 'px';
    this.canvasElement.style.height = video_height + 'px';

    // It works
    // let stream = this.canvasElement.captureStream();
    // console.log('stream: ', stream);


  }



  // InitMediaRecorderPromise(): Promise<any> {
  //   return new Promise((resolve, reject) => {

  //     if (this.canvasToRecord == undefined || (this.canvasToRecord.nativeElement == undefined || this.canvasToRecord.nativeElement == null)) {
  //       console.log("errore nella generazione del canvas o del video");
  //       reject("errore nella generazione del canvas o del video");
  //       return;
  //     }

  //     this.currentCoordinate = null;
  //     const dim = this.videoDimensions(this.videoRemote);
  //     const video_width = dim.width;
  //     const video_height = dim.height;
  //     this.canvasToRecord.nativeElement.width = video_width / this.factor;
  //     this.canvasToRecord.nativeElement.height = video_height / this.factor;
  //     this.canvasToRecord.nativeElement.style.width = (video_width / this.factor) + 'px';
  //     this.canvasToRecord.nativeElement.style.height = (video_height / this.factor) + 'px';

  //     if (this.mediaRecorder == null) {
  //       const sUsrAg = navigator.userAgent;

  //       this.recordedBlobs = [];

  //       let options: MediaRecorderOptions;
  //       if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
  //         options = { mimeType: 'video/webm; codecs=vp9' };
  //       } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
  //         options = { mimeType: 'video/webm; codecs=vp8' };
  //       } else {
  //         options = { mimeType: 'video/webm' };
  //       }

  //       let canvasToRecordStream = null;
  //       try {
  //         if (sUsrAg.indexOf('Firefox') > -1) {
  //           // canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
  //           canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
  //         } else {
  //           console.log('Other');
  //           canvasToRecordStream = this.canvasToRecord.nativeElement.captureStream(30);
  //         }
  //       }
  //       catch (error) {
  //         reject(error);
  //         this.newSnackbar(`Errore captureStream: ${error}`, 'warn', 7000);
  //       }

  //       try {
  //         this.mediaRecorder = new MediaRecorder(canvasToRecordStream, options);
  //       }
  //       catch (error) {
  //         reject(error);
  //         this.newSnackbar(`Errore mediaRecorder istanza: ${error}`, 'warn', 7000);
  //         console.log(error);
  //       }

  //       try {
  //         this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
  //           if (event.data && event.data.size > 0) {
  //             // console.log('event.data: ', event.data);
  //             this.recordedBlobs.push(event.data);
  //           }
  //         }
  //       } catch (error) {
  //         reject(error);
  //         this.newSnackbar(`Errore mediaRecorder dati: ${error}`, 'warn', 8000);
  //         console.log(error);
  //       }

  //       try {
  //         this.mediaRecorder.onstop = (event: Event) => {
  //           this.isRecording = !this.isRecording;
  //           console.log('this.mediaRecorder.state: ', this.mediaRecorder.state);
  //           const videoBuffer = new Blob(this.recordedBlobs, { type: 'video/mp4' });
  //           this.videoBufferBlob = videoBuffer;
  //           this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(videoBuffer));
  //           console.log('this.downloadUrl: ', this.downloadUrl);

  //           if (this.timerDestroy != undefined || this.timerDestroy != null) {
  //             this.timerDestroy.next();
  //             this.timerDestroy.complete();
  //             // this.timerDestroy = null;
  //           }

  //           this.recordedBlobs = [];
  //         }
  //       } catch (error) {
  //         reject(error);
  //         this.newSnackbar(`Errore mediaRecorder stop: ${error}`, 'warn', 8000);
  //         console.log(error);
  //       }
  //     }

  //     resolve();
  //   });
  // }

  // mergeChunks = [];
  InitMediaRecorderWithMediaStream(): Promise<any> {
    return new Promise((resolve, reject) => {

      if (this.canvasToRecord == undefined || (this.canvasToRecord.nativeElement == undefined || this.canvasToRecord.nativeElement == null)) {
        console.log("errore nella generazione del canvas o del video");
        reject("errore nella generazione del canvas o del video");
        return;
      }

      this.currentCoordinate = null;
      const dim = this.videoDimensions(this.videoRemote);
      const video_width = dim.width;
      const video_height = dim.height;
      this.canvasToRecord.nativeElement.width = video_width / this.factor;
      this.canvasToRecord.nativeElement.height = video_height / this.factor;
      this.canvasToRecord.nativeElement.style.width = (video_width / this.factor) + 'px';
      this.canvasToRecord.nativeElement.style.height = (video_height / this.factor) + 'px';

      const mergeAudioStreams = (remoteStream, localStream) => {
        const context = new AudioContext();
        let remote = null, local = null;

        if (remoteStream && remoteStream.getAudioTracks().length > 0) {
          try {
            remote = context.createMediaStreamSource(remoteStream);
          }
          catch (err) {
            console.log("context.createMediaStreamSource(remoteStream) failed")
          }
        }
          
        if (localStream && localStream.getAudioTracks().length > 0) {
          try {
            local = context.createMediaStreamSource(localStream);
          }
          catch (err) {
            console.log("context.createMediaStreamSource(localStream) failed");
          }
        }
        
        let destination = null;
        try {
          destination = context.createMediaStreamDestination();
        }
        catch (err) {
          console.log("context.createMediaStreamDestination() failed");
          return null;
        }
  
        const remoteGain = context.createGain();
        const localGain = context.createGain();
        
        remoteGain.gain.value = 0.7;
        localGain.gain.value = 0.7;
          
        if (remote) {
          remote.connect(remoteGain).connect(destination);
        }
         
        if (local) {
          local.connect(localGain).connect(destination);
        }
          
        return destination.stream.getAudioTracks();
      };

      if (this.mediaRecorder == null) {
        const sUsrAg = navigator.userAgent;

        this.recordedBlobs = [];

        let options: MediaRecorderOptions;
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          options = { mimeType: 'video/webm; codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          if (sUsrAg.indexOf('Firefox') > -1) {
            { options = { mimeType: 'video/webm; codecs=vp8,opus' }; }
          }
          else { options = { mimeType: 'video/webm; codecs=vp8' }; }
        } else {
          options = { mimeType: 'video/webm' };
        }

        let canvasToRecordStream = null;
        try {
          if (sUsrAg.indexOf('Firefox') > -1) {
            // canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
            // canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
            this.canvasToRecord.nativeElement.getContext('2d');
            canvasToRecordStream = this.canvasToRecord.nativeElement.captureStream(30);
          } else {
            console.log('Other');
            canvasToRecordStream = this.canvasToRecord.nativeElement.captureStream(30);
          }
        }
        catch (error) {
          this.newSnackbar(`Errore captureStream: ${error}`, 'warn', 7000);
          return reject(error);
        }


        let remoteVideoStream = null;
        try {
          if (sUsrAg.indexOf('Firefox') > -1) {
            // canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
            remoteVideoStream = this.remote.nativeElement.mozCaptureStream(30);
          } else {
            console.log('Other');
            remoteVideoStream = this.remote.nativeElement.captureStream(30);
          }
        }
        catch (error) {
          this.newSnackbar(`Errore remoteVideoStream: ${error}`, 'warn', 7000);
          return reject(error);
        }


        let localVideoStream = null;
        try {
          if (sUsrAg.indexOf('Firefox') > -1) {
            // canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
            localVideoStream = this.me.nativeElement.mozCaptureStream(30);
          } else {
            console.log('Other');
            localVideoStream = this.me.nativeElement.captureStream(30);
          }
        }
        catch (error) {
          this.newSnackbar(`Errore localVideoStream: ${error}`, 'warn', 7000);
          return reject(error);
        }

        let videoTracks = canvasToRecordStream.getVideoTracks();
        let audioTracks = mergeAudioStreams(remoteVideoStream, localVideoStream);
        let mergeStream = new MediaStream();
        for (const track of videoTracks) {
          mergeStream.addTrack(track);
        }
    
        for (const track of audioTracks) {
          mergeStream.addTrack(track);
        }

        // let mediaRecorder = null;
        
        try {
          // this.mediaRecorder = new MediaRecorder(canvasToRecordStream, options);
          this.mediaRecorder = new MediaRecorder(mergeStream, options);
          // this.mergeChunks = [];
        }
        catch (error) {
          this.newSnackbar(`Errore mediaRecorder istanza: ${error}`, 'warn', 7000);
          console.log(error);
          return reject(error);
        }

        try {
          this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
            if (event.data && event.data.size > 0) {
              this.recordedBlobs.push(event.data);
            }
          }
        } catch (error) {
          this.newSnackbar(`Errore mediaRecorder dati: ${error}`, 'warn', 8000);
          console.log(error);
          return reject(error);
        }

        try {
          this.mediaRecorder.onstop = (event: Event) => {
            this.isRecording = !this.isRecording;
            console.log('this.mediaRecorder.state: ', this.mediaRecorder.state);
            const videoBuffer = new Blob(this.recordedBlobs, { type: 'video/mp4' });
            this.videoBufferBlob = videoBuffer;
            this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(videoBuffer));
            console.log('this.downloadUrl: ', this.downloadUrl);

            if (this.timerDestroy != undefined || this.timerDestroy != null) {
              this.timerDestroy.next();
              this.timerDestroy.complete();
              // this.timerDestroy = null;
            }

            this.recordedBlobs = [];
            this.mediaRecorder = null;
          }
        } catch (error) {
          this.newSnackbar(`Errore mediaRecorder stop: ${error}`, 'warn', 8000);
          console.log(error);
          return reject(error);
        }
      }
    });
  }

  // InitMediaRecorder() {

  //   if (this.canvasToRecord == undefined || (this.canvasToRecord.nativeElement == undefined || this.canvasToRecord.nativeElement == null)) {
  //     console.log("errore nella generazione del canvas o del video");
  //     return;
  //   }

  //   if (this.mediaRecorder == null) {
  //     const sUsrAg = navigator.userAgent;

  //     this.recordedBlobs = [];


  //     let options: MediaRecorderOptions;
  //     if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
  //       options = { mimeType: 'video/webm; codecs=vp9' };
  //     } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
  //       options = { mimeType: 'video/webm; codecs=vp8' };
  //     } else {
  //       options = { mimeType: 'video/webm' };
  //     }

  //     let canvasToRecordStream = null;
  //     if (sUsrAg.indexOf('Firefox') > -1) {
  //       canvasToRecordStream = this.canvasToRecord.nativeElement.mozCaptureStream(30);
  //     } else {
  //       console.log('Other');
  //       canvasToRecordStream = this.canvasToRecord.nativeElement.captureStream(30);
  //     }

  //     try {
  //       this.mediaRecorder = new MediaRecorder(canvasToRecordStream, options);
  //     }
  //     catch (error) {
  //       this.newSnackbar(`Errore mediaRecorder istanza: ${error}`, 'warn', 8000);
  //       console.log(error);
  //     }

  //     try {
  //       this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
  //         if (event.data && event.data.size > 0) {
  //           // console.log('event.data: ', event.data);
  //           this.recordedBlobs.push(event.data);
  //         }
  //       }
  //     } catch (error) {
  //       this.newSnackbar(`Errore mediaRecorder dati: ${error}`, 'warn', 8000);
  //       console.log(error);
  //     }

  //     try {
  //       this.mediaRecorder.onstop = (event: Event) => {
  //         this.isRecording = !this.isRecording;
  //         console.log('this.mediaRecorder.state: ', this.mediaRecorder.state);
  //         const videoBuffer = new Blob(this.recordedBlobs, { type: 'video/mp4' });
  //         this.videoBufferBlob = videoBuffer;
  //         this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(videoBuffer));
  //         console.log('this.downloadUrl: ', this.downloadUrl);

  //         this.recordedBlobs = [];
  //       }
  //     } catch (error) {
  //       this.newSnackbar(`Errore mediaRecorder stop: ${error}`, 'warn', 8000);
  //       console.log(error);
  //     }
  //   }

  // }

  // mergeStream: any;
  // startMerge() {

  //   const mergeAudioStreams = (firstStream, secondStream) => {
  //     const context = new AudioContext();
        
  //     if (firstStream && firstStream.getAudioTracks().length > 0) {

  //     }
  //     // Create a couple of sources
  //     const source1 = context.createMediaStreamSource(firstStream);
  //     const source2 = context.createMediaStreamSource(secondStream);

  //     const destination = context.createMediaStreamDestination();
      
  //     const firstGain = context.createGain();
  //     const secondGain = context.createGain();
        
  //     firstGain.gain.value = 0.7;
  //     secondGain.gain.value = 0.7;
       
  //     source1.connect(firstGain).connect(destination);
  //     // Connect source2
  //     source2.connect(secondGain).connect(destination);
        
  //     return destination.stream.getAudioTracks();
  //     // return destination.stream;
  //   };


   
  //   let canvasToRecordStream = this.canvasToRecord.nativeElement.captureStream();
  //   let remoteVideoStream = this.remote.nativeElement.captureStream();
  //   let localVideoStream = this.me.nativeElement.captureStream();
    
  //   // let videoTracks = localVideoStream.getVideoTracks();
  //   let videoTracks = canvasToRecordStream.getVideoTracks();
  //   let audioTracks = mergeAudioStreams(remoteVideoStream, localVideoStream);

  //   let mergeStream = new MediaStream();
  //   for (const track of videoTracks) {
  //     mergeStream.addTrack(track);
  //   }

  //   for (const track of audioTracks) {
  //     mergeStream.addTrack(track);
  //   }

  //   let video = null;
  //   video = <HTMLVideoElement>document.getElementById('preMergeVideo');
  //   video.srcObject = mergeStream;


  //   var clicked = false;
  //   var b = document.getElementById("recordMergeBtn");

  //   b.addEventListener("click", function (e) {
  //     console.log('clicked');
  //     if (!clicked) {
  //       mediaRecorder.start();
  //       console.log('mediaRecorder.start()');

  //       // osc.start(0);
  //       // e.target.innerHTML = "Stop recording";
  //       clicked = true;
  //     } else {
  //       mediaRecorder.stop();
  //       console.log('mediaRecorder.stop()');
  //       clicked = false;
  //       // osc.stop(0);
  //       // e.target.disabled = true;
  //     }
  //   });



  //   var mediaRecorder = new MediaRecorder(mergeStream);
  //   var mergeChunks = [];
  //   mediaRecorder.ondataavailable = function (evt) {
  //     mergeChunks.push(evt.data);
  //   };

  //   mediaRecorder.onstop = function (evt) {
  //     console.log('mergeChunks: ', mergeChunks);
  //     const videoBuffer = new Blob(mergeChunks, { type: 'video/mp4' });
  //     // this.playVideo(videoBuffer);

  //     var video = <HTMLVideoElement>document.getElementById('mergeVideo');
  //     var videoUrl = window.URL.createObjectURL(videoBuffer);
  //     video.src = videoUrl;

  //     mergeChunks = [];
  //   };


  // }

  // playVideo(videoStream) { // as blob 
  //   var video = <HTMLVideoElement>document.getElementById('mergeVideo');
  //   var videoUrl = window.URL.createObjectURL(videoStream.data);// blob.data gives actual data
  //   video.src = videoUrl;
  // }

  startRecordingStream() {

    this.initCanvas();

    this.recordClass = 'record';

    // this.InitMediaRecorderPromise().then(() => {
    // // this.InitMediaRecorderPromise()
    this.InitMediaRecorderWithMediaStream()
      .then(() => {

      // Init Timer 
      this.timerDestroy = new Subject();

      const ctxCanvasToRecord = this.canvasToRecord.nativeElement.getContext('2d');
      function iterate(item, index) {
        const factor = 2;
        ctxCanvasToRecord.lineTo(item.x / factor, item.y / factor);
        ctxCanvasToRecord.stroke();
        ctxCanvasToRecord.moveTo(item.x / factor, item.y / factor);
      }

      const dim = this.videoDimensions(this.videoRemote);
      // Start Timer
      this.rxjsTimer.pipe(takeUntil(this.timerDestroy)).subscribe(val => {

        if (this.isDrawEnable && this.isFreezed) {
          // Capture Freezed Image
          ctxCanvasToRecord.drawImage(this.canvasElement, 0, 0, dim.width / this.factor, dim.height / this.factor);
        }
        else {
          // Otherwise Capture Video
          ctxCanvasToRecord.drawImage(this.videoRemote, 0, 0, dim.width / this.factor, dim.height / this.factor);
        }

        // Drawing on freezed image
        if ( /* this.pressed && */ this.isDrawEnable && !this.isFingerEnable && this.isFreezed) {
          // console.log("isFreezed");
          if (this.drawingCoordinates && this.drawingCoordinates.length > 0) {
            const ctxCanvasToRecord = this.canvasToRecord.nativeElement.getContext('2d');
            ctxCanvasToRecord.beginPath();
            ctxCanvasToRecord.moveTo(this.firstCoord.x / this.factor, this.firstCoord.y / this.factor);
            this.drawingCoordinates.forEach(iterate);

            this.drawingCoordinates = [];
          }
        }
        // Drawing on video
        if ( /* this.pressed && */ this.isDrawEnable && !this.isFingerEnable && !this.isFreezed) {
          if (this.drawingCoordinates && this.drawingCoordinates.length > 0) {
            const ctxCanvasToRecord = this.canvasToRecord.nativeElement.getContext('2d');
            ctxCanvasToRecord.beginPath();
            ctxCanvasToRecord.moveTo(this.firstCoord.x / this.factor, this.firstCoord.y / this.factor);
            this.drawingCoordinates.forEach(iterate);

            // this.drawingCoordinates = [];
          }
        }

        // Drawing finger
        if (this.pressed && this.isFingerEnable && !this.isDrawEnable) {
          const ctxCanvasToRecord = this.canvasToRecord.nativeElement.getContext('2d');
          const r = 25 / this.factor;

          ctxCanvasToRecord.strokeStyle = '#000';
          ctxCanvasToRecord.lineWidth = 3;
          ctxCanvasToRecord.beginPath();
          ctxCanvasToRecord.arc(this.currentCoordinate.x / this.factor, this.currentCoordinate.y / this.factor, r, 0, Math.PI * 2, true);
          ctxCanvasToRecord.closePath();
          ctxCanvasToRecord.stroke();
        }

      });

      // Start Recording
      if (this.videoRemote) {
        try {
          this.mediaRecorder.start();
          console.log('this.mediaRecorder.state: ', this.mediaRecorder.state);
          this.isRecording = !this.isRecording;
        } catch (err) {
            this.newSnackbar(`Errore avvio registrazione: ${err}`, 'warn', 8000);
          console.log(err);
        }
      }
      else {
        console.log('no remote video');
        this.newSnackbar(`Nessun collegamento remoto`, 'warn', 6000);
      }
    },
      (err) => {

      });

  }


  stopRecordingStream() {
    try {
      this.mediaRecorder.stop();
      this.recordClass = '';
      this.saveVideo();
    }
    catch (error) {
      //this.newSnackbar(`Errore stop registrazione: ${error}`, 'warn', 6000);
      this.newSnackbar('Attendere per favore', 'warn', 6000);
      console.log(error);
    }
  }



  playRecording() {
    if (!this.recordedBlobs || !this.recordedBlobs.length) {
      console.log('cannot play.')
      return;
    }
    // this.recordVideoElement.play()
    // this.playedVideoElement.play()
  }


  getFrame() {
    const ctx = this.getContext();
    const dim = this.videoDimensions(this.videoRemote);
    ctx.drawImage(this.videoRemote, 0, 0, dim.width, dim.height);
    const frame = ctx.getImageData(0, 0, dim.width, dim.height);
    // ctx.putImageData(frame, 0, 0);
    return frame;
  }


  // IN VIA DI SVILUPPO *******************************
  async navigateToFolder(parentUuid, folderName) {

    return new Promise((resolve, reject) => {

      let repositoryId: string = null;
      let repoName = this.myAccount.currentTenant.id;

      this.fileService.getFolderByName(repoName, parentUuid, folderName)
        .subscribe((data: any) => {
          // folderName exists
          if (data && data.length > 0) {
            console.log("folder info: ", data[0]);
            repositoryId = data[0].repositoryId;
            resolve(repositoryId);
          }
          // folderName does not exist
          else {
            let obj = {
              parentId: parentUuid,
              repoName: repoName,
              folderName: folderName
            };
            this.fileService.addNewFolder(obj)
              .subscribe((data: any) => {
                if (data) {
                  repositoryId = data.repositoryId;
                  // this.newSnackbar(`Cartella ${folderName} creata.`, 'success');
                  resolve(repositoryId);
                }
              }, (error) => {
                // this.newSnackbar(`Errore ${error.statusText} nella creazione della cartella ${folderName} .`, 'warn', 4000);
                reject(error);
              });
          }
        });
    }
    );
  }


  async saveVideo() {
    const currentDayDir = moment().format('DD-MM-YYYY');
    let uuid_dateFolder = null;

    try {
      uuid_dateFolder = await this.navigateToFolder(null, currentDayDir);
      if (uuid_dateFolder != null) {
        // this.newSnackbar(`Cartella ${currentDayDir} creata.`, 'success');
      }
    } catch (error) {
      this.newSnackbar(`Errore '${error.statusText}' nella cartella ${currentDayDir} .`, 'warn', 4000);
      console.log('error: ', error);
      return;
    }


    // const subDir = this.emergencyId;
    const subDir = this.myAccount.name + '_' + this.emergencyId.substr(-6, 6);
    // console.log('subDir: ', subDir);
    this.loading = true;

    // const uuid = await this.navigateToFolder(uuid_dateFolder, subDir);
    this.navigateToFolder(uuid_dateFolder, subDir)
      .then((uuid: any) => {
        // console.log('uuid: ', uuid);

        // this.canvasElement.toBlob((blob) => {

        this.askForFileName(this._formBuilder, this.fileService, "").then(
          (fileName) => {
            this.loading = true;

            // console.log('fileName: ', fileName);
            let nomefile = this.emergencyId + '.mp4';
            if (fileName && fileName.data && fileName.data.note && fileName.data.note !== '') {
              nomefile = fileName.data.note + '.mp4';
            }

            // const nomefile = this.emergencyId + '.png';
            const newFile = this.blobToFile(this.videoBufferBlob, nomefile);

            // this.fileService.uploadImage(this.myAccount.currentTenant.id, uuid, newFile);
            this.fileService.uploadImageObservable(this.myAccount.currentTenant.id, uuid, newFile).toPromise().then(
              (res) => {
                this.newSnackbar(`File ${nomefile} creato.`, 'success');
                this.loading = false;
                let canModify = [];
                let canView = [];
                let tags = [];
                tags.push(this.emergencyId);

                const body = {
                  repositoryId: uuid,
                  canView: canView,
                  canModify: canModify,
                  tags: tags
                }

                this.fileService.updateInfo(body)
                  .subscribe(res => {
                    this.videoBufferBlob = null;
                  }, (err: any) => {
                    console.log('Error: ', err);
                    this.snackBarMat.open('Errore inserimento tag emergenza', '',
                      {
                        duration: 3000,
                        panelClass: 'warn'
                      });
                  },
                    async () => {

                    }); // end of subscribe;);
              },
              (error) => {
                this.loading = false;
                this.newSnackbar(`Errore '${error.statusText}' nella creazione del file ${nomefile} .`, 'warn', 4000);

              }
            );
          },
          (errNote) => {
            this.loading = false;
            // this.newSnackbar(`Errore creazione nota chiusura.`, 'warn');
            console.log('errNote: ', errNote);
          }
        );

        // }); // end of  this.canvasElement.toBlob((blob)
        this.loading = false;

      },
        (error) => {
          this.loading = false;
          console.log('error: ', error);
        }
      );

  }



  async saveImageOnCanvas() {
    const currentDayDir = moment().format('DD-MM-YYYY');
    let uuid_dateFolder = null;

    try {
      uuid_dateFolder = await this.navigateToFolder(null, currentDayDir);
      if (uuid_dateFolder != null) {
        // this.newSnackbar(`Cartella ${currentDayDir} creata.`, 'success');
      }
    } catch (error) {
      this.newSnackbar(`Errore '${error.statusText}' nella cartella ${currentDayDir} .`, 'warn', 4000);
      console.log('error: ', error);
      return;
    }



    // const subDir = this.emergencyId;
    const subDir = this.myAccount.name + '_' + this.emergencyId.substr(-6, 6);
    console.log('subDir: ', subDir);
    this.loading = true;

    // const uuid = await this.navigateToFolder(uuid_dateFolder, subDir);
    this.navigateToFolder(uuid_dateFolder, subDir)
      .then((uuid: any) => {
        console.log('uuid: ', uuid);
        this.canvasElement.toBlob((blob) => {

          this.askForFileName(this._formBuilder, this.fileService, "").then(
            (fileName) => {

              console.log('fileName: ', fileName);
              let nomefile = this.emergencyId + '.png';
              if (fileName && fileName.data && fileName.data.note && fileName.data.note !== '') {
                nomefile = fileName.data.note + '.png';
              }

              // const nomefile = this.emergencyId + '.png';
              const newFile = this.blobToFile(blob, nomefile);

              // this.fileService.uploadImage(this.myAccount.currentTenant.id, uuid, newFile);
              this.fileService.uploadImageObservable(this.myAccount.currentTenant.id, uuid, newFile).toPromise().then(
                (res) => {
                  this.newSnackbar(`File ${nomefile} creato.`, 'success');
                  this.loading = false;
                  let canModify = [];
                  let canView = [];
                  let tags = [];
                  tags.push(this.emergencyId);

                  const body = {
                    repositoryId: uuid,
                    canView: canView,
                    canModify: canModify,
                    tags: tags
                  }

                  this.fileService.updateInfo(body)
                    .subscribe(res => {

                    }, (err: any) => {
                      console.log('Error: ', err);
                      this.snackBarMat.open('Errore inserimento tag emergenza', '',
                        {
                          duration: 3000,
                          panelClass: 'warn'
                        });
                    },
                      async () => {

                      }); // end of subscribe;);
                },
                (error) => {
                  this.loading = false;
                  this.newSnackbar(`Errore '${error.statusText}' nella creazione del file ${nomefile} .`, 'warn', 4000);
                }
              );
            },
            (errNote) => {
              this.loading = false;
              // this.newSnackbar(`Errore creazione nota chiusura.`, 'warn');
              console.log('errNote: ', errNote);
            }
          );

          // const nomefile = this.emergencyId + '.png';
          // const newFile = this.blobToFile(blob, nomefile);
          // MS this.saveImage(newFile);
          // MS to remove this.fileService.uploadImage(this.myAccount.currentTenant.id, uuid, newFile);
        });
      },
        (error) => {
          this.loading = false;
          console.log('error: ', error);
        }
      );

  }

  askForFileName(_formBuilder, fileService: FileService, emergencyId): Promise<any> {

    return new Promise((resolve, reject) => {

      const myFormGroup = _formBuilder.group({
        note: ['', Validators.required],
      });

      const askForFileNameCallback = (result) => {
        if (result && result.data) {
          // console.log(result.data);
          resolve(result);
        }

      };


      const obj : DynamicFormDataInput = {
        topHeader: 'Inserisci il nome del file',
        header: 'Inserisci',
        button: 'Inserisci',
        elements: [
          { 
            type: 'TEXT', placeholder: 'Nome File', 
            icon: '', formControlName: 'note' , required: true
          }
        ],
        // selects: null,
        formGroup: myFormGroup,
        disableClose: true
      };

      this.newDynamicDialog(obj, askForFileNameCallback);
      // this.newDialog(obj, askForFileNameCallback);



    });  // endof promise
  }


  saveImage(file) {

    const repoName = this.myAccount.currentTenant.id;
    const parentUuid = this.myAccount.currentTenant.id;

    // LA ROOT DIR HA ID : parentUuid UGUALE AL  TENANTID
    const repoObj = { parentUuid: parentUuid, repoName: repoName };
    //const currentDayDir = new Date().getDay() + "-" + new Date().getMonth() + "-" + new Date().getFullYear();
    const currentDayDir = moment().format('DD-MM-YYYY');
    const subDir = this.emergencyId;

    this.checkDir(repoObj, currentDayDir).then((res: any) => {
      console.log('DIR ', res);

      if (res == undefined || res == null) {
        this.createDir(repoObj, currentDayDir).then((response: any) => {
          this.fileService.uploadImage(repoName, response.payload.repositoryId, file);
          console.log('DIR CREATA', response);
        });
      } else {
        console.log('DIR Esistente', currentDayDir);
        this.fileService.uploadImage(repoName, res.repositoryId, file);
      }

    });

  }

  checkDir(repoObj, newFolder) {

    return new Promise((resolve, reject) => {
      let dirEsistente = false;
      let dataDir: any;
      this.fileService.getRepository(repoObj).subscribe((data: any) => {
        for (let indice = 0; indice < data.payload.length; indice++) {
          if (data.payload[indice].fileType === "FOLDER" && data.payload[indice].fileName === newFolder) {
            dirEsistente = true;
            dataDir = data.payload[indice];
            // console.log("DIRECTORY ESISTENTE",dataDir);
          }
        }
        resolve(dataDir);
      });
    });
  }

  createDir(repoObj, newFolder) {
    return new Promise((resolve, reject) => {
      let dataDir: any;
      let obj = { folderName: newFolder, repoName: this.myAccount.currentTenant.id };
      this.fileService.newFolder(obj).subscribe((data: any) => {
        console.log("DIRECTORY CREATA", data);
        dataDir = data;
        resolve(dataDir);
      }, (error) => {
        console.log(error);
      });
    });
  }

  // IN VIA DI SVILUPPO *******************************


  drawImageOnCanvas() {
    const ctx = this.getContext();
    if (this.isFreezed) {
      this.isFreezed = false;
      this.clearCanvas();
      this.emit('stopfreezeImage', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': false }) });
    } else {
      this.isFreezed = true;
      // this.isFingerEnable = false;
      // this.isTrackable = false;
      // this.isDrawEnable = false;
      this.emit('freezeImage', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });
      this.clearCanvas();
      this.lastSnapShoot = this.getFrame();
      ctx.putImageData(this.lastSnapShoot, 0, 0);
    }
  }


  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    //theBlob.lastModifiedDate = new Date();
    const file = new File([theBlob], fileName);
    //theBlob.name = fileName;
    return file;
  }


  //  base64ToFile(base64Data, tempfilename, contentType) {
  //   contentType = contentType || '';
  //   var sliceSize = 1024;
  //   var byteCharacters = atob(base64Data);
  //   var bytesLength = byteCharacters.length;
  //   var slicesCount = Math.ceil(bytesLength / sliceSize);
  //   var byteArrays = new Array(slicesCount);

  //   for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
  //       var begin = sliceIndex * sliceSize;
  //       var end = Math.min(begin + sliceSize, bytesLength);

  //       var bytes = new Array(end - begin);
  //       for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
  //           bytes[i] = byteCharacters[offset].charCodeAt(0);
  //       }
  //       byteArrays[sliceIndex] = new Uint8Array(bytes);
  //   }
  //   var file = new File(byteArrays, tempfilename, { type: contentType });
  //   return file;
  // }

  eraseDraw() {
    const ctx = this.getContext();
    ctx.putImageData(this.lastSnapShoot, 0, 0);
    this.emit('eraseDraw', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });

  }

  clearCanvas() {
    const ctx = this.getContext();
    ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
  }


  // getImage() {
  //   if (this.canvasElement == undefined ||  this.canvasElement == null) 
  //    return;

  //   const ctx = this.canvasElement.getContext('2d');
  //   ctx.putImageData(this.getFrame() , 0, 0);
  // }



  videoDimensions(video) {
    // Ratio of the video's intrisic dimensions
    const videoRatio = video.videoWidth / video.videoHeight;
    // The width and height of the video element
    let width = video.offsetWidth, height = video.
      offsetHeight;
    // The ratio of the element's width to its height
    const elementRatio = width / height;
    // If the video element is short and wide
    if (elementRatio > videoRatio) { width = height * videoRatio; }
    // It must be tall and thin, or exactly equal to the original ratio
    else { height = width / videoRatio; }

    return {
      width: width,
      height: height
    };
  }



  enableTracking() {
    const ctx = this.getContext();
    this.initCanvas();

    ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    if (this.isTrackable) {
      this.isTrackable = false;
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.emit('stopRemoteTracking', { 'to': this.contactId });
    } else {
      this.isTrackable = true;
      this.isFingerEnable = false;
      this.isDrawEnable = false;
      this.isFreezed = false;

      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.emit('enableRemoteTracking', { 'to': this.contactId });

    }

  }


  enableRemoteFinger() {
    // if (this.islogactive) console.log('enableDraw');


    const ctx = this.getContext();
    this.initCanvas();


    if (this.isFingerEnable) {
      this.isFingerEnable = false;
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.emit('stopRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });
      clearInterval(this.fingerInterval);
    }
    else {
      this.isFingerEnable = true;
      this.isDrawEnable = false;
      this.isTrackable = false;
      this.isFreezed = false;
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.emit('enableRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });
    }

  }

  getContext() {
    // if (this.islogactive) console.log('enableDraw');
    let ctx;
    if (this.canvasElement == undefined || this.canvasElement == null) {
      this.initCanvas();
      if (this.canvasElement != undefined && this.canvasElement != null)
        ctx = this.canvasElement.getContext('2d');
    }
    else {
      ctx = this.canvasElement.getContext('2d');
    }
    return ctx;
  }

  getCanvasToRecordContext() {
    // if (this.islogactive) console.log('enableDraw');
    let ctx;
    if (this.canvasElement == undefined || this.canvasElement == null) {
      this.initCanvas();
      if (this.canvasElement != undefined && this.canvasElement != null)
        ctx = this.canvasElement.getContext('2d');
    }
    else {
      ctx = this.canvasElement.getContext('2d');
    }
    return ctx;
  }


  initCanvastoRecord() {
    // clear all variables 
    this.drawingCoordinates.length = 0;
  }


  enableDraw() {

    const ctx = this.getContext();
    this.initCanvas();

    if (!this.isFreezed)
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    if (this.isDrawEnable) {
      this.isDrawEnable = false;
      this.emit('enableDraw', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': false }) });
    }
    else {
      this.isDrawEnable = true;
      this.isFingerEnable = false;
      this.isTrackable = false;
      this.isFreezed = false;

      this.emit('enableDraw', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });
    }

    this.initCanvastoRecord();

  }

  enableRemoteScreenShare() {

    if (this.isShare) {
      this.emit('remoteScreenShare', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': false }) });
    }
    else {
      this.emit('remoteScreenShare', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });
    }

  }

  handleMouseUp() {
    this.effettuaOperazioniSulCanvasSulMouseUp();
  }

  effettuaOperazioniSulCanvasSulMouseUp() {

    const ctx = this.getContext();


    // if (this.islogactive) console.log('finale', { 'to': this.fromId, 'customData': JSON.stringify({ 'coordinate': JSON.stringify(this.coordinates),  'videoDim': this.videoDim})} );
    if (this.isDrawEnable) {
      this.emit('sendDraw', { 'to': this.contactId, 'customData': JSON.stringify({ 'coordinate': this.coordinates, 'videoDim': this.videoDim, 'brushsize': this.brushSize }) });
      // this.drawing.emit({ 'coordinates': this.coordinates, 'videoDim': this.videoDim, 'brushsize': this.brushSize });
      this.drawingCoordsEvent.emit({ 'coordinates': this.coordinates, 'videoDim': this.videoDim, 'brushsize': this.brushSize });
      this.coordinates.length = 0;
    } else if (this.isFingerEnable) {
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.emit('stopRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'isEnable': true }) });
      clearInterval(this.fingerInterval);

    } else if (this.isTrackable) {
      this.attivaTrackerAI();
    }
    this.pressed = false;
    this.canvasElement.style.cursor = 'default';

  }

  attivaTrackerAI() {

    this.tracker = objectTracker.init(this.getFrame(), [this.lastX, this.lastY, this.rec_width, this.rec_height]);
    this.drawObjectOnTracking();

    // RESET SCHEDULER BEFORE TO RESTART WITH NEW
    clearInterval(this.tracketIntervall);

    this.tracketIntervall = setInterval(() => {
      if (this.isTrackable) {
        this.drawObjectOnTracking();
      } else {
        if (this.tracketIntervall !== undefined && this.tracketIntervall != null) {
          clearInterval(this.tracketIntervall);
          this.tracketIntervall = null;
          this.tracker = null;
          this.emit('stopRemoteTracking', { 'to': this.contactId });
        }
      }
    }, 200);

  }

  async drawObjectOnTracking() {
    if (this.tracker != undefined && this.tracker != null) {
      const box = await this.tracker.next(this.getFrame());
      const ctx = this.getContext();
      ctx.beginPath();
      const currentX = box[0];
      const currentY = box[1];
      const rect_width = box[2];
      const rect_height = box[3];
      ctx.rect(currentX, currentY, rect_width, rect_height);
      ctx.stroke();

      this.emit('sendRemoteTracking', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': currentX, 'y': currentY, 'rectWidth': rect_width, 'rectHeight': rect_height, 'videoDim': this.videoDim }) });
    }


  }


  handleMouseDown(ev) {

    // this.initCanvas();
    if (this.canvasElement == undefined || this.canvasElement == null)
      return;

    this.pressed = true;
    if (this.isDrawEnable) {
      const ctx = this.canvasElement.getContext('2d');
      if (!this.isFreezed)
        ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.lastX = ev.layerX;
      this.lastY = ev.layerY;
      const c = { x: null, y: null };
      c.x = this.lastX;
      c.y = this.lastY;
      this.coordinates.push(c);
      // this.lastCoordinates = c;
      // this.drawing.emit({ 'coordinate': c, 'videoDim': this.videoDim, 'brushsize': this.brushSize });


    } else if (this.isFingerEnable) {
      const x = ev.layerX;
      const y = ev.layerY;

      const c = { x: null, y: null };
      c.x = x;
      c.y = y;
      this.drawing.emit({ 'coordinate': c, 'videoDim': this.videoDim, 'brushsize': this.brushSize });
      // this.lastCoordinates = c;

      this.emit('sendRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': x, 'y': y, 'videoDim': this.videoDim }) });
      this.fingerInterval = setInterval(() => {
        // console.log("INVIO");
        this.sendFinger = true;
      }, 1 * 150);
    } else if (this.isTrackable) {

      if (this.tracker != undefined && this.tracker != null) {
        this.tracker = null;
        this.emit('stopRemoteTracking', { 'to': this.contactId });
      }

      this.lastX = ev.layerX;
      this.lastY = ev.layerY;
    } else {
      this.lastX = ev.layerX;
      this.lastY = ev.layerY;
    }
  }

  handleMouseMove(ev) {


    const ctx = this.getContext();
    const video = document.getElementById('localVideo');

    // DISEGNA ED INVIA I DATI PER IL DRAW ON CAVAS
    if (this.pressed && this.isDrawEnable && !this.isFingerEnable) {
      // posizioni correnti
      const currentX = ev.layerX;
      const currentY = ev.layerY;

      const c = { x: null, y: null };
      c.x = currentX;
      c.y = currentY;
      this.coordinates.push(c); // metti nel vettore le coordinate volta per volta
      // this.drawing.emit({ 'coordinate': c, 'videoDim': this.videoDim, 'brushsize': this.brushSize });

      // disegna
      ctx.beginPath();
      ctx.lineJoin = 'round';
      ctx.moveTo(this.lastX, this.lastY);
      ctx.lineTo(currentX, currentY);
      ctx.closePath();
      ctx.strokeStyle = this.currentColour;
      ctx.lineWidth = this.brushSize;
      ctx.stroke();

      this.lastX = currentX;
      this.lastY = currentY;
      // GESTIONE DEL REMOTE FINGER
    } else if (this.pressed && !this.isDrawEnable && this.isFingerEnable) {

      // this.canvasElement.style.cursor = 'crosshair';
      ctx.globalCompositeOperation = 'source-over';
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

      const r = 25;

      const currentX = ev.layerX;
      const currentY = ev.layerY;

      const c = { x: null, y: null };
      c.x = currentX;
      c.y = currentY;
      // this.coordinates.push(c); // metti nel vettore le coordinate volta per volta
      this.drawing.emit({ 'coordinate': c, 'videoDim': this.videoDim, 'brushsize': this.brushSize });

      ctx.strokeStyle = '#000';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.arc(currentX, currentY, r, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.stroke();


      // const timecontrol = setTimeout(() => {
      //   this.emit('sendRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': currentX, 'y': currentY, 'videoDim': this.videoDim }) });
      // }, 150);

      // if (this.fingerPosY !== currentY || this.currentX !== currentX) {
      //   this.fingerPosY = currentY;
      //   this.currentX = currentX;
      //   clearTimeout(timecontrol);
      // }

      if (this.sendFinger) {
        this.emit('sendRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': currentX, 'y': currentY, 'videoDim': this.videoDim }) });
        this.sendFinger = false;
      }




      //  const timecontrol = setTimeout(() => {
      //   this.emit('sendRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': currentX, 'y': currentY, 'videoDim': this.videoDim }) });
      // }, 150);

      // if (this.fingerPosY !== currentY || this.currentX !== currentX) {
      //   this.fingerPosY = currentY;
      //   this.currentX = currentX;
      //   clearTimeout(timecontrol);
      // }



      // SE MI MUOVO PROVO AD AGGIORNARE LAPOSIZIONE IN TEMPO REALE



      //   if (this.fingerPosY == currentY || this.currentX == currentX) {
      //     this.fingerPosY = currentY;
      //   this.fingerPosX = currentX;
      //   clearTimeout(this.fingerInterval);
      //  } else{

      //   const timecontrol = setTimeout(() => {
      //     this.emit('sendRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': currentX, 'y': currentY, 'videoDim': this.videoDim }) });
      //   }, 150);

      //   // if (this.fingerPosY !== currentY || this.currentX !== currentX) {
      //   //   this.fingerPosY = currentY;
      //   //   this.currentX = currentX;
      //   //   clearTimeout(timecontrol);
      //   // }

      //  }






      // this.fingerInterval = setInterval(() => {
      //   this.sendFinger = true;
      // }, 1 * 2000);

      // if ( (this.fingerPosY  >=  currentY - 100 && this.fingerPosY <=  currentY + 100)|| (this.fingerPosX >=  currentX - 100 && this.fingerPosX <=  currentX + 100)) {
      //   this.fingerPosY = currentY;
      //   this.fingerPosX = currentX;
      //   clearTimeout(this.fingerInterval);
      //   this.sendFinger = false;
      // }


      // if (this.sendFinger)
      // {
      //     this.emit('sendRemoteFinger', { 'to': this.contactId, 'customData': JSON.stringify({ 'x': currentX, 'y': currentY, 'videoDim': this.videoDim }) });
      //     // clearInterval(this.fingerInterval);
      //     this.sendFinger = false;


      // }





      // if ( (this.fingerPosY  <=  currentY - 100 && this.fingerPosY >=  currentY + 100)|| (this.fingerPosX <=  currentX - 100 && this.fingerPosX >=  currentX + 100)) {
      //   this.fingerPosY = currentY;
      //   this.fingerPosX = currentX;
      //   clearTimeout(timecontrol);
      // }



    } else if (this.pressed && this.isTrackable) {
      const currentX = ev.layerX;
      const currentY = ev.layerY;
      ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      ctx.beginPath();
      this.rec_width = currentX - this.lastX;
      this.rec_height = currentY - this.lastY;
      ctx.rect(this.lastX, this.lastY, this.rec_width, this.rec_height);
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 2;
      ctx.stroke();
    }

  }



  actionOnClickvideo(event, element, indice?) {
    // QUESTA FUNZIONE CONSENTE DI POTER RITORNARE SULLA PAGINA PRINCIAPLE DELLA VIDEO CHIAMATA

    if ((this.isDrawEnable || this.isTrackable) && !this.isCollapsed)
      return;

    if (this.isCollapsed) {
      if (!this.dragging)
        this.router.navigateByUrl('/admin/emergencyNotifications');
      this.dragging = false;
    } else {
      switch (element) {
        case 'videME':


          this.hideCanvas();
          this.disableButtonAR();


          this.videoMeContainerClass = this.videoPrimaryContainerClass;
          this.videoMeClass = this.videoPrimaryClass;



          // this.roscamListClass='videoSecondary videRosCam';


          if (this.lastIndiceClickedCam >= 0) {
            this.videoRosCamListClass[this.lastIndiceClickedCam] = this.videoRosCamListClassPRE[this.lastIndiceClickedCam];
            this.rosCamContainerClass[this.lastIndiceClickedCam] = 'videoSecondary videRosCam  video-content video  ';
          } else {
            this.videoRemoteContainerClass = this.videoSecondaryContainerClass;
            this.videoRemoteClass = this.videoSecondaryClass;
          }
          // this.rosCamContainerClass =  'videoSecondary   videRosCam  video-content video  ';

          this.lastIndiceClickedCam = -1;

          break;

        case 'videoRemote':

          // QUESTO CONTROLLO SERVE AD EVIATRE CHE SE STAI SUL PRIMARIO E STAI DISEGNANDO DI CANCELLA IL CANVAS 
          // e cmq significa che non sto in videoSecondary. Bisognorebbe inserireuna variable per il controllo per capire 
          // SE il video remoote e sul primario o n
          // if (this.isDrawEnable || this.isFingerEnable || this.isTrackable)
          //   return;

          if (this.videoRemoteContainerClass == this.videoPrimaryContainerClass)
            return;


          this.videoRemoteContainerClass = this.videoPrimaryContainerClass;
          this.videoRemoteClass = this.videoPrimaryClass;

          this.showCanvas();
          this.enableButtonAR();



          // this.roscamListClass='videoSecondary videRosCam';

          if (this.lastIndiceClickedCam >= 0) {
            this.videoRosCamListClass[this.lastIndiceClickedCam] = this.videoRosCamListClassPRE[this.lastIndiceClickedCam];
            this.rosCamContainerClass[this.lastIndiceClickedCam] = 'videoSecondary videRosCam  video-content video  ';
          } else {
            this.videoMeContainerClass = this.videoSecondaryContainerClass;
            this.videoMeClass = this.videoSecondaryClass;
          }

          // this.rosCamContainerClass =  'videoSecondary   videRosCam  video-content video  ';

          this.lastIndiceClickedCam = -1;


          break;
        case 'rosCamList':
          this.hideCanvas();
          this.disableButtonAR();


          this.rosCamContainerClass[indice] = 'videoPrimary  halfspace video  ';
          this.videoRosCamListClass[indice] = 'videoPrimaryInner rosCam_' + this.rosCamList[indice].value;

          console.log("Resetta classi secondario ", this.lastIndiceClickedCam, this.videoRosCamListClassPRE[this.lastIndiceClickedCam])
          console.log("Resetta classi primario ", indice, this.rosCamContainerClass[this.lastIndiceClickedCam])


          if (this.lastIndiceClickedCam >= 0) {
            this.videoRosCamListClass[this.lastIndiceClickedCam] = this.videoRosCamListClassPRE[this.lastIndiceClickedCam];
            this.rosCamContainerClass[this.lastIndiceClickedCam] = 'videoSecondary videRosCam  video-content video  ';
          } else {
            this.videoRemoteContainerClass = this.videoSecondaryContainerClass;
            this.videoRemoteClass = this.videoSecondaryClass;

            this.videoMeContainerClass = this.videoSecondaryContainerClass;
            this.videoMeClass = this.videoSecondaryClass;
          }

          this.videoRosCamListClassPRE = this.videoRosCamListClass;


          this.lastIndiceClickedCam = indice;

          break;

        default:
          break;
      }

    }
  }

  openChat() {
    // this.notificationService.processingEmergency(this.emergencyId, {type: 'CHAT'}).subscribe((res) => {
    //   this.eventInteractionService.publishData({event:'OPENCHAT', contactId: this.contactId, emergencyId: this.emergencyId})
    // });
    this.emergenciesService.tryToUpdateEmergency(this.emergencyId, 'request', 'CHAT', (res) => {
      this.eventInteractionService.publishData({ event: 'OPENCHAT', contactId: this.contactId, emergencyId: this.emergencyId })
    })
  }

  hideChat() {
    this.emit('hideChat', { 'to': this.contactId });
  }




  handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }

  elementDragged = []
  onDragEnded(event: CdkDragEnd): void {
    this.elementDragged.push(event.source)
    // event.source._dragRef.reset();  
  }


}
