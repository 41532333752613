import { Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ConfigurationService } from 'app/main/admin/services/configuration.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AuthService } from 'app/main/shared/services/auth.service';
import { UserService } from 'app/main/admin/services/user.service';
import { AccountService } from '../services/account.service';
import { PreviewUsefulTipsComponent } from '../preview-useful-tips/preview-useful-tips.component';

@Component({
  selector: 'useful-tips',
  templateUrl: './useful-tips.component.html',
  styleUrls: ['./useful-tips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsefulTipsComponent implements OnInit, AfterViewInit {

  public maxLength = 2000;
  public rteValue: string;
  public textArea: HTMLElement;
  appConfig: any;
  htmlContent = '';
  data;
  appConfigService: Observable<any>;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '40rem',
    minHeight: '16rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private snackBarMat: MatSnackBar,
    private configurationService: ConfigurationService,
    private location: Location,
    private _auth: AuthService,
    private _account: AccountService,
    private dialog: MatDialog ) {

  }

  async ngOnInit() {

    if (this._auth.loggedIn) {
      await this._account.getAccount().then((res: any) => {
        this.data = res;
        console.log('User: ', res);

        if (res.ruolo == 'AMMINISTRATORE') {
          this.configurationService.getAppPlatformConfig().subscribe((res: any) => {
            this.appConfig = res.payload;
            this.htmlContent = res.payload.platformUsefulTips;
          });
        } else if (res.ruolo == 'TENANT_ADMINISTRATOR') {
          this.configurationService.getAppConfig().subscribe((res: any) => {
            this.appConfig = res.payload;
            this.htmlContent = res.payload.usefulTips;
          });
        } else {
          this.snackBarMat.open('Il ruolo attuale non consente la gestione dei consigli utili', '', {
            duration: 3000,
            panelClass: 'warn',
          });
          return;
        }

        /*
        this.configurationService.getAppConfig().subscribe((res: any) => {
          this.appConfig = res.payload;
          this.htmlContent = res.payload.usefulTips;
        });
        */
        /*
        this.appConfigService.subscribe((res: any) => {
          this.appConfig = res.payload;
          this.htmlContent = res.payload.usefulTips;
        });
        */

      });
      /*
            this.configurationService.getAppConfig().subscribe((res: any) => {
              this.appConfig = res.payload;
              this.htmlContent = res.payload.usefulTips;
            });
          */
    }
  }

  public onCancel = () => {
    this.location.back();
  }

  public onPreview = () => {
    let data = null;
     
    // this.location.back();
    const dialog = this.dialog.open(PreviewUsefulTipsComponent, {
      // width: '400px',
      panelClass: 'phone-class',
      data: {
        usefulTips: this.htmlContent,
        title: 'Consigli Utili',
        body: '',
        buttons: [
          {
            text: 'OK',
            close: true,
            value: true,
            callback: () => {
              
            }
          }
        ]
      }
    });

    dialog.afterClosed().subscribe((datas: any) => {
      if (datas) {
        // callback(datas);
      }
    });
    
  }
  

  onChange(event) {
    console.log('changed');
  }

  onBlur(event) {
    console.log('blur ' + event);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let element = document.getElementById('foregroundColorPicker');
      console.log('element:', element);
    }, 1000);

  }

  onUsefulTipsSave() {
    if (this.appConfig) {
      console.log('value: ', this.htmlContent);
      //this.appConfig.usefulTips = this.htmlContent; // ;this.textArea.innerHTML;
      // this.appConfig.usefulTips = this.htmlContent; // ;this.textArea.innerHTML;
      // const appConfigToSave = this.appConfig;

      if (this.data.ruolo == 'AMMINISTRATORE') {
        this.appConfig.platformUsefulTips = this.htmlContent; // ;this.textArea.innerHTML;
        this.configurationService.saveAppPlafformConfig(this.appConfig).subscribe((res) => {

          this.snackBarMat.open('Consigli utili salvati con successo', '', {
            duration: 3000,
            panelClass: 'success',
          });
        }
          , (error) => {
            this.snackBarMat.open('Error salvataggio consigli utili', '', {
              duration: 3000,
              panelClass: 'warn',
            });
          });
      }

      if (this.data.ruolo == 'TENANT_ADMINISTRATOR') {
        this.appConfig.usefulTips = this.htmlContent; // ;this.textArea.innerHTML;
        this.configurationService.saveAppConfig(this.appConfig).subscribe((res) => {

          this.snackBarMat.open('Consigli utili salvati con successo', '', {
            duration: 3000,
            panelClass: 'success',
          });
        }
          , (error) => {
            this.snackBarMat.open('Error salvataggio consigli utili', '', {
              duration: 3000,
              panelClass: 'warn',
            });
          });
      }

    }
  }


}