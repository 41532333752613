import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { navigation } from 'app/navigation/navigation';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  navigation: FuseNavigation[];

  constructor(private router: Router) {
    this.init();
   }

   init() {
    this.navigation = navigation;
   }

   checkCurrent(nav?: FuseNavigation[] | FuseNavigationItem[]): FuseNavigationItem | null {
     if(!nav)
      nav = this.navigation;
      if(nav) {
        for (let index = 0; index < nav.length; index++) {
          const element = nav[index];
          if(element.url && element.type == 'item' && this.router.isActive(element.url, false)) {
            return element;
          }
          if(element.children) {
            let el = this.checkCurrent(element.children);
            if(el)
              return el;
          }
        }
      }
    
      return null;
   }

}
