import { Injectable } from '@angular/core';
import { AccountService } from '../services/account.service';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {


  constructor(private account: AccountService, private router: Router) {}

  canActivate(): boolean {
    if (!this.account.isAdmin()) {
      this.router.navigate(['/notifications']);
    }
    return this.account.isAdmin();
  }
}
