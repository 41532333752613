import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-elimina-segnalazione',
  templateUrl: './elimina-segnalazione.component.html',
  styleUrls: ['./elimina-segnalazione.component.scss']
})
export class EliminazioneSegnalazioneComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EliminazioneSegnalazioneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  // On delete
  onElimina() {
    this.dialogRef.close({
      data: true
    });
  }

}

