import { Component, OnDestroy, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { Router, NavigationExtras } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { NotificationService } from 'app/main/services/notification.service';
import { ProfileComponent } from 'app/main/shared/profile/profile.component';
import { AccountService } from 'app/main/shared/services/account.service';
import { AuthService } from 'app/main/shared/services/auth.service';
import { ErrorHandlerService } from 'app/main/shared/services/error-handler.service';
import { TokenService } from 'app/main/shared/services/token.service';
import { navigation } from 'app/navigation/navigation';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { QrCodeComponent } from 'app/main/shared/alert/qr-code/qr-code.component';
import { WebsocketService } from 'app/main/shared/services/websocket.service';
import { VideocallEventInteractionService } from 'app/main/apps/videocall/eventWebSocket.service';
import { EventWebSocketService } from 'app/main/shared/services/event-web-socket.service';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { EventInteractionService } from 'app/main/shared/services/event-interaction.service';
import { FormBuilder } from '@angular/forms';
import { StorageService } from 'app/main/shared/services/storage.service';
import { UserService } from 'app/main/admin/services/user.service';
import { FuseNavigationItem } from '@fuse/types';



@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent extends AdminUtilComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    emergencyNotificationsNumber: any;
    notificationsNumber: any;
    data: any;
    banca: any;

    subPollingSession: Subscription;
    pollingInterval: any = null;
    subNotifications: Subscription;
    subEmergencyNotificationsInterval: any = null;

    // Private
    private _unsubscribeAll: Subject<any>;
    veis: Subscription;
    videoCallContactId: any;
    videoCallEmergencyId: any;
    showVideoCall: boolean = false;
    operators: any[] = [];
    myUserUuid: any;
    videoCallOperatorContactId: any;
    ewss: Subscription;
    videoCallOperatorIsAnswer: string;
    callId: any;
    audio: HTMLAudioElement;
    videoCallId: any;
    callingTimeout: any;
    isCalling: boolean = false;
    callDialog: MatDialogRef<any>;
    isOperator: boolean;
    public isOperatorClickingOnMenu: boolean;
    subEmergencyNotifications: Subscription;
    subNotificationsInterval: any = null;
    notifications: [];
    eis: Subscription;
    videoCallSocketSessionId: any;
    notificationServiceClickedSubscription: Subscription;
    rolesList: any;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private snackBarMat: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private router: Router,
        private token: TokenService,
        private auth: AuthService,
        private notificationService: NotificationService,
        private errorHandler: ErrorHandlerService,
        public dialog: MatDialog,
        private accountService: AccountService,
        private _fuseNavigationService: FuseNavigationService,
        private webSocketService: WebsocketService,
        private videocallEventInteractionService: VideocallEventInteractionService,
        private eventWebSocketService: EventWebSocketService,
        private eventInteractionService: EventInteractionService,
        private _formBuilder: FormBuilder,
        private storageService: StorageService,
        private userService: UserService,
        injector: Injector
    ) {
        super(dialog, snackBarMat, injector);
        this.operators = [];
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#f34336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        //this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit() {
        try {

            if (this.auth.loggedIn()) {
                this.accountService.getAccount().then((account) => {
                    // console.log(account);
                    this.myUserUuid = account.userId;
                })
                this.isOperator = false;
                if (this.accountService.isOperator()) {
                    this.isOperator = true;
                    this.checkAndClean(this.subEmergencyNotifications);
                    this.subEmergencyNotifications = this.notificationService.onEmergencyNotificationsValueChange.subscribe(notificationsNumber => {
                        if (notificationsNumber !== null && notificationsNumber !== false) {
                            this.notificationService.countEmergencyNotfications('OPEN, PROCESSING').subscribe((res: any) => {
                                if (res.payload) {
                                    this.emergencyNotificationsNumber = res.payload.count;
                                }
                            });
                        }
                    });

                    this.notificationService.getOperators().subscribe((res: any) => {
                        // console.log(res)
                        if (res.payload) {
                            this.mergeOperators(res.payload)
                        }
                    });


                    const tempoDiPollingInMillisecondi = 20000;
                    if (this.subEmergencyNotificationsInterval !== null) {
                        clearInterval(this.subEmergencyNotificationsInterval);
                    }
                    this.subEmergencyNotificationsInterval = setInterval(async () => {
                        // const func = await this.notificationService.countEmergencyNotfications('OPEN, PROCESSING');
                        // if (func) {
                        //     func.subscribe((res: any) => {
                        //         if (res.payload) {
                        //             this.emergencyNotificationsNumber = res.payload.count;

                        //             this.notificationService.clicked.subscribe(item => {
                        //                 this.isOperatorClickingOnMenu=item;
                        //             });

                        //             // console.log("isOperatorClickingOnMenu",this.isOperatorClickingOnMenu);

                        //             // QUESTO ESCAMOTAGE MI PERMETTE DI TENERE IL REFRESH ALTO E NON INFICIARE L'USABILITA' DELLA PAGINA QUANDO SI REFRESHA 
                        //             if (!this.isOperatorClickingOnMenu)
                        //             {
                        //                 this.notificationService.updateEmergencyNotificationsValue2(true);
                        //                 this.notificationService.updateEmergencyNotificationsValue3(true);
                        //             }
                        //         }
                        //     });
                        // }
                        this.updateEmergencies();

                        // const func1 = await this.notificationService.getOperators();
                        // if (func1) {
                        //     func1.subscribe((res: any) => {
                        //         // console.log(res)
                        //         if (res.payload) {
                        //             //this.operators = res.payload;
                        //             this.mergeOperators(res.payload)
                        //         }
                        //     });
                        // }


                    }, tempoDiPollingInMillisecondi);

                    if (this.subNotificationsInterval !== null) {
                        clearInterval(this.subEmergencyNotificationsInterval);
                    }

                    this.subNotificationsInterval = setInterval(async () => {
                        // const func = await this.notificationService.getNotfications()
                        // if (func) {
                        //     func.subscribe((res: any) => {
                        //         if (res.payload) {
                        //             // console.log(res);
                        //             this.notifications = res.payload;
                        //             if(this.notifications != undefined && this.notifications != null)
                        //                 this.notificationsNumber = this.notifications.length;
                        //         }
                        //     });
                        // }
                        this.getNotifications();
                        this.webSocketService.checkService();
                    }, tempoDiPollingInMillisecondi);

                    this.updateEmergencies();
                    this.getNotifications();
                }

                this.checkAndClean(this.subPollingSession);
                this.subPollingSession = this.auth.refresh().subscribe();


                this.pollingInterval = setInterval(() => {
                    if (this.auth.loggedIn()) {
                        this.subPollingSession.unsubscribe();
                        this.subPollingSession = this.auth.refresh().subscribe();

                    }
                }, 60 * 1000);

                this.webSocketService.initService();
            }
            // Subscribe to the config changes
            await this._fuseConfigService.config
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((settings) => {
                    this.horizontalNavbar = settings.layout.navbar.position === 'top';
                    this.rightNavbar = settings.layout.navbar.position === 'right';
                    this.hiddenNavbar = settings.layout.navbar.hidden === true;
                });
        } catch (ex) {
            // console.log(ex);
        }
        if (this.auth.loggedIn()) {
            this.auth.getAuthMap().subscribe((res: any) => {
                this.accountService.setAuths(res.payload);
                this._fuseNavigationService.triggerNavigationItemUpdated();
                this.updateQRCode();



                if (res.payload.active == false || res.payload.tenantActive == false) {

                    //VERIFICO SE é IN CORSO IL PAGAMENTO DELLA LICENA ALTRIMENTI VUOL DIRE CHE
                    // LA LIC E? SCADUTA E DEVE VEDERE SOLO LA PAGINA DELLE LIC.
                    let info = this.storageService.get('LICENSE_KEY');
                    if (info == undefined || info == null || info == '')
                        this.router.navigateByUrl('/admin/buy-licenses');
                }
            }, (err) => {
                this.accountService.setAuths({ methodsAuths: [] });
                this._fuseNavigationService.triggerNavigationItemUpdated();
                this.updateQRCode();
            });
        }
        this.checkAndClean(this.veis);
        //
        let parent: any = this._fuseNavigationService.getNavigationItem('gestutenti');
        parent.children = [];
        this._fuseNavigationService.triggerNavigationItemUpdated();

        //aggiunge dinamicamente al menu, una funzione per ogni ruolo per la gestione degl utenti.
        await this.userService.getUserRoleList().toPromise().then((resp: any) => {
            this.rolesList = resp.payload;
            for (let index = 0; index < this.rolesList.length; index++) {
                const element = this.rolesList[index];

                let item: FuseNavigationItem =
                {
                    id: element.role,
                    title: element.roleDescription ? element.roleDescription : element.role,
                    type: 'item',
                    icon: 'contact',
                    url: '/admin/utenti/' + element.role,
                    checkAdmin: true,
                    bemethod: 'UserService_getUsersList'
                };

                this._fuseNavigationService.addNavigationItem(item, 'gestutenti');
                this._fuseNavigationService.triggerNavigationItemUpdated();
            }
        }).catch((error) => {
            this.loading = false;
            console.log(error);
            return;
        });

        //
        this.eis = this.eventInteractionService.getObservable().subscribe(this.eventInteractionServiceCallback.bind(this))
        this.veis = this.videocallEventInteractionService.getObservable().subscribe(this.eventInteractionServiceCallback.bind(this));

        this.checkAndClean(this.ewss);
        this.ewss = this.eventWebSocketService.getObservable().subscribe((data) => {
            // console.log('eventWebSocketService ', data);
            this.onSocketMessage(data);
        });
    }

    eventInteractionServiceCallback(data) {
        //console.log('Data received', data);

        if (data != undefined && data != null
            && data.event != undefined && data.event != null) {
            switch (data.event) {
                case 'toolbarVideocallStart':
                    this.videoCallContactId = data.contactId;
                    this.videoCallEmergencyId = data.emergencyId;
                    this.videoCallOperatorContactId = null;
                    this.videoCallSocketSessionId = null;
                    this.videoCallOperatorIsAnswer = null;
                    this.videoCallId = null;
                    this.showVideoCall = true;
                    break;
                case 'toolbarVideocallStop':
                    this.videoCallContactId = null;
                    this.videoCallEmergencyId = null;
                    this.videoCallOperatorContactId = null;
                    this.videoCallSocketSessionId = null;
                    this.videoCallOperatorIsAnswer = null;
                    this.videoCallId = null;
                    this.showVideoCall = false;

                    if (this.audio != null) {
                        this.audio.pause();
                        this.audio = null;
                    }
                    if (this.callingTimeout != undefined && this.callingTimeout != null) {
                        clearTimeout(this.callingTimeout);
                        this.callingTimeout = null;
                    }

                    break;
                case 'videocallTimeout':
                    if (this.audio != null) {
                        this.audio.pause();
                        this.audio = null;
                    }
                    this.videoCallContactId = null;
                    this.videoCallEmergencyId = null;
                    this.videoCallOperatorContactId = null;
                    this.videoCallSocketSessionId = null;
                    this.videoCallOperatorIsAnswer = null;
                    this.showVideoCall = false;
                    this.emit('timeout', { callId: this.callId });
                    this.newDialog({
                        topHeader: 'Chiamata senza risposta',
                        header: 'L\'utente non ha risposto alla chiamata.',
                        buttons: [{
                            label: 'OK',
                            color: 'accent',
                            action: () => {
                            },
                            closeDialog: true
                        },
                        /*{
                            label: 'Rifiuta',
                            color: 'danger',
                            action: ()=>{
                                this.emit('reject', { 'toUserId': msg.data.from });
                                this.videoCallContactId = null;
                                this.videoCallOperatorContactId = null;
                                this.videoCallOperatorIsAnswer = null;
                                this.videoCallEmergencyId = null;
                                this.showVideoCall = false;
                            },
                            canClose: true
                        }*/],
                        changePassword: false,
                        textareas: null,
                        selects: null,
                        formGroup: null,
                    }, () => { })
                    break;
                case 'clickOperator':
                    this.clickOperator(data.userId);
                    break;
                case 'askForNotes':
                    this.askForNotes(this._formBuilder, this.notificationService, data.emergencyId)
                        .then(
                            (dataNote) => {
                                this.newSnackbar(`Nota chiusura creata.`, 'success');
                                console.log('errNote: ', dataNote);

                            },
                            (errNote) => {
                                this.newSnackbar(`Errore creazione nota chiusura.`, 'warn');
                                console.log('errNote: ', errNote);
                            }
                        );
                default:
                    break;
            }
        }
    }

    checkAndClean(sub: Subscription) {
        if (sub != undefined && sub != null)
            sub.unsubscribe();
    }
    mergeOperators(payload: []) {
        let toRemove = [];
        for (let index = 0; index < this.operators.length; index++) {
            const element = this.operators[index];
            const found = payload.find((v) => {
                return v['userId'] == element.userId;
            })
            if (found == undefined || found == null) {
                toRemove.push(index);
            } else {
                this.operators[index].status = found['status'];
                this.operators[index].sessionId = found['sessionId'];
            }
        }
        toRemove = toRemove.sort((a, b) => {
            if (a == b)
                return 0;
            if (a < b)
                return -1;
            if (a > b)
                return 1;
        })

        for (let index = toRemove.length - 1; index >= 0; index--) {
            this.operators.splice(toRemove[index], 1);
        }

        for (let index = 0; index < payload.length; index++) {
            if (payload[index]['userId'] == this.myUserUuid)
                continue;
            const found = this.operators.find((v) => {
                return v['userId'] == payload[index]['userId'];
            })
            if (found == undefined || found == null) {
                this.operators.push(payload[index]);
            }
        }
    }

    updateQRCode() {
        //console.log('updateQRCode')
        //console.log(this._fuseNavigationService.getNavigationItem('qrcode'));

        this._fuseNavigationService.updateNavigationItem('moviemasherclient', {
            function: () => {
                this.accountService.getAccount().then((res) => {
                    window.open("https://videosopclient.eclettica.net", "_blank");
                });
            }
        });
        // this._fuseNavigationService.updateNavigationItem('qrcode', {
        //     function: () => {
        //         this.accountService.getAccount().then((res) => {
        //             //console.log(res);
        //             const dialog = this.dialog.open(QrCodeComponent, {
        //                 // data: `${data}`,
        //                 width: '1000px',
        //                 data: {
        //                     title: 'QR Code',
        //                     body: 'Invia questo QR code ai tuoi utenti per consentire loro di associare l\'App alla tua azienda.',
        //                     //qrcode: this.appConfig.mnemonicId,
        //                     qrcode: res.currentTenantMnemonicId,
        //                     buttons: [
        //                         {
        //                             text: 'OK',
        //                             close: true,
        //                             value: true,
        //                             callback: () => {

        //                             }
        //                         }
        //                     ]
        //                 }

        //             });
        //             dialog.afterClosed().toPromise();
        //         });
        //     }
        // });
        //console.log(this._fuseNavigationService.getNavigationItem('qrcode'));

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this.subPollingSession) {
            this.subPollingSession.unsubscribe();
        }
        if (!this.auth.loggedIn()) {
            if (this.pollingInterval !== null) {
                clearInterval(this.pollingInterval);
            }
            if (this.subEmergencyNotificationsInterval !== null) {
                clearInterval(this.subEmergencyNotificationsInterval);
            }
            // if (this.accountService.isOperator()) {
            if (this.subNotifications) {
                this.subNotifications.unsubscribe();
            }
            if (this.subEmergencyNotifications) {
                this.subEmergencyNotifications.unsubscribe();
            }
            this.notificationService.updateEmergencyNotificationsValue(false);
            this.notificationService.updateEmergencyNotificationsValue2(false);
            this.notificationService.updateEmergencyNotificationsValue3(false);
            // }
        }
        if (this.veis != undefined && this.veis != null)
            this.veis.unsubscribe();
        if (this.eis != undefined && this.eis != null)
            this.eis.unsubscribe();
        if (this.ewss != undefined && this.ewss != null)
            this.ewss.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }


    /**
     * Logout
     *
     * @param value
     */
    logout() {
        this.auth.logOut().subscribe();
        this.router.navigate(['/login']);
        //this.router.navigate(['/login'], { queryParams: { returnUrl: '/' } })

        this.accountService.deleteAccount();
        this.webSocketService.checkService();
        return this.token.deleteToken();
    }

    about() {

        this.router.navigate(['/admin/info']);


    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    async openProfile() {
        await this.accountService.getAccount().then((res: any) => {
            this.data = res;
        });
        const dialog = this.dialog.open(ProfileComponent, {
            width: '400px',
            panelClass: 'custom-panel-class',
            disableClose: true,
            data: {
                data: this.accountService.getAccount()
            }
        });
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
    }

    goToEmergencyNotifications() {
        this.router.navigate(['/admin/emergencyNotifications']);
    }

    clickOperator(userId) {
        const op = this.operators.find(x => x.userId == userId);
        this._clickOperator(op);
    }

    _clickOperator(op) {
        if (op == undefined || op == null || this.isCalling)
            return;
        // console.log('click operator', op);
        if (op.sessionId != undefined && op.sessionId != null && op.status == 'ONLINE') {
            this.isCalling = true;
            this.videoCallContactId = null;
            this.videoCallOperatorContactId = op.userId;
            this.videoCallSocketSessionId = op.sessionId;
            this.videoCallOperatorIsAnswer = 'false';
            this.videoCallEmergencyId = null;
            this.videoCallId = null;
            this.showVideoCall = true;
            this.audio = new Audio('assets/ringback.mp3');
            this.audio.load();
            this.audio.loop = true;
            this.audio.play();
        }
    }



    private onSocketMessage(event) {
        try {
            // console.log(event.data);
            var msg;
            /*try {
              msg = JSON.parse(event.data);
            } catch (e1) {
              msg = event.data;
            }*/
            msg = event;
            // console.log('onSocketMessage', msg);
            switch (msg.event) {
                case "call":
                    if (msg.data.status == 'calling') {
                        //STO RICEVENDO UNA CHIAMATA... dovrei mostrare la modale
                        const obj = {
                            topHeader: 'Chiamata in arrivo',
                            header: 'Stai ricevendo una chiamata da ' + msg.data.fromCompleteName + ', cosa vuoi fare?',
                            buttons: [{
                                label: 'Rispondi',
                                color: 'accent',
                                hasIcon: true,
                                iconColor: '',
                                iconName: 'call',
                                action: () => {
                                    setTimeout(() => {
                                        this.emit('answer', { 'toUserId': msg.data.from });
                                        this.videoCallContactId = null;
                                        this.videoCallOperatorContactId = msg.data.from;
                                        this.videoCallSocketSessionId = msg.data.socketSessionFrom;
                                        this.videoCallOperatorIsAnswer = 'true';
                                        this.videoCallEmergencyId = null;
                                        this.videoCallId = msg.data.callId;
                                        this.showVideoCall = true;
                                    }, 10);
                                    if (this.audio != null) {
                                        this.audio.pause();
                                        this.audio = null;
                                    }
                                    if (this.callingTimeout != undefined && this.callingTimeout != null) {
                                        clearTimeout(this.callingTimeout);
                                        this.callingTimeout = null;
                                    }
                                },
                                closeDialog: true
                            },
                            {
                                label: 'Rifiuta',
                                color: 'warn',
                                hasIcon: true,
                                iconColor: '',
                                iconName: 'call_end',
                                action: () => {
                                    this.rejectCall(msg);
                                },
                                closeDialog: true
                            }],
                            changePassword: false,
                            textareas: null,
                            selects: null,
                            formGroup: null,
                        };

                        this.callDialog = this.newDialog(obj, () => {
                            if (this.audio != undefined && this.audio != null)
                                this.audio.pause();
                            this.audio = null;
                        });
                        this.audio = new Audio('assets/ring.mp3');
                        this.audio.load();
                        this.audio.loop = true;
                        this.audio.play();

                        this.callingTimeout = setTimeout(() => {
                            this.rejectCall(msg);
                        }, 30000);

                    } else if (msg.data.status == 'leave') {
                        this.isCalling = false;
                        if (this.callingTimeout != undefined && this.callingTimeout != null) {
                            clearTimeout(this.callingTimeout);
                            this.callingTimeout = null;
                        }
                        if (this.audio != undefined && this.audio != null) {
                            this.audio.pause();
                            this.audio = null;
                        }
                        if (this.callDialog != undefined && this.callDialog != null) {
                            this.callDialog.close();
                        }
                        this.callingTimeout
                    } else if (msg.data.status == 'answer') {
                        this.isCalling = false;
                        if (this.audio != undefined && this.audio != null) {
                            this.audio.pause();
                            this.audio = null;
                        }
                    } else if (msg.data.status == 'reject') {
                        setTimeout(() => {
                            if (this.isCalling == false)
                                return;
                            this.isCalling = false;
                            this.videoCallContactId = null;
                            this.videoCallOperatorContactId = null;
                            this.videoCallSocketSessionId = null;
                            this.videoCallOperatorIsAnswer = null;
                            this.videoCallEmergencyId = null;
                            this.videoCallId = null;
                            this.showVideoCall = false;
                            if (this.callingTimeout != undefined && this.callingTimeout != null) {
                                clearTimeout(this.callingTimeout);
                                this.callingTimeout = null;
                            }
                            if (this.audio != undefined && this.audio != null) {
                                this.audio.pause();
                                this.audio = null;
                            }
                            this.audio = new Audio('assets/busy.mp3');
                            this.audio.load();
                            this.audio.loop = true;
                            this.audio.play();
                            setTimeout(() => {
                                if (this.audio != undefined && this.audio != null) {
                                    this.audio.pause();
                                    this.audio = null;
                                }
                            }, 5000);
                        }, 200);

                    }
                    break;

                case 'updateusers':
                    this.mergeOperators(msg.data);
                    break;
                case 'updateemergencies':
                    this.updateEmergencies();
                    break;
                case 'updatenotification':
                    this.getNotifications();
                    break;
            }
        } catch (e) {
            console.log("Errore getSignalMessageCallback - ", e);
        }
    }

    async updateEmergencies() {
        if (this.notificationServiceClickedSubscription) {
            this.notificationServiceClickedSubscription.unsubscribe();
            this.notificationServiceClickedSubscription = null;
        }
        let sub = this.notificationService.countEmergencyNotfications('OPEN, PROCESSING').subscribe((res: any) => {
            if (res.payload) {
                this.emergencyNotificationsNumber = res.payload.count;

                this.notificationServiceClickedSubscription = this.notificationService.clicked.subscribe(item => {
                    this.isOperatorClickingOnMenu = item;
                });

                // console.log("isOperatorClickingOnMenu",this.isOperatorClickingOnMenu);

                // QUESTO ESCAMOTAGE MI PERMETTE DI TENERE IL REFRESH ALTO E NON INFICIARE L'USABILITA' DELLA PAGINA QUANDO SI REFRESHA 
                if (!this.isOperatorClickingOnMenu) {
                    this.notificationService.updateEmergencyNotificationsValue2(true);
                    this.notificationService.updateEmergencyNotificationsValue3(true);
                }
            }
            sub.unsubscribe();
        });

    }
    rejectCall(msg) {
        console.log('----------rejectCall----------');
        try {
            if (this.callDialog != undefined && this.callDialog != null)
                this.callDialog.close();
        } catch (ex) {
            console.log('-------ex--------', ex);
        }

        try {
            if (this.audio != undefined && this.audio != null) {
                this.audio.pause();
                this.audio = null;
            }
        } catch (ex) {
            console.log('-------ex--------', ex);
        }

        this.emit('reject', {
            'toUserId': msg.data.from,
            'callId': msg.data.callId
        });
        this.isCalling = false;
        this.videoCallContactId = null;
        this.videoCallOperatorContactId = null;
        this.videoCallSocketSessionId = null;
        this.videoCallOperatorIsAnswer = null;
        this.videoCallEmergencyId = null;
        this.videoCallId = null;
        this.showVideoCall = false;
    }

    private emit(status, data, event?, callback?) {
        this.webSocketService.emit(status, data, event, callback);
    }

    clickNotification(notification) {
        console.log('click notification', notification)
        if (notification == undefined || notification == null)
            return;
        switch (notification.actionType) {
            case 'OPEN':
                switch (notification.entityType) {
                    case 'Questionario':
                    case 'Questionario compilato':
                        this.notificationService.getNotfication(notification.id).subscribe((res) => {
                            this.getNotifications();
                        })
                        const url = this.router.url;
                        if (url.split('?')[0] == '/admin/questionari') {
                            this.eventInteractionService.publishData({ event: 'showQuestionario', idQuestionario: notification.actionDetails });
                        } else {
                            this.router.navigateByUrl('/admin/questionari?questionarioId=' + notification.actionDetails);
                        }
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    }

    getNotifications() {
        if (this.subNotifications) {
            this.subNotifications.unsubscribe();
            this.subNotifications = null;
        }
        let obj = this.notificationService.getNotfications();
        if (obj == undefined || obj == null)
            return;
        this.subNotifications = obj.subscribe((res: any) => {
            if (res.payload) {
                // console.log(res);
                this.notifications = res.payload;
                if (this.notifications != undefined && this.notifications != null)
                    this.notificationsNumber = this.notifications.length;
            }
        });
        return this.subNotifications;
    }
}
