import { ChatAdapter, IChatGroupAdapter, Window, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { delay } from "rxjs/operators";
import { ChatService } from './services/chat.service';
import { UserService } from '../admin/services/user.service';

export class ChatAdapterImplementation extends ChatAdapter implements IChatGroupAdapter {
  
  public downloadFile(repositoryId: string, fileName: string): void {
    throw new Error('Method not implemented.');
  }
  public goToRepo(repositoryId: string, isGroup: boolean): void {
    throw new Error('Method not implemented.');
  }


  myUserUuid: any;
  emergencies: [];
  usersList: any;
  userConvertedList: any;
  allPartecipants: IChatParticipant[];

  constructor(
    private chatService: ChatService,
    private userService: UserService,
    private userTypes: Array<{
      key: string,
      name: string,
      title: string,
      mergeInList: boolean,
      windowOptions: {
        buttons: Array<{
          title: string,
          showIcon: boolean,
          icon: string,
          action: (chattingTo: Window) => void ,
        enableButton: (participant: IChatParticipant) => boolean}>,
      windowClass: string}|null,}>
  ) {
    super();
    if (userTypes == undefined || userTypes == null || userTypes.length <= 0) {
      userTypes = [{
        key: 'default',
        name: 'default',
        title: 'default',
        mergeInList: true,
        windowOptions:null
      }]
    }

    this.usersList = {};
    this.userConvertedList = {};

    for (let index = 0; index < userTypes.length; index++) {
      const element = userTypes[index];
      this.usersList[element.key] = [];
      this.userConvertedList[element.key] = [];
    }
  }



  public static mockedParticipants: IChatParticipant[] = [];

  // public static mockedParticipantsOrig: IChatParticipant[] = [
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 1,
  //       displayName: "Arya Stark",
  //       avatar: "https://66.media.tumblr.com/avatar_9dd9bb497b75_128.pnj",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Online
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 2,
  //       displayName: "Cersei Lannister",
  //       avatar: null,
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Online
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 3,
  //       displayName: "Daenerys Targaryen",
  //       avatar: "https://68.media.tumblr.com/avatar_d28d7149f567_128.png",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Busy
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 4,
  //       displayName: "Eddard Stark",
  //       avatar: "https://pbs.twimg.com/profile_images/600707945911844864/MNogF757_400x400.jpg",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Offline
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 5,
  //       displayName: "Hodor",
  //       avatar: "https://pbs.twimg.com/profile_images/378800000449071678/27f2e27edd119a7133110f8635f2c130.jpeg",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Offline
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 6,
  //       displayName: "Jaime Lannister",
  //       avatar: "https://pbs.twimg.com/profile_images/378800000243930208/4fa8efadb63777ead29046d822606a57.jpeg",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Busy
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 7,
  //       displayName: "John Snow",
  //       avatar: "https://pbs.twimg.com/profile_images/3456602315/aad436e6fab77ef4098c7a5b86cac8e3.jpeg",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Busy
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 8,
  //       displayName: "Lorde Petyr 'Littlefinger' Baelish",
  //       avatar: "http://68.media.tumblr.com/avatar_ba75cbb26da7_128.png",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Offline
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 9,
  //       displayName: "Sansa Stark",
  //       avatar: "http://pm1.narvii.com/6201/dfe7ad75cd32130a5c844d58315cbca02fe5b804_128.jpg",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Online
  //     },
  //     {
  //       participantType: ChatParticipantType.User,
  //       id: 10,
  //       displayName: "Theon Greyjoy",
  //       avatar: "https://thumbnail.myheritageimages.com/502/323/78502323/000/000114_884889c3n33qfe004v5024_C_64x64C.jpg",
  //       avatarSrc: null,
  //       status: ChatParticipantStatus.Away
  //     }];

  getUsera(userId): ParticipantResponse {
    const partecipants = ChatAdapterImplementation.mockedParticipants.filter((p) => {
      return p.id == userId;
    })
    if (partecipants != undefined && partecipants != null && partecipants.length > 0) {
      let participantResponse = new ParticipantResponse();

      participantResponse.participant = partecipants[0];
      participantResponse.metadata = {
        totalUnreadMessages: 0
      }
      return participantResponse;
    }
    return null;
  }

  listFriends(): Observable<ParticipantResponse[]> {
    console.log('listFriends!!!');
    return of(ChatAdapterImplementation.mockedParticipants.map(user => {
      let participantResponse = new ParticipantResponse();

      participantResponse.participant = user;
      participantResponse.metadata = {
        totalUnreadMessages: Math.floor(Math.random() * 10)
      }

      return participantResponse;
    }));
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    let mockedHistory: Array<Message>;

    mockedHistory = [
      {
        fromId: 1,
        toId: 999,
        message: "Hi there, just type any message bellow to test this Angular module.",
        dateSent: new Date()
      }
    ];

    return of(mockedHistory).pipe(delay(2000));
  }

  sendMessage(message: Message): void {
    let msg = {
      message: message.message
    };
    this.chatService.send(
      message.toId,
      msg,
      new Date()
    );
  }

  groupCreated(group: Group): void {
    ChatAdapterImplementation.mockedParticipants.push(group);

    ChatAdapterImplementation.mockedParticipants = ChatAdapterImplementation.mockedParticipants.sort((first, second) =>
      second.displayName > first.displayName ? -1 : 1
    );

    // Trigger update of friends list
    this.listFriends().subscribe(response => {
      this.onFriendsListChanged(response);
    });
  }

  newMessage(message) {
    // let user = ChatAdapterImplementation.mockedParticipants.find(x => x.id == message.fromUser.uuid);
    let user = this.allPartecipants.find(x => x.id == message.fromUser.uuid);
    if (user == undefined || user == null)
      return;
    let replyMessage = new Message();

    replyMessage.message = message.textMessage;
    replyMessage.dateSent = message.clientDate;
    replyMessage.fromId = message.fromUser.uuid;
    replyMessage.toId = message.toUser.uuid;

    this.onMessageReceived(user, replyMessage);
  }

  addToList(listKey, user) {
    // let u = ChatAdapterImplementation.mockedParticipants.find(x => x.id == user.id);
    // if(u == undefined || u == null) {
    //     ChatAdapterImplementation.mockedParticipants.push(user);
    // }
    let u = this.userConvertedList[listKey].find(x => x.id == user.id);
    if (u == undefined || u == null) {
      this.userConvertedList[listKey].push(user);
      this.usersList[listKey].push(user);
      this.allPartecipants.push(user);
    }
  }

  removeFromList(listKey: string, id: any) {
    let idx = this.userConvertedList[listKey].findIndex(x => x.id == id);
    if (idx > -1) {
      this.userConvertedList[listKey].splice(idx, 1);
    }
    idx = this.usersList[listKey].findIndex(x => x.id == id);
    if (idx > -1) {
      this.usersList[listKey].splice(idx, 1);
    }

    idx = this.allPartecipants.findIndex(x => x.id == id);
    if (idx > -1) {
      this.allPartecipants.splice(idx, 1);
    }
  }

  setMyUserId(myUserUuid: any) {
    this.myUserUuid = myUserUuid;
  }

  mergeList(key: string, payload: Array<any>) {
    return new Promise((resolve, reject) => {
      console.log('chat adapter merge list ' + key)
      let toRemove = [];
      const ut = this.userTypes.find(x => x.key == key);
      if (this.usersList[key] == undefined || this.usersList[key] == null)
        this.usersList[key] = new Array<any>();
      this.userConvertedList[key] = [];
      for (let index = 0; index < this.usersList[key].length; index++) {
        const element = this.usersList[key][index];
        const found: any = payload.find((v) => {
          return v['userId'] == element.userId;
        })
        if (found == undefined || found == null) {
          toRemove.push(index);
        } else {
          
          this.usersList[key][index].status = found['status'];
          this.usersList[key][index].sessionId = found['sessionId'];
          this.usersList[key][index].sessionIds = found['sessionIds'];

          let usr = {
            participantType: ChatParticipantType.User,
            id: found.id,
            displayName: found.name + " " + found.surname,
            avatarSrc: this.userService.retrieveProfileImageUidEndpoint(found.id, false),
            status: this.getUserStatus(found.status),
            windowOptions: ut.windowOptions
          }
          this.userConvertedList[key].push(usr);
        }
      }
      toRemove = toRemove.sort((a, b) => {
        if (a == b)
          return 0;
        if (a < b)
          return -1;
        if (a > b)
          return 1;
      })

      for (let index = toRemove.length - 1; index >= 0; index--) {
        this.usersList[key].splice(toRemove[index], 1);
      }

      for (let index = 0; index < payload.length; index++) {
        if (payload[index]['userId'] == this.myUserUuid)
          continue;
        const found = this.usersList[key].find((v) => {
          return v['userId'] == payload[index]['userId'];
        })
        if (found == undefined || found == null) {
          this.usersList[key].push(payload[index]);
          let usr = {
            participantType: ChatParticipantType.User,
            id: payload[index].id,
            displayName: payload[index].name + " " + payload[index].surname,
            avatarSrc: this.userService.retrieveProfileImageUidEndpoint(payload[index].id, false),
            status: this.getUserStatus(payload[index].status),
            windowOptions: ut.windowOptions
          }
          this.userConvertedList[key].push(usr);
        }
      }
      console.log('-----------------------------', this.usersList[key]);
      resolve(true);
    })

  }

  mergeAllList() {
    let participants: IChatParticipant[] = [];
    let allPartecipants: IChatParticipant[] = [];
    for (let index = 0; index < this.userTypes.length; index++) {
      const element = this.userTypes[index];
      for (let idx = 0; idx < this.userConvertedList[element.key].length; idx++) {
        const el = this.userConvertedList[element.key][idx];
        if (element.mergeInList) {
          participants.push(el);
        }
        allPartecipants.push(el);
      }

    }
    ChatAdapterImplementation.mockedParticipants = participants;
    this.allPartecipants = allPartecipants;
  }

  loadUserAvatar(usr: { participantType: ChatParticipantType; id: any; displayName: string; avatar: string; avatarSrc: string, status: ChatParticipantStatus; }, callback: (user: any) => void) {
    return new Promise((resolve, reject) => {
      this.userService.renderUnsecureProfileImageUid(usr.id, false)
        .subscribe((data) => {
          usr.avatar = data;
          callback(usr);
          resolve(usr);
        },
          (error) => {
            usr.avatar = '/assets/images/admin-image.jpg';
            callback(usr);
            resolve(usr);
          });
    })

  }

  getUserStatus(status) {
    if (status == undefined || status == null) {
      return ChatParticipantStatus.Offline;
    }
    switch (status) {
      case 'ONLINE':
        return ChatParticipantStatus.Online;
        break;
      case 'OFFLINE':
        return ChatParticipantStatus.Offline;
        break;
      case 'BUSY':
        return ChatParticipantStatus.Busy;
        break;
      default:
        return ChatParticipantStatus.Offline;
        break;
    }
  }

}
