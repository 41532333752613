import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateToStringPipe } from './date-to-string.pipe';
import { ToUpperTrimPipe } from './to-upper-trim.pipe';
import { CustomDateFormatterPipe } from './customDateFormatterPipe';
import { PatientProfileImagePipe } from './patient-profile-image.pipe';
import { UserRoleFormatterPipe } from './userRoleFormatter.pipe';
import { CurrencyLicensePipe } from './license-pipes';
import { PeriodLicensePipe } from './license-pipes';
import { CustomDateHourFormatterPipe } from './customDateHourFormatterPipe';
import { MachineImagePipe } from './machine-image.pipe';


@NgModule({
  declarations: [
    DateToStringPipe,
    ToUpperTrimPipe,
    CustomDateFormatterPipe,
    CustomDateHourFormatterPipe,
    PatientProfileImagePipe,
    MachineImagePipe,
    UserRoleFormatterPipe,
    CurrencyLicensePipe,
    PeriodLicensePipe
  ],
  imports: [
    CommonModule
  ],
  exports:
    [
      DateToStringPipe,
      ToUpperTrimPipe,
      CustomDateFormatterPipe,
      CustomDateHourFormatterPipe,
      PatientProfileImagePipe,
      MachineImagePipe,
      UserRoleFormatterPipe,
      CurrencyLicensePipe,
      PeriodLicensePipe
    ]
})
export class ApplicationPipesModule { }
