import { Pipe, PipeTransform } from '@angular/core';

declare let moment: any;

@Pipe({
  name: 'customDateHourFormatterPipe'
})
export class CustomDateHourFormatterPipe implements PipeTransform {

  constructor() { }

  transform(value, ...args: any[]): any {
    const date = new Date(value);
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

}
