import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FileService } from 'app/main/admin/services/file.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

// To modify
@Component({
  selector: 'app-image-crop-machine',
  templateUrl: './image-crop-machine.component.html',
  styleUrls: ['./image-crop-machine.component.scss']
})
export class ImageCropMachineComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImageCropMachineComponent>,
    private fileService: FileService) { }

  imageChangedEvent: any = '';
  croppedImage: any = '';


  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }


  // When you choose a file from the file input, it will trigger fileChangeEvent.
  // That event is then passed to the image cropper through imageChangedEvent which will load the image into the cropper
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  // Everytime you release the mouse, 
  // the imageCropped event will be triggerd with the cropped image as a Base64 string in its payload.
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onSave() {
    // Convert it to a blob to upload
    let blob = this.base64ToFile(this.croppedImage);
    this.fileService.uploadTmpImage(blob).subscribe(async (res: any) => {
      console.log('imageId: ', res.payload.fileId);
      this.dialogRef.close({ machineImageTmpId: res.payload.fileId, machineImageBlob:blob, error: false });
    }, (error) => {
      console.log(error);
      this.dialogRef.close({ error: true });
    });

  }

  ngOnInit() {
  }

}
