import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { UserService } from 'app/main/admin/services/user.service';
import { NotificationService } from 'app/main/services/notification.service';
import { IChatController, IChatParticipant, Localization, Window } from 'ng-chat';
import { ChatAdapterImplementation } from '../chat-adapter-implementation';
import { AccountService } from '../services/account.service';
import { ChatService } from '../services/chat.service';
import { EmergenciesService } from '../services/emergencies.service';
import { EventInteractionService } from '../services/event-interaction.service';
import { VideocallService } from '../services/videocall.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends AdminUtilComponent implements OnInit {

  adapter: ChatAdapterImplementation;
  chatDisabled: boolean = false;
  searchEnabled: boolean = true;
  historyEnabled: boolean = false;
  hideFriendsList: boolean = false;
  messagePlaceholder: string = "Scrivi un messaggio"
  messageDatePipeFormat: string = "dd-MM-yyyy HH:mm:SS"
  localization: Localization;
  isCollapsed: boolean = true;
  isViewportOnMobileEnabled: boolean = true;
  // userId = 999;
  // chatDisabled: boolean = false;
  // searchEnabled: boolean = false;
  // historyEnabled: boolean = false;
  // hideFriendsList: boolean = true;
  // messagePlaceholder: string = "Scrivi un messaggio"
  // messageDatePipeFormat: string = "dd-MM-yyyy HH:mm:SS"

  @ViewChild('ngChatInstance')
  protected ngChatInstance: IChatController;

  @Input()
  public chatRoleEnabled: Array<any>;

  eis: any;
  fileUrl: any;
  userInfo: any;
  emergencies: {};
  isInEmergency: boolean;
  myUserUuid: any;
  firstMerge: boolean;
  emergencySubscription: any;

  constructor(
    private dialogMat: MatDialog,
    private snackBarMat: MatSnackBar,
    private eventInteractionService: EventInteractionService,
    //private videocallEventInteractionService: VideocallEventInteractionService,
    private chatService: ChatService,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private _formBuilder: FormBuilder,
    private accountService: AccountService,
    private emergenciesService: EmergenciesService,
    private videocallService: VideocallService,
    injector: Injector,
  ) {
    super(dialogMat, snackBarMat, injector);
    this.chatService.setThereIsAdapter(true);
    this.adapter = new ChatAdapterImplementation(
      this.chatService, 
      this.userService,
      [{
        key:'utenti',
        name:'utenti',
        title:'utenti',
        mergeInList:false,
        windowOptions: {
          buttons: [{
            title: 'video_call',
            showIcon: true,
            icon: 'video_call',
            action: (chattingTo: Window) => { 
              console.log(chattingTo) 
            },
            enableButton: (participant: IChatParticipant) => {
              if(this.videocallService.isInCall())
                return false;
               return true;
              },
          }],
          windowClass: 'utenti'
        }
      },
      {key:'specialisti',
      name:'specialisti',
      title:'specialisti',
      mergeInList:true,
      windowOptions: {
        buttons: [{
          title: 'video_call',
          showIcon: true,
          icon: 'video_call',
          action: (chattingTo: Window) => { 
            console.log(chattingTo);
             this.eventInteractionService.publishData({
               event:'clickOperator', 
               userId:chattingTo.participant.id
              })
              this.ngChatInstance.triggerToggleChatWindowVisibility(chattingTo.participant.id);

          },
          enableButton: (participant: IChatParticipant) => { 
            if(participant.status == 0 && !this.videocallService.isInCall())
              return true;
            return false;
           },
        }],
        windowClass: 'specialisti'
      }
    }]);
    if (this.emergencies == undefined || this.emergencies == null)
      this.emergencies = {};
    this.localization = {
      title: "Specialisti",
      messagePlaceholder: "Scrivi un messaggio",
      searchPlaceholder: "Ricerca",
      browserNotificationTitle: "Nuovo messaggio",
      loadMessageHistoryPlaceholder: "Caricamento",
      statusDescription: {
        online: 'ONLINE',
        busy: 'OCCUPATO',
        away: 'AWAY',
        offline: 'OFFLINE'
      }
    }
  }

  eventInteractionServiceSubscription(data) {
    //console.log('Data received', data);

    if (data != undefined && data != null
      && data.event != undefined && data.event != null) {
      switch (data.event) {
        case 'LOGIN':
          setTimeout(() => {
            this._afterLogin();
          }, 500);
          
          break;
        case 'LOGOUT':
          console.log('chat disabled!!!')
          this.chatDisabled = true;
          this.adapter.setMyUserId(null);
          break;
        // case 'OPENCHAT':
        //   console.log('openchat', data)
        //   this.openChat(data);
        //   break;
        case 'OPENCHAT':
          console.log('openchat', data)
          this.openChat(data);
          break;
        case 'NEWCHATMESSAGE':
          console.log('NEWCHATMESSAGE', data)
          this.newMessage(data);
          break;
        case 'closeemergency':
          console.log('closeemergency', data)
          this.remoteCloseEmergency(data.data);
          break;
        case 'updateusers':
          this.mergeOperators(data.data);
            break;
        default:
          break;
      }
    }
  }

  ngOnInit() {
    this.eis = this.eventInteractionService.getObservable().subscribe(this.eventInteractionServiceSubscription.bind(this));
    this.emergencySubscription = this.emergenciesService.getObservable().subscribe((obs) =>{
      if(obs.event == 'removeEmergency') {
        if(this.emergencies && 
          this.emergencies[obs.emergency.userId] && 
          this.emergencies[obs.emergency.userId].emergencyId == obs.emergency.emergencyId) {
            this.isInEmergency = false;
            delete this.emergencies[obs.emergency.userId];
            this.ngChatInstance.triggerCloseChatWindow(obs.emergency.userId);
          }
      }
    })
    this.accountService.getAccount().then((account) => {
      this.myUserUuid = account.userId;
      this.adapter.setMyUserId(this.myUserUuid);
      setTimeout(() => {
        if (this.ngChatInstance != undefined && this.ngChatInstance != null)
        {
          this.ngChatInstance.setBeforeParteciantChatClosed(this.beforeChatClosed.bind(this));
        }
      }, 500);
  })

  

    // setTimeout(() => {
    //   console.log('adding public fetch');
    //   this.ngChatInstance['publicFetch'] = this.publicFetch;
    // }, 200);


  }

  _afterLogin() {
    this.accountService.getAccount().then((account) => {
      if(account == undefined || account == null ||
        account.userId == undefined || account.userId == null) {
          setTimeout(()=>{
            this._afterLogin();
          }, 200);
          return;
        }
      if(this.chatRoleEnabled != undefined && this.chatRoleEnabled != null &&
        !this.chatRoleEnabled.includes(account.ruolo)) {
          this.myUserUuid = null;
          this.adapter.setMyUserId(this.myUserUuid);
          console.log('chat enabled!!!')
          this.chatDisabled = true;
          // this.userService.getOperators().subscribe((resp: any) => {
          //   console.log(resp);
          //   if(resp.payload)
          //     this.mergeOperators(resp.payload)
          // });
         
        } else {
          this.myUserUuid = account.userId;
          this.adapter.setMyUserId(this.myUserUuid);
          // console.log('chat enabled!!!')
          this.chatDisabled = false;
          // this.userService.getOperators().subscribe((resp: any) => {
          //   console.log(resp);
          //   if(resp.payload)
          //     this.mergeOperators(resp.payload)
          // });
        }
      
    })
  }

  // publicFetch() {
  //   console.log(this);
  //   this['fetchFriendsList'](false);
  // }

  mergeOperators(data) {
    // console.log('chat component merge operators')
    this.firstMerge = true;
    if(this.ngChatInstance == undefined || this.ngChatInstance == null){
      setTimeout(() => {
        this.mergeOperators(data)
      }, 200);
      return;
    }

    this.adapter.mergeList('specialisti', data).then(()=>{
      // if(this.ngChatInstance['publicFetch'] == undefined || this.ngChatInstance['publicFetch'] == null) {
      //   this.ngChatInstance['publicFetch'] = this.publicFetch;
      // } 
      // this.ngChatInstance['publicFetch']();
      this.adapter.mergeAllList();
      this.ngChatInstance.fetchFriendsList(false);
    })
   
    
  }

  openChat(data) {
    console.log('opening user chat');
    let contactId = 15;
    let emergencyId;
    let user = {
      participant: {
        participantType: 0,
        id: contactId,
        displayName: "Test test",
        avatarSrc: "https://thumbnail.myheritageimages.com/502/323/78502323/000/000114_884889c3n33qfe004v5024_C_64x64C.jpg",
        status: 0,
        windowOptions: null
      }
    }
    this.isInEmergency = true;
    user.participant.avatarSrc = '/assets/images/admin-image.jpg';
    if (data != undefined && data != null) {
      user.participant.id = data.contactId;
      contactId = data.contactId;
      emergencyId = data.emergencyId;
      if (this.emergencies == undefined || this.emergencies == null)
        this.emergencies = {};
      this.emergencies[contactId] = {
        contactId: contactId,
        emergencyId: emergencyId
      }
      this.showLoader();
      this.userService.getUserInfoByUserId(contactId)
        .subscribe((data: any) => {
          this.userInfo = data.payload;
          user.participant.displayName = this.userInfo.name + " " + this.userInfo.surname;
          user.participant.avatarSrc = this.userService.retrieveProfileImageUidEndpoint(contactId, false);
          user.participant.windowOptions = {
            buttons: [{
              title: 'test',
              showIcon: true,
              icon: 'video_call',
              action: (chattingTo: Window) => {
                console.log(chattingTo);
                if(this.emergencies && this.emergencies[chattingTo.participant.id]) {
                  //this.videocallEventInteractionService.publishData({event:'toolbarVideocallStart', contactId: this.emergencies[chattingTo.participant.id].contactId, emergencyId: this.emergencies[chattingTo.participant.id].emergencyId})
                  // this.eventInteractionService.publishData({event:'toolbarVideocallStart', contactId: this.emergencies[chattingTo.participant.id].contactId, emergencyId: this.emergencies[chattingTo.participant.id].emergencyId})
                  this.emergenciesService.tryToUpdateEmergency(
                    this.emergencies[chattingTo.participant.id].emergencyId,
                    'request',
                    'VIDEO',
                    (res)=>{
                      this.eventInteractionService.publishData({event:'toolbarVideocallStart', contactId: this.emergencies[chattingTo.participant.id].contactId, emergencyId: this.emergencies[chattingTo.participant.id].emergencyId})
                      this.ngChatInstance.triggerToggleChatWindowVisibility(this.emergencies[chattingTo.participant.id].contactId);
                    })
                }
              },
              enableButton: (participant: IChatParticipant) => {
                if(this.videocallService.isInCall())
                  return false;
                return true;
              },
          }],
            windowClass: 'utenti'
          };
          this._openChat(user.participant);
          this.hideLoader();
        },
          (error) => {
            this.snackBarMat.open('Errore caricamento profilo', '', {
              duration: 3000,
              panelClass: 'warn',
            });
          });

    } else {
    }


  }

  _getSafeUrl() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
  }

  _openChat(user) {
    if (!this.chatDisabled) {
      this.adapter.addToList('utenti', user);
      this.ngChatInstance.triggerOpenChatWindow(user);
      this.emergenciesService.tryToUpdateEmergency(
        this.emergencies[user.id].emergencyId,
        'add',
        'CHAT',
        null
        )

        this.chatService.send(user.id, {message:'COMMAND_OPENCHAT'}, new Date());
    }
  }

  ngOnDestroy(): void {
    if(this.emergencySubscription != undefined && this.emergencySubscription != null)
      this.emergencySubscription.unsubscribe();
    if (this.eis != undefined && this.eis != null)
      this.eis.unsubscribe();
    this.isInEmergency = false;
  }

  newMessage(data) {
    if (data.message != undefined && data.message != null)
      this.adapter.newMessage(data.message);
  }

  async beforeChatClosed(participant):Promise<boolean> {
    console.log(participant);
    const res =  await this._beforeChatClosed(participant);
    return res;
  }

  _beforeChatClosed(participant) {
    return new Promise<boolean>((resolve)=>{
      this.newConfirmation(null, (result)=>{
        if (result && result.data) { 
          resolve(true);
        } else {
          resolve(false);
        }
      });

    })
  }

  onChatToggle(payload) {
    console.log(payload);
    if(this.emergencies && this.emergencies[payload.participant.id]){
      if(payload.isCollapsed == true) {
        // this.chatService.sendCommand(
        //   'hidechat',
        //   {
        //     userUuid: payload.participant.id,
        //     uuid: payload.participant.id,
        //     to: payload.participant.id,
        //     toUserId: payload.participant.id,
        //     message: 'hidechat',
        // });
        this.chatService.emit('hideChat', { 'to': payload.participant.id});
      } else {
        // this.chatService.sendCommand(
        //   'showchat',
        //   {
        //     userUuid: payload.participant.id,
        //     uuid: payload.participant.id,
        //     to: payload.participant.id,
        //     toUserId: payload.participant.id,
        //     message: 'showchat',
        // });
        this.chatService.emit('showChat', { 'to': payload.participant.id});
      }
    }
  }

  onChatClosed(participant) {
    console.log('chat closed!!!', participant);
    if (this.emergencies != undefined &&
      this.emergencies != null &&
      this.emergencies[participant.id] != undefined) {

        const em = this.emergenciesService.getEmergencyById(this.emergencies[participant.id].emergencyId);
        if(em != undefined && em.videoEnabled) {

          this.chatService.emit('hideChat', { 'to': participant.id});

          this.emergenciesService.tryToUpdateEmergency(
            this.emergencies[participant.id].emergencyId,
            'remove',
            'CHAT',(e)=>{
              this.isInEmergency = false;
              delete this.emergencies[participant.id];
              this.emergenciesService.tryToUpdateEmergency(
                this.emergencies[participant.id].emergencyId,
                'unrequest',
                'CHAT',null)
            })
        } else {
          this.closeEmergency(participant.id);
          this.chatService.forceCloseEmergency(participant.id);
          this.adapter.removeFromList('utenti', participant.id);
        }
    }
  }

  remoteCloseEmergency(data) {
    let toUserId = data.result.fromUser.uuid;
    if (!this.isInEmergency || this.emergencies == undefined || this.emergencies == null
      || this.emergencies[toUserId] == undefined || this.emergencies[toUserId] == null)
      return;
    let emergencyId = this.emergencies[toUserId].emergencyId;
    this.newSnackbar(`Sessione chiusa dall'utente remoto.`, 'warn');
    this.emergenciesService.removeClosedEmergencyById(emergencyId, (res) =>{
      delete this.emergencies[toUserId];
      this.ngChatInstance.triggerCloseChatWindow(toUserId);
    }, (err)=>{
      console.log(err);
    })
    // this.askForNotes(this._formBuilder, this.notificationService, emergencyId)
    //   .then(
    //     (dataNote) => {
    //       this.newSnackbar(`Nota chiusura creata.`, 'success');
    //       console.log('errNote: ', dataNote);

    //     },
    //     (errNote) => {
    //       this.newSnackbar(`Errore creazione nota chiusura.`, 'warn');
    //       console.log('errNote: ', errNote);
    //     }
    //   );
    
  }

  closeEmergency(toUserId: any) {
    const closed = (result) => {
      if (result && result.data) {
        this.loading = true;
        this.emergenciesService.removeEmergencyByUserId(
          toUserId,
          (res) => {
            this.isInEmergency = false;
            delete this.emergencies[toUserId];
          },
          (error) =>{
            this.loading = false;
            console.log(error);
            delete this.emergencies[toUserId];
          })
        // return this.notificationService.closeEmergencyByPatientId(toUserId).subscribe((res: any) => {
        //   let emergencyId = null;
        //   if (res['payload'] != undefined && res['payload'] != null)
        //     emergencyId = res['payload'].emergencyId;
        //   this.notificationService.getEmergencyNotfications('OPEN, PROCESSING').subscribe((resp: any) => {
        //     this.notificationService.updateEmergencyNotificationsValue(true);
        //   });
        //   this.isInEmergency = false;
        //   // this.newSnackbar(`Emergenza chiusa.`, 'success');
        //   if (emergencyId == undefined || emergencyId == null) {
        //     emergencyId = this.emergencies[toUserId].emergencyId;
        //   }
        //   if (emergencyId != undefined && emergencyId != null) {
        //     // this.askForNote(this._formBuilder, this.notificationService, emergencyId);

        //     this.askForNotes(this._formBuilder, this.notificationService, emergencyId)
        //       .then(
        //         (dataNote) => {
        //           this.newSnackbar(`Nota chiusura creata.`, 'success');
        //           console.log('errNote: ', dataNote);

        //         },
        //         (errNote) => {
        //           this.newSnackbar(`Errore creazione nota chiusura.`, 'warn');
        //           console.log('errNote: ', errNote);
        //         }
        //       );
        //   }
        //   delete this.emergencies[toUserId];
        // }, (error) => {
        //   this.loading = false;
        //   console.log(error);
        //   delete this.emergencies[toUserId];
        // });
      }
    };
    this.closeAllEmergencyByUserId(toUserId, closed);

  }

}
